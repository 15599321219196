export const PLAN_FREE = 'free'
export const PLAN_ERA_NONE = 'NONE'
export const PLAN_ERA_2019_LAUNCH = '2019_LAUNCH'
export const PLAN_ERA_2021_CLEANUP = '2021_CLEANUP'
export const PLAN_ERA_2022 = '2022'

export type PlanType =
  | 'free'
  | 'launch'
  | 'individual'
  | 'pro'
  | 'business'
  | 'starter'
  | 'professional'
  | 'team'
  | 'business2022'

// Describe the current state.
// Move old CURRENT_ERA into POST_ERAS when a new current era is introduced.
export const CURRENT_ERA = PLAN_ERA_2022
export const PAST_ERAS = [PLAN_ERA_2019_LAUNCH, PLAN_ERA_2021_CLEANUP]

interface PlanEras {
  [key: string]: Array<string>
}

/**
 * We group plans by "Era" rather than referring to "New" and "Old" plans.
 * A plan is only "New" until newer plans come along.
 */
export const PLAN_ERAS: PlanEras = {
  [PLAN_ERA_NONE]: ['free'],
  [PLAN_ERA_2019_LAUNCH]: ['launch', 'individual'], // Defunct - no longer used but still found in code
  [PLAN_ERA_2021_CLEANUP]: ['pro', 'business'],
  [PLAN_ERA_2022]: ['starter', 'professional', 'team', 'business2022'],
}

export const BUSINESS_PRICE_MULTIPLIER = 10
export const BUSINESS_ACTIONS_MULTIPLIER = 200000
export const OTHER_TEAMS_PRICE_MULTIPLIER = 8
export const OTHER_TEAMS_ACTIONS_MULTIPLIER = 15000

type PlanProperties = {
  planDisplayName: string
  limits: {
    actions: number
    testApps: number
    publishedApps: number
    seats: number
  }
  premiumFeatures: string[]
  nextPlanUp: PlanType
  prices: {
    monthly: number
    yearly: number
  }
  perks: string[]
  stripePrices: {
    live: {
      monthly: string
      yearly: string
    }
    test: {
      monthly: string
      yearly: string
    }
  }
}

export type PricingPlans = Record<PlanType, PlanProperties>
