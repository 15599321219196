/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextInput, Platform } from 'react-native'
import { connect } from 'react-redux'
import {
  INPUT,
  inputTypes,
  bindingTypes,
  DEBOUNCE_TIME,
} from '@adalo/constants'
import { debounce } from 'lodash'

import { validateNumberInputValue } from 'utils/inputs'
import { evaluateBinding } from 'utils/dependencies'
import { actionContextTypes } from 'utils/actions'
import { doesBindingContainRandomFormula } from 'utils/bindings'

import {
  changeValue,
  setDefaultValue,
  resetValue,
  getValue,
} from 'ducks/formInputs'

import BaseObject from '../BaseObject'
import ActionWrapper from '../ActionWrapper'

export class Input extends BaseObject {
  state = {
    value: '',
    keyboardType: 'default',
    autoCapitalize: 'none',
    autoCorrect: true,
    secureTextEntry: false,
    placeholderColor: null,
    inputStyle: {},
  }

  static contextTypes = {
    ...actionContextTypes,
  }

  static className = 'TextInput'

  componentDidMount() {
    const { value } = this.props
    const { inputType, autoFocus } = this.getAttributes()

    switch (inputType) {
      case inputTypes.DEFAULT:
        this.setState({ autoCapitalize: 'sentences' })
        break
      case inputTypes.PASSWORD:
        this.setState({ autoCorrect: false, secureTextEntry: true })
        break
      case inputTypes.EMAIL:
        this.setState({ keyboardType: 'email-address' })
        break
      case inputTypes.NUMBER:
        this.setState({ keyboardType: 'default' })
        break
    }

    const defaultValue = this.getDefaultValue()

    if (defaultValue) {
      this.setDefaultValue(defaultValue)
    } else if (value) {
      this.setState({ value })
    }

    // styles
    this.handleStyles()

    this.debounceHandleFormStateUpdate = debounce(
      this.handleFormStateUpdate,
      DEBOUNCE_TIME
    )

    if (autoFocus) {
      this.textInput.focus()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      defaultValue,
      isRandomDefaultValue,
      object,
      value,
      topScreen,
      formSubmitSuccess,
    } = this.props

    const { autoFocus } = object.attributes

    if (topScreen) {
      /*
       * The order of these if statements must be preserved to maintain
       * the correct handling for each different render of the inputs.
       */
      if (prevProps.defaultValue !== defaultValue && !isRandomDefaultValue) {
        this.setDefaultValue(this.getDefaultValue())
      }

      if (object.attributes !== prevProps.object.attributes) {
        this.handleStyles()
      }

      if (value && value !== prevProps.value) {
        this.handleChange(value)
      }

      if (
        prevProps.formSubmitSuccess !== formSubmitSuccess &&
        formSubmitSuccess
      ) {
        this.handleChange('')
      }

      if (autoFocus && !prevProps.active) {
        this.textInput.focus()
      }
      /*
       * We need this specific check to be able to set an input
       * to empty if it is doing it through the CHANGE_VALUE action using
       *  an undefined value.
       */
      if (typeof value === 'undefined' && value !== prevProps.value) {
        this.handleChange('')
      }
    }
  }

  componentWillUnmount() {
    const { object, resetValue } = this.props

    if (resetValue && object) {
      resetValue(object.id)
    }
  }

  handleChange = value => {
    const { maxLength, inputType } = this.getAttributes()

    if (maxLength && value.length > maxLength) return

    if (value && inputType === inputTypes.NUMBER) {
      const checkValid = validateNumberInputValue(value)
      if (!checkValid) return
    }

    value = this.formatValue(value)

    this.setState({ value }, this.debounceHandleFormStateUpdate)
  }

  handleFormStateUpdate = () => {
    const { value } = this.state
    const { value: formValue, inputId, changeValue } = this.props

    if (value !== formValue) {
      changeValue(inputId, value)
    }
  }

  formatValue = value => {
    const { object } = this.props
    const { inputType } = object.attributes

    if (object.type === INPUT) {
      switch (inputType) {
        case inputTypes.LOWERCASE: {
          return value.toLowerCase()
        }
      }
    }

    return value
  }

  setDefaultValue = value => {
    const { setDefaultValue, inputId, changeValue } = this.props

    this.setState({ value })
    setDefaultValue(inputId, value)
    changeValue(inputId, value)
  }

  getInputId() {
    const { object, getBindingsList } = this.props
    const parentBindings = getBindingsList()

    return parentBindings.concat(object.id).join('.')
  }

  getDefaultValue = () => {
    const { object, bindingData, defaultValue } = this.props
    const { dataBinding } = object

    if (defaultValue) {
      return defaultValue
    } else if (dataBinding && dataBinding.type === bindingTypes.SET_TEXT) {
      return bindingData
    }
  }

  getAttributes = () => {
    const { object } = this.props

    return object.attributes || {}
  }

  getStyles = () => {
    const { height, padding, fontSize, multiline } = this.getAttributes()
    const textStyles = this.textStyles()

    const styles = {
      ...textStyles,
      height,
      paddingLeft: padding,
      paddingRight: padding,
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: fontSize * 1.25,
    }

    if (multiline) {
      Object.assign(styles, {
        textAlignVertical: 'top',
        paddingTop: padding,
        paddingBottom: padding,
      })
    }

    return styles
  }

  getInputStyles = () => {
    let backgroundStyles = this.backgroundStyles()

    backgroundStyles = {
      ...backgroundStyles,
      backgroundColor: backgroundStyles.backgroundColor || 'white',
    }

    const styles = {
      ...this.getStyles(),
      ...this.shadowStyles(),
      ...this.borderStyles(),
      ...backgroundStyles,
    }

    if (Platform.OS === 'web') {
      styles.outline = 'none'
    }

    return styles
  }

  getPlaceholderColor = () => {
    const { placeholderColor } = this.getAttributes()

    return placeholderColor || null
  }

  handleStyles = () => {
    const placeholderColor = this.getPlaceholderColor()
    const inputStyle = this.getInputStyles()
    this.setState({ inputStyle, placeholderColor })
  }

  sanitizeValue = value => {
    return typeof value === 'string' ? value : ''
  }

  render() {
    const { object, component, bindingData } = this.props
    const { attributes, layout } = object
    const { placeholder, multiline, maxLength } = attributes
    const {
      value,
      autoCapitalize,
      autoCorrect,
      keyboardType,
      secureTextEntry,
      inputStyle,
      placeholderColor,
    } = this.state
    return (
      <ActionWrapper
        object={object}
        component={component}
        bindingData={bindingData}
        style={[layout, this.getOpacity()]}
      >
        <TextInput
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          // eslint-disable-next-line no-return-assign
          ref={textInput => (this.textInput = textInput)}
          keyboardType={keyboardType}
          style={inputStyle}
          multiline={multiline}
          returnKeyType={multiline ? 'default' : 'done'}
          onChangeText={this.handleChange}
          onBlur={this.handleFormStateUpdate}
          value={this.sanitizeValue(value)}
          placeholder={placeholder}
          placeholderTextColor={placeholderColor || '#a9a9a9'}
          secureTextEntry={secureTextEntry}
          underlineColorAndroid="transparent"
          maxLength={maxLength}
        />
      </ActionWrapper>
    )
  }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const connectInput = (inputClass, additionalMethods = {}, mapState) => {
  if (!mapState) {
    mapState = () => ({})
  }

  const mapStateToProps = (state, props) => {
    const { getBindingsList, object } = props
    const inputId = getBindingsList().concat([object.id]).join('.')

    let value = getValue(state, inputId)

    // value needs to be a string for a TextInput
    if (
      typeof value === 'number' &&
      inputClass.className &&
      inputClass.className === 'TextInput'
    ) {
      value = `${value}`
    }

    return {
      inputId,
      value,
      ...mapState(state, props),
    }
  }

  const ConnectedInput = connect(mapStateToProps, {
    ...additionalMethods,
    changeValue,
    setDefaultValue,
    resetValue,
  })(inputClass)

  class WrappedInput extends Component {
    static contextTypes = {
      getBindingsList: PropTypes.func,
    }

    render() {
      const { getBindingsList } = this.context

      return (
        <ConnectedInput {...this.props} getBindingsList={getBindingsList} />
      )
    }
  }

  return WrappedInput
}

const inputMapState = (state, props) => {
  const { defaultValue } = props.object.attributes
  const isRandomDefaultValue = doesBindingContainRandomFormula(defaultValue)

  return {
    defaultValue: evaluateBinding(state, defaultValue, props),
    isRandomDefaultValue,
  }
}

export default connectInput(Input, null, inputMapState)
