import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'

import LayoutGuides, { DEFAULT_LAYOUT_GUIDES } from '../utils/layout-guides'

import { getAuthenticated, restartSession } from '../ducks/auth'

import Navigator from './Navigator'
import Notifications from './Notifications'
import Toast from './Toast'
import Loading from './Loading'
import SelectProvider from './Select/SelectProvider'

class Runner extends Component {
  constructor(props) {
    super(props)

    const layoutGuides = { ...DEFAULT_LAYOUT_GUIDES, ...props.layoutGuides }

    this.state = { layoutGuides }
  }

  static childContextTypes = {
    getApp: PropTypes.func,
  }

  getChildContext() {
    return {
      getApp: this.getApp,
    }
  }

  getApp = () => {
    const { app } = this.props

    return app
  }

  handleFontFamily = fontObj => {
    const { family, variants } = fontObj

    if (variants && variants.length > 0) {
      return `${family}:${variants.join(',')}`
    }

    return family
  }

  componentDidMount() {
    const { layoutGuides: prevLayoutGuides, restartSession } = this.props

    // Load up all the auth keys, so we have them
    restartSession()

    // Setup layout guides
    if (!prevLayoutGuides) {
      LayoutGuides.getLayoutGuides().then(layoutGuides => {
        if (layoutGuides) {
          this.setState({ layoutGuides })
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { app } = this.props

    if (app && prevProps.app !== app) {
      const { branding = {} } = app
      const { fonts = {} } = branding

      const families = []

      if (fonts) {
        const { heading = {}, body = {} } = fonts

        if (heading && typeof heading.family === 'string') {
          if (heading.family !== 'default') {
            families.push(this.handleFontFamily(heading))
          }
        }
        if (body && typeof body.family === 'string') {
          if (body.family !== 'default') {
            families.push(this.handleFontFamily(body))
          }
        }

        if (families.length > 0) {
          WebFont.load({ google: { families } })
        }
      }
    }
  }

  render() {
    const {
      app,
      authenticated,
      onNavigate,
      initialRoute,
      deviceId,
      skipNotifications,
      marginBottom,
    } = this.props

    const { layoutGuides } = this.state

    if (!app) {
      return <Loading />
    }

    let { launchComponentId, authComponentId } = app

    if (!authComponentId) {
      authComponentId = launchComponentId
    }

    const launchComponent = authenticated ? launchComponentId : authComponentId

    return (
      <SelectProvider>
        <View
          style={{
            flex: 1,
            backgroundColor: '#eee',
            marginBottom,
          }}
        >
          {!app || authenticated === null ? (
            <Loading />
          ) : (
            <Navigator
              app={app}
              authenticated={authenticated}
              initialComponentId={launchComponent}
              initialRoute={initialRoute}
              onNavigate={onNavigate}
              layoutGuides={layoutGuides}
              deviceId={deviceId}
              skipNotifications={skipNotifications}
            />
          )}
          <View style={styles.notifications}>
            <Notifications layoutGuides={layoutGuides} />
          </View>
          <View style={styles.toast}>
            <Toast />
          </View>
        </View>
      </SelectProvider>
    )
  }
}

const styles = StyleSheet.create({
  notifications: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  toast: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
})

const mapStateToProps = (state, { app }) => ({
  authenticated: getAuthenticated(state, (app && app.datasources) || {}),
})

export default connect(mapStateToProps, { restartSession })(Runner)
