/* eslint-disable no-await-in-loop */
import PropTypes from 'prop-types'

import {
  sourceTypes,
  actionTypes,
  authTypes,
  transitions,
  DEBOUNCE_TIME,
} from '@adalo/constants'

import { setCurrentLocation } from 'ducks/location'
import { ACTION_EXECUTION_DENIED } from 'ducks/toasts'
import { getEndpoint, getEndpointMethod } from './apis'
import { apiURL } from './urls'
import { Alert } from './alerts'
import { getId, searchForSource } from './sources'
import { disableFeature } from './featureAccess'

import { mapObject, toDeep, deepGet } from './objects'
import { getSingleActionDependencies, getInputs } from './dependencies'
import { share } from './sharing'
import { request } from './networking'
import { getInputIds } from './inputs'
import { openLink } from './linking'
import { sleep } from './sleep'
import { addRelatedBindings } from './bindings'
import { logEvent, logAppAction } from './analytics'
import { executeAPICustomAction } from './custom-action'
import { evaluateCondition } from './conditions'
import { isExternalCollection } from './externalCollections'
import { fileUpload } from './filedata'

import {
  registerDevice,
  unregisterDevice,
  sendNotification,
} from './notifications'

import { requestPermission } from './notification-wrapper'

import { changeValue, resetValue } from '../ducks/formInputs'

import {
  setAuthToken,
  unsafeGetToken,
  authenticate,
  authenticateExternal,
  logout,
} from '../ducks/auth'

import {
  createObject,
  updateObject,
  deleteObject,
  createAssociation,
  deleteAssociation,
} from '../ducks/data'

import { TARGET_FORGOT_PASSWORD } from './forgotPassword'

export const actionContextTypes = {
  navigate: PropTypes.func,
  getBinding: PropTypes.func,
  getBindings: PropTypes.func,
  getBindingsList: PropTypes.func,
  getParams: PropTypes.func,
  getAssetURL: PropTypes.func,
  getBaseURL: PropTypes.func,
  getBaseAPIURL: PropTypes.func,
  getDatasources: PropTypes.func,
  getFileUploadsBaseURL: PropTypes.func,
  getImageUploadsBaseURL: PropTypes.func,
  setDirty: PropTypes.func,
  getApp: PropTypes.func,
  getStore: PropTypes.func,
  getNotificationsURL: PropTypes.func,
  getDeviceId: PropTypes.func,
  isPreviewer: PropTypes.bool,
  getComponent: PropTypes.func,
  appVersion: PropTypes.string,
  disableAnalytics: PropTypes.bool,
  instantNavigation: PropTypes.func,
  getFlags: PropTypes.func,
}

const terminatingActionTypes = [
  actionTypes.AUTHENTICATE,
  actionTypes.AUTHENTICATE_EXTERNAL,
  actionTypes.AUTHENTICATE_API,
  actionTypes.SIGNUP,
  actionTypes.SIGNUP_EXTERNAL,
  actionTypes.SIGNUP_API,
  actionTypes.CALL_API,
]

const handleConditionalAction = (conditional, deps) => {
  // Ignore `conditional` and read from `deps` so that we get any loaded dependencies
  let {
    conditional: value,
    conditionalComparison,
    conditionalComparison2,
    comparatorOptions,
  } = deps

  value = value && value.value
  const comparison = conditionalComparison && conditionalComparison.value
  const comparison2 = conditionalComparison2 && conditionalComparison2.value

  if (comparatorOptions) {
    for (const key of Object.keys(comparatorOptions)) {
      comparatorOptions[key] =
        comparatorOptions[key] && comparatorOptions[key].value
    }
  }

  return evaluateCondition(
    value,
    conditional.comparator,
    conditional.source.dataType,
    comparison,
    comparison2,
    comparatorOptions
  )
}

export const executeAction = async (action, helpers, dependencies = {}) => {
  let {
    dispatch,
    navigate,
    getApp,
    getBinding,
    getBindings,
    getParams,
    getBaseURL,
    getStore,
    getComponent,
    getDatasources,
    instantNavigation,
    getFlags,
  } = helpers

  dispatch = dispatch || getStore().dispatch

  let createInputs = []

  const { actions } = action
  let { useInstantNavigation } = action

  const createdObjects = {}
  const customActionOutputs = {}

  const selectorOpts = {
    getParams,
    createdObjects,
    getBinding,
  }

  const getCreatedObject = (datasourceId, tableId) => {
    return createdObjects[`${datasourceId}.${tableId}`]
  }

  const getCustomActionOutput = (customActionId, key) =>
    deepGet(customActionOutputs[customActionId], key.split('.'))

  helpers = {
    ...helpers,
    createdObjects,
    getCreatedObject,
    customActionOutputs,
    getCustomActionOutput,
  }

  let disabledActionList = []
  const potentialDisabledActionsList = []

  const { hasRevisedDataBindings } = getFlags()

  // Default to true for legacy actions with no set value.
  if (useInstantNavigation === undefined) {
    useInstantNavigation = true
  }

  if (
    useInstantNavigation &&
    actions &&
    actions.length > 1 &&
    actions[0].actionType !== actionTypes.NAVIGATE
  ) {
    const linkActions = actions.filter(
      ({ actionType }) => actionType === actionTypes.NAVIGATE
    )
    const hasTerminatingAction = actions.find(({ actionType }) =>
      terminatingActionTypes.includes(actionType)
    )

    // Only do instant navigation if we have a list of actions with exactly one navigation, and that navigation is not first or conditional
    // Do not instant nav if another action in the list stops all subsequent actions on failure
    if (
      linkActions.length === 1 &&
      linkActions[0].options &&
      !linkActions[0].options.conditional &&
      !hasTerminatingAction
    ) {
      instantNavigation(linkActions[0].options)
    }
  }

  const actionsUseLocation = searchForSource(
    actions,
    sourceTypes.DEVICE_LOCATION
  )
  if (actionsUseLocation) {
    const baseUrl = getBaseURL()
    const { id } = getApp()
    await dispatch(setCurrentLocation(baseUrl, id, false))
  }

  for (let num = 0; num < actions.length; num += 1) {
    const { actionType, options } = actions[num]
    if (actionType === actionTypes.CUSTOM) {
      potentialDisabledActionsList.push(num)
    } else if (options.tableId && options.datasourceId) {
      const { tableId, datasourceId } = options
      const datasource = getDatasources()[datasourceId]
      if (isExternalCollection({ datasource, tableId })) {
        potentialDisabledActionsList.push(num)
      }
    }
  }

  if (potentialDisabledActionsList.length !== 0) {
    const app = getApp()

    const { Organization } = app
    const { enabledFeatures } = Organization

    const disableExternalCollectionActions = disableFeature(
      enabledFeatures,
      'customIntegrations'
    )

    if (disableExternalCollectionActions) {
      disabledActionList = potentialDisabledActionsList

      dispatch({ type: ACTION_EXECUTION_DENIED })
    }
  }

  try {
    let hasNavigated = false
    for (let i = 0; i < actions.length; i += 1) {
      if (disabledActionList.includes(i)) continue

      const { id, actionType, options } = actions[i]

      let sleepTime = 0

      const formInputs = getInputs(actions[i], helpers)
      if (formInputs && formInputs.length !== 0) {
        sleepTime = DEBOUNCE_TIME
      }

      await sleep(sleepTime)

      const state = getStore().getState()

      let deps
      let inputs = []

      if (dependencies[id]) {
        deps = dependencies[id]
      } else {
        const spread = getSingleActionDependencies(state, actions[i], helpers)
        deps = spread[0] // eslint-disable-line prefer-destructuring
        inputs = spread[1] // eslint-disable-line prefer-destructuring
      }

      if (options.conditional) {
        const result = handleConditionalAction(options.conditional, deps)
        if (!result) continue
      }

      if (actionType === actionTypes.NAVIGATE) {
        if (hasNavigated) {
          continue
        } else {
          hasNavigated = true
        }
      }

      if (options.fields && options.fields.length) {
        const { fields } = options
        for (const field of fields) {
          if (Array.isArray(field.source)) {
            const { source } = field
            for (const singleSource in source) {
              if (singleSource.source) {
                if (singleSource.source.type === sourceTypes.ACTION_ARGUMENT) {
                  const { source } = singleSource

                  await fileUpload(source, helpers)
                }
              }
            }
          } else if (field.source.type === sourceTypes.ACTION_ARGUMENT) {
            const { source } = field
            await fileUpload(source, helpers)
          }
        }
      }

      if (actionType === actionTypes.CREATE_OBJECT) {
        createInputs = createInputs.concat(inputs, getInputIds(deps))
        const obj = await executeCreate(dispatch, options, deps, helpers)
        const { datasourceId, tableId } = options
        createdObjects[`${datasourceId}.${tableId}`] = obj.id
      } else if (actionType === actionTypes.UPDATE_OBJECT) {
        const { fields } = deps
        const object = createRequestObject(fields)

        let { objectId, datasourceId, tableId } = options

        if (options.fields?.length) {
          const { fields } = options
          for (const index in options.fields) {
            if (index) {
              const field = fields[index]

              if (field?.fieldId && field?.size) {
                object[field.fieldId].size = field.size
              }

              if (
                field &&
                field.source &&
                field.source.options &&
                field.source.options.operation
              ) {
                delete object[field.fieldId]

                const object1Id = deps.bindingId.value
                const object2Id = deps.fields[field.fieldId].value
                const table1Id = options.bindingId.tableId
                const table2Id = field.source.tableId

                const args = {
                  table1Id,
                  object1Id,
                  table2Id,
                  object2Id,
                  fieldId: field.fieldId,
                  baseURL: getBaseURL(),
                  datasourceId: field.source.datasourceId,
                }

                const { CREATE_ASSOCIATION, DELETE_ASSOCIATION } = actionTypes
                const { operation } = field.source.options

                switch (operation) {
                  case CREATE_ASSOCIATION:
                    await dispatch(createAssociation(args))
                    continue
                  case DELETE_ASSOCIATION:
                    await dispatch(deleteAssociation(args))
                    continue
                }
              }
            }
          }
        }

        if (!objectId) {
          if (!options.bindingId) {
            continue
          }

          objectId = deps.bindingId && deps.bindingId.value
          datasourceId = options.bindingId.datasourceId
          tableId = options.bindingId.tableId
        }

        if (objectId) {
          const datasource = getDatasources()[datasourceId]

          await dispatch(
            updateObject(
              getBaseURL(),
              datasourceId,
              tableId,
              objectId,
              object,
              datasource,
              getParams,
              state
            )
          )
        }
      } else if (actionType === actionTypes.DELETE_OBJECT) {
        if (!options.bindingId) {
          continue
        }

        const { datasourceId, tableId } = options.bindingId
        const objectId = deps.bindingId && deps.bindingId.value
        const datasource = getDatasources()[datasourceId]

        if (objectId) {
          await dispatch(
            deleteObject(getBaseURL(), {
              datasourceId,
              tableId,
              objectId,
              datasource,
              getParams,
              state,
            })
          )
        }
      } else if (
        actionType === actionTypes.CREATE_ASSOCIATION ||
        actionType === actionTypes.DELETE_ASSOCIATION
      ) {
        // Create / Delete association

        const { object1, object2, objectId } = options
        const action = actions[i]
        const { fieldId } = action.options || {}

        if (
          !object1 ||
          !object2 ||
          !fieldId ||
          !deps.object1 ||
          !deps.object2 ||
          !deps.fieldId ||
          object1.datasourceId !== object2.datasourceId
        ) {
          continue
        }

        const authToken = unsafeGetToken(object1.datasourceId)
        const opts = { ...selectorOpts, authToken }

        const object1Id = deps.object1.value || getId(object1, opts) || objectId
        const object2Id = deps.object2.value || getId(object2, opts)
        const table1Id = object1.tableId
        const table2Id = object2.tableId

        if (!object1Id || !object2Id) {
          continue
        }

        const args = {
          table1Id,
          object1Id,
          table2Id,
          object2Id,
          fieldId,
          baseURL: getBaseURL(),
          datasourceId: object1.datasourceId,
        }

        if (actionType === actionTypes.CREATE_ASSOCIATION) {
          // Create
          await dispatch(createAssociation(args))
        } else {
          // Delete
          await dispatch(deleteAssociation(args))
        }
      } else if (actionType === actionTypes.NAVIGATE) {
        const paramValues = {}

        if (deps.params) {
          Object.keys(deps.params).forEach(key => {
            paramValues[key] = deps.params[key] && deps.params[key].value
          })
        }

        let bindings = getBindings && getBindings()

        const app = helpers.getApp()

        if (hasRevisedDataBindings) {
          const relatedBindings = addRelatedBindings(
            { ...createdObjects },
            state,
            app
          )
          bindings = { ...relatedBindings, ...bindings, ...createdObjects }
        } else {
          bindings = addRelatedBindings(
            { ...bindings, ...createdObjects },
            state,
            app
          )
        }

        const params = {
          ...paramValues,
          ...bindings,
        }

        navigate({ ...options, params })
      } else if (actionType === actionTypes.EXTERNAL_LINK) {
        const { useSystemBrowser } = options
        const { url } = deps

        if (url && url.value) {
          await openLink(url.value, useSystemBrowser)
        }
      } else if (actionType === actionTypes.AUTHENTICATE) {
        const { datasourceId, errorMessage } = options
        const params = { password: deps.password && deps.password.value }
        const app = helpers.getApp()
        if (options.authType === authTypes.USERNAME) {
          params.username = deps.username && deps.username.value
        } else if (options.authType === authTypes.EMAIL) {
          params.email = deps.email && deps.email.value
        }
        try {
          await dispatch(authenticate(getBaseURL(), datasourceId, params))

          // clear fields on success
          const emailId = deps.email && deps.email.inputId
          const usernameId = deps.username && deps.username.inputId
          const passwordId = deps.password && deps.password.inputId
          const ids = [emailId, usernameId, passwordId]
          createInputs = createInputs.concat(inputs, ids)

          registerDevice(
            app,
            helpers.getDeviceId(),
            helpers.getNotificationsURL(),
            unsafeGetToken(datasourceId),
            helpers.isPreviewer
          ).catch(err => console.log('NOTIFICATIONS REGISTRATION ERROR:', err))
        } catch (err) {
          const type =
            options.authType === authTypes.EMAIL ? 'Email' : 'Username'
          if (errorMessage) {
            Alert.alert(errorMessage)
          } else if (err.response && err.response.data) {
            Alert.alert(err.response.data)
          } else {
            Alert.alert(
              `Invalid ${type} or Password`,
              'Please double-check the information you provided and try again.'
            )
          }

          throw err
        }
      } else if (actionType === actionTypes.SIGNUP) {
        createInputs = createInputs.concat(inputs, getInputIds(deps))
        await executeSignup(dispatch, options, deps, helpers)
      } else if (actionType === actionTypes.LOGOUT) {
        const app = getApp()
        const datasourceIds = Object.keys(app.datasources)

        unregisterDevice(
          app,
          helpers.getDeviceId(),
          helpers.getNotificationsURL(),
          unsafeGetToken(null, app),
          helpers.isPreviewer
        ).catch(err => console.log('NOTIFICATIONS REGISTRATION ERROR:', err))

        dispatch(logout(datasourceIds))
      } else if (actionType === actionTypes.FORGOT_PASSWORD) {
        navigate({
          target: TARGET_FORGOT_PASSWORD,
          transition: transitions.MODAL,
        })
      } else if (
        actionType === actionTypes.AUTHENTICATE_EXTERNAL ||
        actionType === actionTypes.SIGNUP_EXTERNAL
      ) {
        const app = helpers.getApp()
        const datasourceId = Object.keys(app.datasources)[0]

        const authType =
          actionType === actionTypes.AUTHENTICATE_EXTERNAL ? 'login' : 'signup'

        const { customActionId } = options

        const { getBaseURL } = helpers

        try {
          const inputs = {}

          for (const itm in deps) {
            inputs[itm] = deps[itm].value
          }

          const tokenConfig = {
            authToken: app.externalUsers[authType].authToken.formattedKey,
            id: app.externalUsers[authType].id?.formattedKey,
          }

          const config = {
            inputs,
            tokenConfig,
            customActionId,
            appId: app.id,
          }
          await dispatch(
            authenticateExternal(getBaseURL(), datasourceId, config)
          )
          registerDevice(
            app,
            helpers.getDeviceId(),
            helpers.getNotificationsURL(),
            unsafeGetToken(datasourceId),
            helpers.isPreviewer
          ).catch(err =>
            console.error(
              'EXTERNAL USERS - NOTIFICATIONS REGISTRATION ERROR:',
              err
            )
          )
        } catch (err) {
          const authTypeFormatted =
            authType.charAt(0).toUpperCase() + authType.slice(1)
          console.error('AUTHENTICATE EXTERNAL ERROR! ', err)

          const errorMessage = err?.response?.data?.data?.message
            ? err.response.data.data.message
            : `${authTypeFormatted} Failed`

          Alert.alert(errorMessage)
          break
        }
      } else if (actionType === actionTypes.SHARE) {
        if (options.source && deps.source) {
          await share(options.source, deps.source.value, helpers)
        }
      } else if (actionType === actionTypes.SET_INPUT_VALUE) {
        if (options.inputId) {
          dispatch(
            changeValue(options.inputId, deps.source && deps.source.value)
          )
        }
      } else if (actionType === actionTypes.PUSH_NOTIFICATION) {
        sendNotification(options, deps, helpers).catch(err =>
          console.error('ERROR SENDING NOTIFICATION:', err)
        )
      } else if (actionType === actionTypes.NOTIFICATION_PERMISSION) {
        requestPermission()
      } else if (actionType === actionTypes.CALL_API) {
        await executeCallAPI(dispatch, options, deps, helpers)
      } else if (actionType === actionTypes.AUTHENTICATE_API) {
        // Do auth
        await executeAPILogin(dispatch, options, deps, helpers)
      } else if (actionType === actionTypes.CUSTOM) {
        // CUSTOM_ACTIONS
        const { customActionId } = options
        if (customActionId) {
          const outputs = await executeAPICustomAction(
            dispatch,
            options,
            deps,
            helpers
          )

          customActionOutputs[customActionId] = outputs.data.response.data
        }
      } else if (actionType === actionTypes.SIGNUP_API) {
        // Do signup
        await executeAPILogin(dispatch, options, deps, helpers, true)
      } else if (actionType === actionTypes.LOCATION_PERMISSION) {
        const baseUrl = getBaseURL()
        const { id } = getApp()
        await dispatch(setCurrentLocation(baseUrl, id, false))
      } else {
        console.error(`I don't know how to "${actionType}"`)
      }

      const app = getApp()

      logEvent(actionType, app, getComponent(), { ...options }, helpers)
      await logAppAction(
        { actionType, appId: app.id, orgId: app.OrganizationId },
        options,
        helpers
      )
    }

    createInputs.forEach(inputId => {
      if (inputId) {
        dispatch(resetValue(inputId))
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

export const createRequestObject = fields => {
  if (!fields) {
    return {}
  }

  const mappedObject = mapObject(fields, field => {
    const valueIsNestedDeeper =
      field && !Object.prototype.hasOwnProperty.call(field, 'value')
    if (valueIsNestedDeeper) {
      return createRequestObject(field)
    }

    if (typeof field.value === 'number') {
      return field.value
    }

    return field.value || null
  })
  return toDeep(mappedObject)
}

export const executeCreate = async (
  dispatch,
  options,
  dependencies = {}, // eslint-disable-line @typescript-eslint/default-param-last
  helpers
) => {
  const { getBaseURL, getDatasources, getParams, getStore } = helpers
  const { fields = {} } = dependencies
  const { datasourceId, tableId } = options
  const state = getStore().getState()

  const object = createRequestObject(fields)
  const datasource = getDatasources()[datasourceId]

  const { value } = await dispatch(
    createObject(getBaseURL(), {
      datasourceId,
      tableId,
      object,
      datasource,
      getParams,
      state,
    })
  )

  const obj = value.data

  return obj
}

export const executeSignup = async (
  dispatch,
  options,
  dependencies,
  helpers
) => {
  const { getBaseURL } = helpers
  const { datasourceId, errorMessage } = options

  options = { ...options, tableId: 'users' }
  try {
    await executeCreate(dispatch, options, dependencies, helpers)

    const authCredentials = mapObject(dependencies.fields, dep => dep.value)
    await dispatch(authenticate(getBaseURL(), datasourceId, authCredentials))

    registerDevice(
      helpers.getApp(),
      helpers.getDeviceId(),
      helpers.getNotificationsURL(),
      unsafeGetToken(datasourceId),
      helpers.isPreviewer
    ).catch(err => console.log('NOTIFICATIONS REGISTRATION ERROR:', err))
  } catch (err) {
    Alert.alert(errorMessage || 'User email already exists')

    // Need to throw again so we cancel further actions.
    throw err
  }
}

export const executeCallAPI = async (
  dispatch,
  options,
  dependencies,
  helpers
) => {
  const { params } = prepareAPIDependencies(dependencies.params)
  const { datasourceId, endpoint } = options

  const app = helpers.getApp()
  const datasource = app.datasources[datasourceId]

  if (!datasource) {
    throw new Error(`Invalid datasource: ${datasourceId}`)
  }

  if (!endpoint || !endpoint.collectionId || !endpoint.endpointId) {
    throw new Error('Endpoint info missing')
  }

  const { collectionId, endpointId } = endpoint
  const endpointObj = getEndpoint(datasource, collectionId, endpointId)

  const url = apiURL({
    datasource,
    collectionId,
    endpointId,
    apiParams: params,
  })
  const method = getEndpointMethod(endpointObj)

  const response = await request(datasourceId, url, method, params)

  return response
}

const executeAPILogin = async (
  dispatch,
  options,
  dependencies,
  helpers,
  isSignup = false
) => {
  const { datasourceId } = options

  const app = helpers.getApp()
  const datasource = app.datasources[datasourceId]
  const login = isSignup ? datasource.auth.signup : datasource.auth.login

  if (!login) {
    throw new Error('Auth not setup')
  }

  options = {
    ...options,
    endpoint: login.endpoint,
  }

  try {
    const response = await executeCallAPI(
      dispatch,
      options,
      dependencies,
      helpers
    )

    const { fieldId } = login.tokenSource
    const token = response.data[fieldId]

    if (token) {
      dispatch(setAuthToken(datasourceId, token))
    }

    return
  } catch (err) {
    console.warn('AUTH ERROR:', err)
  }

  let errorTitle = 'Invalid Credentials'
  let errorPrompt =
    'Please double-check the information you provided and try again.'

  if (isSignup) {
    errorTitle = 'Error Signup Up'
    errorPrompt = 'You may already have an account with the email address'
  }

  Alert.alert(errorTitle, errorPrompt, [
    { text: 'Try Again', onPress: () => {}, style: 'cancel' },
  ])

  throw new Error('Invalid credentials')
}

export const prepareAPIDependencies = dependencies => {
  const params = {}
  const inputIds = []

  for (const key in dependencies) {
    if (key) {
      params[key] = dependencies[key].value
      inputIds.push(dependencies[key].inputId)
    }
  }

  return { params, inputIds }
}

export const hasLink = action => {
  const actionChain = action.actions || []
  const links = actionChain.filter(a => a.actionType === actionTypes.NAVIGATE)

  return links.length > 0
}
