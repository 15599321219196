import { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'

const INITIAL_WINDOW = Dimensions.get('window')

const useWindowDimensions = () => {
  const [window, setWindow] = useState(INITIAL_WINDOW)

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWindow(window)
    })
    return () => subscription?.remove()
  }, [])

  return window
}
export default useWindowDimensions
