import { endpointTypes } from '@adalo/constants'
import QS from 'qs'

import { encodeFilter } from './filtering'
import {
  isExternalCollection,
  resolveAuth,
  resolveEndpoints,
} from './externalCollections'

const DETAIL_METHODS = [
  endpointTypes.DETAIL,
  endpointTypes.UPDATE,
  endpointTypes.DELETE,
]

export const buildURL = (baseURL, opts) => {
  let {
    appId,
    componentId,
    datasourceId,
    datasource,
    tableId,
    id,
    fieldId,
    includes,
    sort,
    sortReference,
    limit,
    offset,
    columnFilter,
    apiFilter,
    bindingIds,
    appVersion,
    state,
    getParams,
    counts,
    validateMode,
  } = opts

  const filterObj = opts.filter

  if (opts.objectId) {
    throw new Error('Invalid opt supplied to buildURL: objectId')
  }

  const collectionUrl = `${baseURL}/databases/${datasourceId}/tables/${tableId}`
  includes = includes || []

  const queryParams = {
    appId,
    componentId,
    bindingIds: bindingIds && bindingIds.join(','),
    imageMeta: true,
  }

  if (appVersion) {
    queryParams.version = appVersion
  }

  if (validateMode) {
    queryParams.validateMode = true
  }

  if (isExternalCollection({ datasource, tableId })) {
    const auth = resolveAuth({
      datasource,
      tableId,
      state,
      getParams,
    })

    if (auth) {
      queryParams.auth = auth
    }

    const endpoints = resolveEndpoints({
      datasource,
      tableId,
      state,
      getParams,
    })

    if (endpoints) {
      queryParams.endpoints = endpoints
    }
  }

  if (!id) {
    const filter = encodeFilter(filterObj)

    if (filter) {
      queryParams.filter = filter
    }

    if (includes) {
      queryParams.include = includes.join(',')
    }

    if (counts) {
      queryParams.counts = counts.join(',')
    }

    if (sort) {
      queryParams.sort = sort
    }

    if (sortReference) {
      queryParams.sortReference = sortReference
    }

    if (limit) {
      queryParams.limit = limit
    }

    if (offset) {
      queryParams.offset = offset
    }

    if (columnFilter) {
      queryParams.column_filter = columnFilter
    }

    if (apiFilter) {
      queryParams.api_filter = apiFilter
    }

    return `${collectionUrl}?${QS.stringify(queryParams)}`
  }

  let url = `${collectionUrl}/${id}`

  if (fieldId) {
    url = `${url}/${fieldId}`
  }

  if (includes.length > 0) {
    queryParams.include = includes.join(',')
  }

  return `${url}?${QS.stringify(queryParams)}`
}

export const apiURL = opts => {
  let { datasource, collectionId, endpointId, apiParams, id } = opts

  const { collections } = datasource
  const collection = collections[collectionId]
  let endpoint = collection.endpoints.filter(e => e.id === endpointId)[0]

  apiParams = { id, ...apiParams }

  if (!endpoint) {
    const detailEndpoint = collection.endpoints.filter(
      e => e.type === 'detail'
    )[0]
    const listEndpoint = collection.endpoints.filter(e => e.type === 'list')[0]

    if (id && detailEndpoint && detailEndpoint.enabled) {
      endpoint = detailEndpoint
    } else if (listEndpoint && listEndpoint.enabled) {
      endpoint = listEndpoint
    }
  }

  if (!endpoint) {
    return null
  }

  const endpointURL = getEndpointURL(collection, endpoint)
  const url = parametrizeURL(endpointURL, apiParams)

  return joinURL(datasource.baseURL, url)
}

export const getEndpointURL = (collection, endpoint) => {
  let url = `/${collection.baseURL}/`

  if (DETAIL_METHODS.includes(endpoint.type)) {
    url = `${url}/:id`
  }

  if (endpoint.url) {
    url = endpoint.url
  }

  return url
}

export const joinURL = (...urlParts) => {
  let url = urlParts[0] || ''

  for (let i = 1; i < urlParts.length; i += 1) {
    if (url.match(/\/$/)) {
      url = url.slice(0, -1)
    }

    let nextPart = urlParts[i]

    if (nextPart.match(/^\//)) {
      nextPart = nextPart.slice(1)
    }

    url = `${url}/${nextPart}`
  }

  return url
}

export const parametrizeURL = (url, params = {}) => {
  const parts = url.split('/')

  const result = parts.map(part => {
    if (part.match(/^:/)) {
      const key = part.slice(1)

      return params[key]
    }

    return part
  })

  return result.join('/')
}

export const loginUrl = (baseURL, datasourceId) => {
  return `${baseURL}/databases/${datasourceId}/sessions`
}

export const loginUrlExternal = (baseURL, datasourceId) => {
  return `${baseURL}/databases/${datasourceId}/sessions/external_login`
}

export const encodeURL = url => {
  return encodeURIComponent(url).replace(/'/g, '%27').replace(/"/g, '%22')
}

export const decodeURL = url => {
  return decodeURIComponent(url.replace(/\+/g, ' '))
}

export const normalizeURL = url => {
  if (!url) {
    return url
  }

  if (!url.match(/^[a-z]+:/)) {
    url = `http://${url}`
  }

  if (url.startsWith('http')) {
    const prefixRegex = /https?:\/\//g
    const suffixRegex = /[?#](.*)/g

    const prefix = url.match(prefixRegex)
    const protocol = prefix ? prefix[0] : ''

    const parsedUrl = url.replace(prefixRegex, '').replace(suffixRegex, '')

    const suffix = url.match(suffixRegex) || ''

    const encodedSuffix = Array.isArray(suffix)
      ? encodeURI(decodeURI(suffix[0])).replace('%25', '%')
      : suffix

    const encodedUrl = parsedUrl
      .split('/')
      .map(component => {
        return encodeURL(decodeURL(component))
      })
      .join('/')

    return protocol + encodedUrl + encodedSuffix
  }

  return encodeURI(decodeURI(url))
}
