import { SECTION } from '@adalo/constants'
import PushGraph from './types/PushGraph'
import {
  RunnerPartialObject,
  DeviceVisibility,
} from './types/RunnerPartialObject'

export interface FlatRunnerPartialObject {
  name?: string
  id?: string
  type?: string
  children?: RunnerPartialObject[]
  pushGraph?: PushGraph
  deviceVisibility?: DeviceVisibility
  variableHeight?: boolean
  itemId?: number
  x?: number
  y?: number
  adjustedY?: number
  height?: number
  width?: number
  newHeight?: number | undefined
  newY?: number | undefined
}

export const buildRunnerPartialObject = ({
  name = Math.random().toString(),
  id = Math.random().toString(),
  type = SECTION,
  children = [],
  pushGraph = { nodeIds: [], edges: [] },
  variableHeight = false,
  x = 0,
  y = 0,
  adjustedY = 0,
  height = 100,
  width = 100,
  deviceVisibility = undefined,
  newHeight = undefined,
  newY = undefined,
  itemId = undefined,
}: FlatRunnerPartialObject): RunnerPartialObject => ({
  id,
  type,
  children,
  pushGraph,
  variableHeight,
  attributes: {
    name,
    x,
    adjustedY,
    y,
    height,
    width,
    deviceVisibility,
  },
  itemId,
  newHeight,
  newY,
})
