import { ReactElement, useMemo, useCallback } from 'react'
import { getNodeY, PushTreeState, getNode } from 'ducks/pushTreeMap'
import { GROUP, LIST, responsivePositioningOptions } from '@adalo/constants'
import { useSelector } from 'react-redux'
import { Dimensions, LayoutChangeEvent } from 'react-native'
import {
  getResponsiveObject,
  getRenderObject,
  getPositioningLayout,
} from 'utils/responsive'

import { RunnerObject, Layout } from 'types'

const { FIXED_ON_SCROLL } = responsivePositioningOptions

export const useResponsiveObject = (
  object: RunnerObject,
  newY: number
): { renderObject: RunnerObject; positioningLayout: Layout } => {
  const { width } = Dimensions.get('window')

  const deviceObject = useMemo(
    () => getResponsiveObject(object, width),
    [object, width]
  )

  const renderObject = useMemo(
    () => getRenderObject(deviceObject),
    [deviceObject]
  )

  const positioningLayout = getPositioningLayout(deviceObject, newY)

  return { renderObject, positioningLayout }
}

type State = { pushTreeMap: PushTreeState }

export const usePushNode = (
  componentId: string,
  pushId: string,
  isFixed: boolean
): { newY: number; nodeHeight: number } => {
  const newY = useSelector(state =>
    getNodeY(state as State, componentId, pushId)
  )

  const node = useSelector(state =>
    getNode(state as State, componentId, pushId)
  )

  let nodeHeight = node?.height || 0

  if (isFixed) {
    // fixed objects with a list inside need to have their height set to the original height
    if (node?.pushNode?.children?.some(c => c.type === LIST)) {
      nodeHeight = node?.pushNode?.originalHeight || nodeHeight
    }

    return { nodeHeight, newY: 0 }
  }

  return { newY, nodeHeight }
}

export type HandleLayoutChangeFunction = (e: LayoutChangeEvent) => void

export interface HandleLayoutParameters {
  renderObject: RunnerObject
  setHeight: (pushId: string, obj: RunnerObject, height: number) => void
  nodeHeight: number
  pushId: string
}

export const useHandleLayoutChange = ({
  renderObject,
  setHeight,
  nodeHeight,
  pushId,
}: HandleLayoutParameters): HandleLayoutChangeFunction => {
  const handleLayoutChange = useCallback(
    (event: LayoutChangeEvent): void => {
      const { height: newHeight } = event.nativeEvent.layout

      if (
        renderObject.type !== GROUP &&
        renderObject.type !== LIST &&
        renderObject.responsivity?.verticalPositioning !== FIXED_ON_SCROLL &&
        newHeight !== nodeHeight
      ) {
        setHeight(pushId, renderObject, newHeight)
      }
    },
    [renderObject, setHeight, nodeHeight, pushId]
  )

  return handleLayoutChange
}

export const useRenderedChildren = (
  renderObject: RunnerObject,
  renderChildren: (children: RunnerObject[]) => ReactElement[]
): null | ReactElement[] => {
  const { children } = renderObject

  const isResponsiveList = renderObject.type === LIST

  const renderedChildren = useMemo(
    () => (children && !isResponsiveList ? renderChildren(children) : null),
    [children]
  )

  return renderedChildren
}
