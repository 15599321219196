import { dataTypes } from '@adalo/constants'
import { evaluateCondition } from './conditions'
import { getBindingValue } from './dependencies'

export const empty = itm => {
  return [null, undefined, ''].includes(itm)
}

export const getDataType = (filter, data, comparison, opts) => {
  const { fieldId } = filter

  if (fieldId && fieldId.source) {
    return fieldId.source.dataType
  }

  if (!data || data.length === 0) {
    return null
  }

  const { datasourceId, tableId } = data[0]._meta

  const app = opts.getApp()

  const datasource = app.datasources[datasourceId]
  const table = datasource && datasource.tables[tableId]

  const field = table && table.fields[fieldId]

  return (field && field.type) || dataTypes.TEXT
}

export const evaluateFilters = (filters, opts) => {
  if (!filters || filters.length === 0) {
    return []
  }

  const sanitizedFilters = [...filters]

  for (let i = 0; i < filters.length; i += 1) {
    sanitizedFilters[i] = sanitizedFilters[i].map(filterItem => {
      const { comparison, comparator, fieldId } = filterItem

      if (['true', 'false'].includes(comparator)) {
        return filterItem
      }

      const isBoundValue =
        typeof fieldId === 'object' || typeof comparison === 'object'

      if (isBoundValue) {
        const value = getBindingValue(comparison, opts)

        if (value === undefined || value === '') {
          return false
        }
      }

      return filterItem
    })
  }

  return sanitizedFilters
}

export const applyFilter = (data, filters, getBindingValue, opts) => {
  if (!filters || filters.length === 0) {
    return data
  }

  if (!Array.isArray(filters)) {
    filters = [filters]
  }
  const isSimpleFilter = !Array.isArray(filters[0])

  if (isSimpleFilter) {
    filters = [filters]
  }

  filters = evaluateFilters(filters, opts)

  const hasFullfilledFilter = filters.length > 0

  const uniqueFilteredData = hasFullfilledFilter ? new Set() : data

  for (const filter of filters) {
    let filteredData = [].concat(data)

    filter.forEach(filter => {
      if (!filter || !filter.fieldId) {
        return
      }

      const boundOptions = {}
      if (filter.comparatorOptions) {
        for (const key of Object.keys(filter.comparatorOptions)) {
          boundOptions[key] = getBindingValue(filter.comparatorOptions[key])
        }
      }

      const comparison = getBindingValue(filter.comparison)
      const comparison2 = getBindingValue(filter.comparison2)

      const dataType = getDataType(filter, data, filter.comparison, opts)

      const { fieldId } = filter
      let getter = () => null

      if (typeof fieldId === 'string') {
        getter = itm => itm[fieldId]
      } else {
        getter = itm => {
          const getBinding = listObjectId => {
            return itm
          }

          return getBindingValue(fieldId, { getBinding })
        }
      }

      filteredData = applyFilterSub(
        filteredData,
        dataType,
        getter,
        filter.comparator,
        comparison,
        comparison2,
        boundOptions
      )
    })

    filteredData.forEach(item => uniqueFilteredData.add(item))
  }

  return Array.from(uniqueFilteredData)
}

const applyFilterSub = (
  data,
  dataType,
  getter,
  comparator,
  comparison,
  comparison2,
  comparatorOptions
) => {
  if (!data || !Array.isArray(data)) {
    return data
  }

  const result = data.filter(row => {
    const itm = getter(row)

    try {
      return evaluateCondition(
        itm,
        comparator,
        dataType,
        comparison,
        comparison2,
        comparatorOptions
      )
    } catch (err) {
      console.error('filter error:', err)

      return false
    }
  })

  return result
}
