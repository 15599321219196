import { RunnerObject, Layout, TransformValue } from 'types'
import { responsivePositioningOptions } from '@adalo/constants'
import {
  getDeviceType,
  DEVICE_TYPE_MOBILE,
  DEVICE_TYPE_TABLET,
  DEVICE_TYPE_DESKTOP,
} from './device'

const { FIXED_ON_SCROLL } = responsivePositioningOptions

const getAdjustedLayoutForObject = (obj: any, deviceType: string) => {
  switch (obj.attributes?.libraryName) {
    case '@adalo/navigation':
      if (
        (obj.attributes?.onDesktop === 'top' &&
          deviceType === DEVICE_TYPE_DESKTOP) ||
        (obj.attributes?.onTablet === 'top' &&
          deviceType === DEVICE_TYPE_TABLET) ||
        (obj.attributes?.onMobile === 'top' &&
          deviceType === DEVICE_TYPE_MOBILE)
      ) {
        return {
          width: '100%',
          height: 66,
        }
      }

      return {}
    default:
      return {}
  }
}

export const getResponsiveObject = (obj: any, width?: number) => {
  if (!obj) {
    return
  }

  const deviceType: string = getDeviceType(width) as string

  if (obj.attributes?.shared?.[deviceType] !== false) {
    return {
      ...obj,
      layout: {
        ...obj.layout,
        ...getAdjustedLayoutForObject(obj, deviceType),
      },
    }
  }

  const deviceObj = obj[deviceType]
  const responsivity = deviceObj.responsivity || obj.responsivity
  const attributes = deviceObj.attributes
    ? { ...obj.attributes, ...deviceObj.attributes }
    : obj.attributes

  const layout = deviceObj.layout || obj.layout

  return {
    // unique
    ...obj,
    responsivity,
    attributes,
    layout: {
      ...layout,
      ...getAdjustedLayoutForObject(obj, deviceType),
    },
  }
}

export const getRenderObject = (object: RunnerObject): RunnerObject => {
  const top = object.layout.top ? 0 : undefined
  const bottom = object.layout.bottom ? 0 : undefined
  const newObject = {
    ...object,
    layout: {
      ...object.layout,
      marginTop: 0,
      marginLeft: 0,
      left: 0,
      right: 0,
      top,
      bottom,
      width: '100%',
    },
  }

  return newObject
}

export const isPercentage = (a: number | string): a is string =>
  typeof a === 'string'

export const getPositioningLayout = (
  object: RunnerObject,
  newY: number
): Layout => {
  const translateX = object.layout.marginLeft || 0
  const translateY = object.layout.marginTop || 0
  const { left, right, bottom, width, height, zIndex } = object.layout
  const transform: TransformValue[] = isPercentage(translateX)
    ? []
    : [{ translateX }]

  let { top } = object.layout
  if (object.responsivity?.verticalPositioning !== FIXED_ON_SCROLL) {
    top = newY || object.layout.top
  }

  if (!isPercentage(translateY)) {
    transform.push({ translateY })
  }

  return {
    position: 'absolute',
    top,
    transform,
    left,
    right,
    bottom,
    width,
    height,
    zIndex,
  }
}

export const getPushId = (
  listItemId: string,
  childId: string,
  itemId: number,
  parentPushId: string
): string => {
  let pushId = childId

  // inside a direct non list parent (so that we use the list item push Id,
  // as the base and not the groups one, so elements don't go unecessarily huge,
  if (listItemId && !itemId) {
    const lastIndex = parentPushId.lastIndexOf(' -- ')
    const parentPushIdWithoutGroupId = parentPushId.substring(0, lastIndex)
    pushId = `${parentPushIdWithoutGroupId} -- ${childId}`
    // inside a direct list parent
  } else if (itemId) {
    pushId = `${parentPushId} - ${itemId} -- ${childId}`
  }

  return pushId
}
