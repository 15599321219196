import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistReducer } from 'redux-persist'

import data from './data'
import auth from './auth'
import formInputs from './formInputs'
import notifications from './notifications'
import toasts from './toasts'
import random from './random'
import location from './location'
import pushTreeMap from './pushTreeMap'

const store = initializeStore()

export const getStore = () => {
  return store
}

export function initializeStore() {
  const middleware = applyMiddleware(thunkMiddleware, promiseMiddleware())
  const rootReducer = combineReducers({
    data,
    auth,
    notifications,
    formInputs,
    toasts,
    random,
    location,
    pushTreeMap,
  })

  const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    blacklist: ['data', 'toasts', 'location', 'pushTreeMap'],
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  // dev tools
  let composer = compose

  if (
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true })
  }

  return createStore(persistedReducer, composer(middleware))
}

// exported reducers
export { default as data } from './data'
export { default as auth } from './auth'
export { default as formInputs } from './formInputs'
export { default as notifications } from './notifications'
export { default as toasts } from './toasts'
export { default as random } from './random'
export { default as location } from './location'
export { default as pushTreeMap } from './pushTreeMap'
