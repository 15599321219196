const SHOW_NOTIFICATION = Symbol('SHOW_NOTIFICATION')
const HIDE_NOTIFICATION = Symbol('HIDE_NOTIFICATION')
const CLICK_NOTIFICATION = Symbol('CLICK_NOTIFICATION')

let counter = 0

const INITIAL_STATE = {
  notification: null,
  callback: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action) => {
  if (action.type === SHOW_NOTIFICATION) {
    let { notification, callback } = action

    notification = {
      ...notification,
      id: counter,
    }

    counter += 1

    return {
      notification,
      callback,
    }
  }

  if (action.type === HIDE_NOTIFICATION) {
    return INITIAL_STATE
  }

  if (action.type === CLICK_NOTIFICATION) {
    const { callback } = state

    callback()

    return INITIAL_STATE
  }

  return state
}

// Actions

export const showNotification = (notification, callback) => ({
  type: SHOW_NOTIFICATION,
  notification,
  callback,
})

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
})

export const clickNotification = () => ({
  type: CLICK_NOTIFICATION,
})

// Selectors

export const getCurrentNotification = state => {
  return state.notifications.notification
}
