import { bindingTypes } from '@adalo/constants'
import { evaluateCondition } from './conditions'
import { evaluateBinding, getBindingValue } from './dependencies'
import { getDeviceType } from './device'

export const getVisible = (state, object, opts, device) => {
  const { visibility = {}, deviceVisibility = null } = object.attributes

  if (deviceVisibility) {
    const deviceType = device || getDeviceType()
    const visibleOnDevice = deviceVisibility[deviceType]
    if (!visibleOnDevice) {
      return false
    }
  }

  if (
    !visibility ||
    !visibility.source ||
    visibility.bindingType !== bindingTypes.VISIBILITY
  ) {
    return true
  }

  const { comparator, source } = visibility

  const binding = evaluateBinding(state, visibility, opts)
  const comp1 = getBindingValue(visibility.comparison, { ...opts, state })
  const comp2 = getBindingValue(visibility.comparison2, { ...opts, state })
  const boundComparatorOptions = {}

  if (visibility.comparatorOptions) {
    for (const key of Object.keys(visibility.comparatorOptions)) {
      boundComparatorOptions[key] = getBindingValue(
        visibility.comparatorOptions[key],
        { ...opts, state }
      )
    }
  }

  return evaluateCondition(
    binding,
    comparator,
    source.dataType,
    comp1,
    comp2,
    boundComparatorOptions
  )
}

export const getVisibleOnDevice = (object, deviceType) => {
  const { deviceVisibility = null } = object.attributes

  if (deviceVisibility) {
    deviceType = deviceType || getDeviceType()

    return deviceVisibility[deviceType]
  }

  return true
}
