import React from 'react'
import { StyleSheet, View } from 'react-native'

// components
import BaseObject, { BaseObjectProps } from 'components/BaseObject'
import ActionWrapper from 'components/ActionWrapper'

export interface SectionProps extends BaseObjectProps {
  component?: any
  bindingData?: {}
}

export default class Section<T extends SectionProps> extends BaseObject<T> {
  additionalStyles() {
    return {}
  }

  renderBackground() {
    return null
  }

  getHeight() {
    const { nodeHeight, isResponsiveComponent, object } = this.props
    const { layout } = object
    if (
      isResponsiveComponent &&
      object.children?.length &&
      nodeHeight !== undefined
    ) {
      return nodeHeight
    }
    return layout.height
  }

  render() {
    const { object, children, bindingData } = this.props
    const { attributes, layout } = object

    const {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      ...layoutStyles
    } = layout

    const wrapperStyles = {
      ...layoutStyles,
      ...this.additionalStyles(),
      opacity: attributes.opacity,
      height: this.getHeight(),
    }

    const innerWrapperStyles = {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
    }

    const borderOffset = this.getBorderOffset()
    const borderStyles = this.borderStyles()
    const borderWidth = borderStyles.borderWidth || 0

    const borderPadding = 0.5 * borderWidth + borderOffset

    const backgroundStyles = {
      ...this.shadowStyles(),
      ...borderStyles,
      ...this.backgroundStyles(),
      left: -borderPadding,
      top: -borderPadding,
      right: -borderPadding,
      bottom: -borderPadding,
    }

    const backgroundOverlayStyles = {
      borderRadius: this.getBorderRadius(true),
    }

    const backgroundOverlayInnerStyles = {
      flex: 1,
      margin: -borderPadding,
    }

    return (
      <ActionWrapper
        bindingData={bindingData}
        object={object}
        style={[wrapperStyles]}
      >
        <View style={[styles.background, backgroundStyles as any]}>
          <View style={[styles.backgroundOverlay, backgroundOverlayStyles]}>
            <View style={backgroundOverlayInnerStyles}>
              {this.renderBackground()}
            </View>
          </View>
        </View>
        <View style={[innerWrapperStyles, { height: layout.height }]}>
          {children}
        </View>
      </ActionWrapper>
    )
  }
}

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
  },
})
