import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  View,
  Text,
  StyleSheet,
  Animated,
  TouchableWithoutFeedback,
} from 'react-native'
import { isEqual as _isEqual } from 'lodash'
import Icon from 'react-native-vector-icons/dist/MaterialIcons'
import { getCurrentToast, removeToast } from '../ducks/toasts'

const OPACITY_HIDDEN = 0
const OPACITY_VISIBLE = 1
const TOAST_TTL = 5000 // 5 seconds

class Toast extends Component {
  state = {
    opacity: new Animated.Value(OPACITY_HIDDEN),
  }

  handlePress = () => {
    const { opacity } = this.state
    const { removeToast } = this.props

    opacity.stopAnimation(() => {
      Animated.timing(opacity, {
        toValue: OPACITY_HIDDEN,
        duration: 250,
        useNativeDriver: true,
      }).start(() => removeToast())
    })
  }

  componentDidUpdate(prevProps) {
    // either will force an update
    // - cleared
    // - new (or updated with fresh `id` to reset the animation)
    if (!_isEqual(prevProps, this.props)) {
      const { toast, removeToast } = this.props
      const { opacity } = this.state

      if (toast) {
        Animated.sequence([
          Animated.timing(opacity, {
            toValue: OPACITY_VISIBLE,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(opacity, {
            toValue: OPACITY_HIDDEN,
            delay: TOAST_TTL,
            duration: 500,
            useNativeDriver: true,
          }),
        ]).start(({ finished }) => {
          if (finished) {
            removeToast()
          }
        })
      } else {
        opacity.stopAnimation()
      }
    }
  }

  render() {
    const { opacity } = this.state
    const { toast } = this.props

    if (!toast) {
      return null
    }

    return (
      <Animated.View style={{ opacity }}>
        <TouchableWithoutFeedback onPress={this.handlePress}>
          <View style={styles.container}>
            <Icon
              style={styles.icon}
              size={28}
              name="error-outline"
              color="#eee"
            />
            <Text style={styles.toastBody}>{toast.body}</Text>
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 12,
    marginBottom: 12,
    backgroundColor: '#333',
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    margin: 6,
  },
  toastBody: {
    margin: 6,
    marginLeft: 3,
    fontSize: 14,
    fontWeight: '400',
    color: '#eee',
  },
})

const mapStateToProps = state => ({
  toast: getCurrentToast(state),
})

export default connect(mapStateToProps, { removeToast })(Toast)
