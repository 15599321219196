import React, { useEffect, useMemo, ReactElement, memo } from 'react'
import { Platform, StyleProp, View, ViewStyle } from 'react-native'
import { responsivePositioningOptions } from '@adalo/constants'
import { normalizeAttributes } from 'utils/styles'

import {
  usePushNode,
  useResponsiveObject,
  useHandleLayoutChange,
  useRenderedChildren,
} from 'hooks/responsive'

import { RunnerObject, Attributes, Screen } from 'types'

const { FIXED_ON_SCROLL } = responsivePositioningOptions

export interface ResponsiveComponentProps {
  branding: unknown
  ObjectClass: React.FC
  object: RunnerObject
  component: Screen

  getBaseProps: (
    obj: RunnerObject,
    types: Record<string, any>
  ) => React.Props<React.FC>
  renderChildren: (children: RunnerObject[]) => ReactElement[]

  pushId: string
  visible: boolean
  visibleOnDevice: boolean
  setHeight: (pushId: string, obj: RunnerObject, height: number) => void
  setVisible: (
    pushId: string,
    obj: RunnerObject,
    visible: boolean,
    visibleOnDevice: boolean
  ) => void
  bindingData: unknown[]
}

export const ResponsiveComponentNoMemo = ({
  branding,
  ObjectClass,
  object,
  component,

  getBaseProps,
  renderChildren,

  setHeight,
  setVisible,
  visible,
  visibleOnDevice,

  pushId,
  bindingData,
}: ResponsiveComponentProps): ReactElement | null => {
  const isFixed = object?.responsivity?.verticalPositioning === FIXED_ON_SCROLL
  const { nodeHeight, newY } = usePushNode(component.id, pushId, isFixed)
  const { renderObject, positioningLayout } = useResponsiveObject(object, newY)

  const baseProps = useMemo(
    () => getBaseProps(renderObject, { newY, nodeHeight, bindingData }),
    [renderObject, newY, nodeHeight, bindingData]
  )

  const handleLayoutChange = useHandleLayoutChange({
    renderObject,
    setHeight,
    nodeHeight,
    pushId,
  })

  useEffect(() => {
    setVisible(pushId, renderObject, visible, visibleOnDevice)
  }, [visible, pushId, renderObject])

  const renderedChildren = useRenderedChildren(renderObject, renderChildren)

  if (!visible) {
    return null
  }

  const { attributes } = renderObject

  if (branding) {
    renderObject.attributes = normalizeAttributes(
      renderObject.attributes,
      branding as Object
    ) as Attributes
  }

  // positioningLayout
  let child = <ObjectClass {...baseProps}>{renderedChildren}</ObjectClass>

  if (attributes?.variableHeight) {
    child = (
      <View pointerEvents="box-none" style={{ position: 'relative' }}>
        {child}
      </View>
    )
  }

  const minHeightStyle: StyleProp<ViewStyle> = {}
  if (nodeHeight && Platform.OS === 'android') {
    // The wrapping View must completely wrap it's child for all the content within it to be tappable in android.
    // If nodeHeight isn't set as the height, it'll only have the object.layout height set, which could be less than
    // the inner child's height if it has variable height content, and not all the content within it would be tappable.
    minHeightStyle.minHeight = nodeHeight
  }

  return (
    <View
      onLayout={handleLayoutChange}
      pointerEvents="box-none"
      style={[positioningLayout, minHeightStyle]}
    >
      {child}
    </View>
  )
}

export const ResponsiveComponent = memo(ResponsiveComponentNoMemo)
