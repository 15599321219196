type Task = () => any | Promise<any>
/**
 * This scheduler runs the supplied task on a scheduled interval.
 * Unlike `setInterval`, it will ensure that a long running task is done before starting a new one.
 * @param task the task to run
 * @param interval the interval
 * @returns A function to stop the recurring task
 */
export const scheduleRecurringTask = (task: Task, interval: number) => {
  let isActive = true
  let timeoutId: number | undefined

  const scheduleNextRun = () => {
    timeoutId = window.setTimeout(async () => {
      try {
        await task()
      } catch (e) {
        console.error('An error occured in scheduled task:', e)
      } finally {
        if (isActive) {
          scheduleNextRun()
        }
      }
    }, interval)
  }

  scheduleNextRun()

  const stop = () => {
    isActive = false
    window.clearTimeout(timeoutId)
  }
  return stop
}
