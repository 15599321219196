const cache = { cache: 'force-cache' }
const START_SIZE = 32

export const getScaledSources = (url, width, height, filename, size) => {
  const original = { ...cache, width, height, uri: url }
  const results = [original]

  const unit =
    width > height
      ? { width: 1, height: height / width }
      : { width: width / height, height: 1 }

  for (let size = START_SIZE; unit.width * size < width; size *= 1.5) {
    const width = Math.round(size * unit.width)
    const height = Math.round(size * unit.height)

    results.push({
      ...cache,
      width,
      height,
      uri: `${url}?w=${width}`,
      filename,
      size,
    })
  }

  return results
}
