import React from 'react'
import PropTypes from 'prop-types'

import {
  View,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
} from 'react-native'

import BaseImageUpload, { connectImageUpload } from './BaseImageUpload'
import FullWidth from './FullWidth'

import Icon from './images/image-large.png'

export class ImageUpload extends BaseImageUpload {
  static contextTypes = {
    getBaseURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
  }

  componentWillUnmount() {
    const { object, resetValue } = this.props

    if (resetValue && object) {
      resetValue(object.id)
    }
  }

  renderImage() {
    const { setUploading } = this.props
    const pending = this.getPending()
    const imageURL = this.getImageURL()

    if (setUploading) {
      setUploading(pending)
    }

    if (imageURL) {
      return (
        <View style={styles.preview}>
          <Image
            style={[styles.previewImage, pending ? styles.faded : {}]}
            source={{ uri: imageURL }}
            resizeMode="contain"
          />
          {pending ? <ActivityIndicator color="#999999" /> : null}
        </View>
      )
    }

    return <Image source={Icon} style={styles.icon} />
  }

  render() {
    const { object, setUploading } = this.props
    const { attributes, layout } = object
    const { height } = attributes

    if (object.attributes.imageUploadType === 'fullWidth') {
      return <FullWidth {...this.props} setUploading={setUploading} />
    }

    const borderRadius = this.getBorderRadius()

    const { opacity } = this.getOpacity()

    return (
      <View style={layout}>
        <TouchableOpacity onPress={this.handlePress}>
          <View style={[styles.wrapper, { height, borderRadius, opacity }]}>
            {this.renderImage()}
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#eee',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  preview: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
  },
  previewImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  faded: {
    opacity: 0.5,
  },
  icon: {
    opacity: 0.2,
    width: 36,
    height: 36,
  },
})

export default connectImageUpload(ImageUpload)
