/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'

import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
} from 'react-native'

import PropTypes from 'prop-types'
import { NAVIGATION_BACK } from '@adalo/constants'
import axios from 'axios'
import validator from 'email-validator'
import { connect } from 'react-redux'

import { contrastWithBackground } from '../../utils/colors'
import { currentUserSelector } from '../../utils/selectors'

import { getAuthToken, setTemporaryLoginStatus } from '../../ducks/auth'
import { request } from '../../utils/networking'
import Loading from '../Loading'

class ForgotPasswordForm extends Component {
  render() {
    const {
      app,
      error,
      layoutGuides,
      value,
      onChange,
      onSubmit,
      onCancel,
      isSending,
    } = this.props
    const { top } = layoutGuides
    const { branding, primaryPlatform } = app

    const backgroundColor = branding.secondary
    const color = contrastWithBackground(backgroundColor)
    const margin = primaryPlatform === 'web' ? 'auto' : 0
    const mobilePadding = primaryPlatform !== 'web' ? top + 32 : 0

    return (
      <View style={styles.wrapper}>
        <View style={[styles.container, { paddingTop: mobilePadding, margin }]}>
          <KeyboardAvoidingView>
            <Text style={[styles.headerText]}>Password Reset</Text>
            <Text style={[styles.text]}>
              Enter your email address and we&apos;ll send you instructions on
              how to reset your password.
            </Text>

            <View style={styles.inputWrapper}>
              <Text style={styles.label}>Email</Text>
              <TextInput
                autoCapitalize="none"
                onChangeText={onChange}
                style={styles.input}
                placeholder="Enter email..."
                keyboardType="email-address"
                value={value}
              />
              {error ? (
                <Text style={[styles.text, styles.error]}>{error}</Text>
              ) : null}
            </View>

            <TouchableOpacity
              style={[styles.button, styles.submit, { backgroundColor }]}
              onPress={onSubmit}
              disabled={isSending}
            >
              {isSending ? (
                <ActivityIndicator color="#999999" />
              ) : (
                <Text style={[styles.buttonText, { color }]}>Submit</Text>
              )}
            </TouchableOpacity>
            <TouchableOpacity style={[styles.button]} onPress={onCancel}>
              <Text style={[styles.buttonText, styles.cancelButtonText]}>
                Cancel
              </Text>
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </View>
    )
  }
}

class ForgotPasswordSuccess extends Component {
  render() {
    const { app, handleClose, layoutGuides, value } = this.props
    const { top } = layoutGuides
    const { branding, primaryPlatform } = app

    const backgroundColor = branding.secondary
    const color = contrastWithBackground(backgroundColor)
    const margin = primaryPlatform === 'web' ? 'auto' : 0
    const mobilePadding = primaryPlatform !== 'web' ? top + 32 : 0

    return (
      <View style={styles.wrapper}>
        <View style={[styles.container, { paddingTop: mobilePadding, margin }]}>
          <Text style={[styles.headerText]}>An email is on its way!</Text>
          <Text style={[styles.text]}>
            We sent an email to{' '}
            <Text style={styles.bold}>{value || 'david@adalo.com'}</Text> with a
            temporary password and instructions on how to reset your password.
          </Text>
          <TouchableOpacity
            style={[
              styles.button,
              styles.submit,
              styles.closeButton,
              { backgroundColor },
            ]}
            onPress={handleClose}
          >
            <Text style={[styles.buttonText, { color }]}>Awesome</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

export class ForgotPassword extends Component {
  static contextTypes = {
    getApp: PropTypes.func,
    getBaseURL: PropTypes.func,
    navigate: PropTypes.func,
  }

  state = {
    email: '',
    isEmailSent: false,
    error: '',
    isSending: false,
  }

  onChange = email => {
    const { error } = this.state

    if (error) {
      if (validator.validate(email)) {
        this.setState({ error: '' })
      }
    }

    this.setState({ email })
  }

  onSubmit = () => {
    const { getBaseURL, getApp } = this.context
    const { datasourceId } = this.props
    const { email } = this.state

    if (!validator.validate(email)) {
      this.setState({ error: `Please enter a valid email address ${email}` })
      return null
    }

    this.setState({ isSending: true })

    const { name } = getApp()
    const baseURL = getBaseURL()

    const url = `${baseURL}/databases/${datasourceId}/sessions/forgot_password`

    const appName = name
    const body = { email, appName }

    axios
      .post(url, body)
      .then(res => {
        this.setState({ isEmailSent: true, isSending: false })
      })
      .catch(err => {
        this.setState({
          error: 'An error occurred, please try again',
          isEmailSent: false,
          isSending: false,
        })
      })
  }

  onCancel = () => {
    const { navigate } = this.context
    return navigate({ target: NAVIGATION_BACK })
  }

  render() {
    const { email, isEmailSent, isSending, error } = this.state
    const { layoutGuides, screenLoading } = this.props
    const { getApp } = this.context
    const app = getApp()
    if (screenLoading) {
      return (
        <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
          <Loading />
        </View>
      )
    }

    if (isEmailSent) {
      return (
        <ForgotPasswordSuccess
          app={app}
          value={email}
          handleClose={this.onCancel}
          layoutGuides={layoutGuides}
        />
      )
    }

    return (
      <ForgotPasswordForm
        app={app}
        layoutGuides={layoutGuides}
        value={email}
        error={error}
        isSending={isSending}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
      />
    )
  }
}

class ResetPassword extends Component {
  state = {
    password: '',
    error: '',
    isSubmitting: false,
  }

  static contextTypes = {
    getApp: PropTypes.func,
    getBaseURL: PropTypes.func,
    navigate: PropTypes.func,
  }

  componentDidMount() {
    const { setTemporaryLoginStatus } = this.props

    // if this component has mounted, that means the user has
    // used a temporary password. Once this modal has mounted
    // we should set the state of that status to false
    setTemporaryLoginStatus(false)
  }

  onChange = password => {
    this.setState({ password })
  }

  onSubmit = () => {
    const { getApp, getBaseURL, navigate } = this.context
    const { authToken, datasourceId } = this.props
    const { password } = this.state

    this.setState({ isSubmitting: true })

    if (password.length < 1) {
      return this.setState({
        error: 'Passwords can not be empty',
        isSubmitting: false,
      })
    }

    const app = getApp()
    const { name: appName } = app
    const id = currentUserSelector(null, { authToken })
    const baseURL = getBaseURL()
    const url = `${baseURL}/databases/${datasourceId}/sessions/reset_password`
    const body = { appName, id, password }

    request(datasourceId, url, 'post', body)
      .then(res => {
        this.setState({ isSubmitting: false })
        return navigate({ target: NAVIGATION_BACK })
      })
      .catch(err => {
        this.setState({ isSubmitting: false })
      })
  }

  render() {
    const { getApp } = this.context
    const { error, isSubmitting, password } = this.state
    const { layoutGuides, screenLoading } = this.props
    const { top } = layoutGuides
    const { branding, primaryPlatform } = getApp()

    const backgroundColor = branding.secondary
    const color = contrastWithBackground(backgroundColor)
    const margin = primaryPlatform === 'web' ? 'auto' : 0
    const mobilePadding = primaryPlatform !== 'web' ? top + 32 : 0

    if (screenLoading) {
      return (
        <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
          <Loading />
        </View>
      )
    }

    return (
      <View style={styles.wrapper}>
        <View style={[styles.container, { paddingTop: mobilePadding, margin }]}>
          <KeyboardAvoidingView>
            <Text style={[styles.headerText]}>Create New Password</Text>
            <Text style={[styles.text]}>
              You&apos;ve successfully logged in with your temporary password.
              Please create a new permanent password for your account.
            </Text>

            <View style={styles.inputWrapper}>
              <Text style={styles.label}>New Password</Text>
              <TextInput
                onChangeText={this.onChange}
                style={styles.input}
                placeholder="Enter new password..."
                value={password}
                secureTextEntry
              />
              {error ? (
                <Text style={[styles.text, styles.error]}>{error}</Text>
              ) : null}
            </View>

            <TouchableOpacity
              style={[styles.button, styles.submit, { backgroundColor }]}
              onPress={this.onSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <ActivityIndicator color="#999999" />
              ) : (
                <Text style={[styles.buttonText, { color }]}>Submit</Text>
              )}
            </TouchableOpacity>
          </KeyboardAvoidingView>
        </View>
      </View>
    )
  }
}

const mapStateToProps = (state, { datasourceId }) => {
  const authToken = getAuthToken(state, datasourceId)

  return {
    authToken,
  }
}

const ResetPasswordWrapped = connect(mapStateToProps, {
  setTemporaryLoginStatus,
})(ResetPassword)

export { ResetPasswordWrapped as ResetPassword }

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: 'white',
    padding: 16,
  },
  container: {
    maxWidth: 480,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerText: {
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 8,
  },
  text: {
    color: '#777777',
  },
  error: {
    color: 'red',
    marginTop: 4,
  },
  label: {
    fontSize: 12,
    marginBottom: 4,
  },
  button: {
    borderRadius: 4,
    alignContent: 'center',
    justifyContent: 'center',
    minHeight: 40,
    marginBottom: 8,
  },
  closeButton: {
    marginTop: 16,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  cancelButtonText: {
    color: '#777',
  },
  submit: {},
  input: {
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 40,
    fontSize: 14,
    ...Platform.select({
      web: {
        outline: 'none',
      },
    }),
  },
  inputWrapper: {
    paddingTop: 32,
    paddingBottom: 16,
  },
  bold: {
    fontWeight: '500',
    color: '#000',
  },
})
