import numeral from 'numeral'
import { formatters, dataTypes, numberFormats } from '@adalo/constants'
import { getUserLocale } from './user-locale'

import {
  relativeDate,
  relativeShort,
  absoluteDate,
  universalDate,
  absoluteDatetime,
  dayOfWeek,
  dayNumber,
  monthName,
  monthNumber,
  yearNumber,
  timeOnly,
  isoDateOnly,
  isoDateTime,
} from './dates'

import { formatCoordinate } from './coordinates'

export const formatValue = (binding, value, format = {}, helpers = {}) => {
  if (!binding || !binding.source) {
    return ''
  }

  let result = value

  if (value === undefined || value === null || !String(value)) {
    if (
      binding &&
      binding.source &&
      binding.source.dataType === dataTypes.IMAGE
    ) {
      return value
    }

    return ''
  }

  if (helpers.isUploadURL) {
    const { parentSource } = helpers

    const baseURL =
      parentSource?.dataType === dataTypes.IMAGE
        ? helpers.getImageUploadsBaseURL && helpers.getImageUploadsBaseURL()
        : helpers.getFileUploadsBaseURL && helpers.getFileUploadsBaseURL()

    if (baseURL) {
      return `${baseURL}/${value}?orient`
    }
  }

  switch (binding.source.dataType) {
    case dataTypes.DATE:
      result = formatDate(value, format)
      break
    case dataTypes.DATE_ONLY:
      result = formatDateOnly(value, format)
      break
    case dataTypes.NUMBER:
      result = formatNumber(value, format)
      break
  }

  const parentSource = binding && binding.source && binding.source.source

  if (parentSource && parentSource.dataType === dataTypes.LOCATION) {
    const [dataSubType, field] = binding.source.fieldId.split('.')

    if (dataSubType === 'coordinates') {
      result = formatCoordinate(value, field, format)
    }
  }

  let { prefix, suffix } = format

  if (binding.source.dataType === dataTypes.NUMBER && +value === 1) {
    prefix = format.prefixSingular
    suffix = format.suffixSingular
  }

  if (prefix) {
    result = `${prefix} ${result}`
  }
  if (suffix) {
    result = `${result} ${suffix}`
  }

  return result
}

export const formatDate = (dateString, format) => {
  const { type } = format

  switch (type) {
    case formatters.date.RELATIVE:
      return relativeDate(dateString)
    case formatters.date.RELATIVE_SHORT:
      return relativeShort(dateString)
    case formatters.date.ABSOLUTE_DATE:
      return absoluteDate(dateString)
    case formatters.date.UNIVERSAL_DATE:
      return universalDate(dateString)
    case formatters.date.ABSOLUTE_DATETIME:
      return absoluteDatetime(dateString)
    case formatters.date.WEEK_DAY_LONG:
      return dayOfWeek(dateString)
    case formatters.date.WEEK_DAY_SHORT:
      return dayOfWeek(dateString, true)
    case formatters.date.DAY_NUMBER:
      return dayNumber(dateString)
    case formatters.date.MONTH_NAME_LONG:
      return monthName(dateString)
    case formatters.date.MONTH_NAME_SHORT:
      return monthName(dateString, true)
    case formatters.date.MONTH_NUMBER:
      return monthNumber(dateString)
    case formatters.date.YEAR_NUMBER_LONG:
      return yearNumber(dateString)
    case formatters.date.YEAR_NUMBER_SHORT:
      return yearNumber(dateString, true)
    case formatters.date.TIME:
      return timeOnly(dateString)
    case formatters.date.ISO:
      return isoDateTime(dateString)
    default:
      return dateString
  }
}

export const formatDateOnly = (dateString, format) => {
  const { type } = format

  const justDate = dateString.split('T')[0]
  const dateTime = `${justDate}T00:00:00`

  switch (type) {
    case formatters.dateOnly.RELATIVE:
      return relativeDate(dateTime)
    case formatters.dateOnly.ABSOLUTE_DATE:
      return absoluteDate(dateTime)
    case formatters.dateOnly.UNIVERSAL_DATE:
      return universalDate(dateTime)
    case formatters.dateOnly.WEEK_DAY_LONG:
      return dayOfWeek(dateTime)
    case formatters.dateOnly.WEEK_DAY_SHORT:
      return dayOfWeek(dateTime, true)
    case formatters.dateOnly.DAY_NUMBER:
      return dayNumber(dateTime)
    case formatters.dateOnly.MONTH_NAME_LONG:
      return monthName(dateTime)
    case formatters.dateOnly.MONTH_NAME_SHORT:
      return monthName(dateTime, true)
    case formatters.dateOnly.MONTH_NUMBER:
      return monthNumber(dateTime)
    case formatters.dateOnly.YEAR_NUMBER_LONG:
      return yearNumber(dateTime)
    case formatters.dateOnly.YEAR_NUMBER_SHORT:
      return yearNumber(dateTime, true)
    case formatters.dateOnly.ISO:
    default:
      return isoDateOnly(dateString)
  }
}

export const formatNumber = (num, format) => {
  const { type } = format
  const locale = getUserLocale()

  if (Array.isArray(num)) {
    const results = num.map(itm => formatNumber(itm, format))

    if (results.length === 1) {
      return results[0]
    } else {
      return results.join(' - ')
    }
  }

  switch (type) {
    case numberFormats.COMMAS:
      return numeral(num).format('0,0[.][0][0]')
    case numberFormats.ABBREVIATED:
      return numeral(num).format(abbreviatedFormat(num)).toUpperCase()
    case numberFormats.CURRENCY:
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: format.currency || 'usd',
      }).format(num)
    case numberFormats.ABBREVIATED_CURRENCY:
      return numeral(num)
        .format(`$${abbreviatedFormat(num)}`)
        .toUpperCase()
    default:
      return Number(num)
  }
}

export const abbreviatedFormat = num => {
  const order = ((3 * Math.log(num)) / Math.log(1000)) % 3
  let format = '0[.][00]a'

  if (order >= 2) {
    format = '0a'
  } else if (order >= 1) {
    format = '0[.][0]a'
  }

  return format
}
