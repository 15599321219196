import React, { Component } from 'react'
import {
  TouchableOpacity,
  View,
  Text,
  ActivityIndicator,
  StyleSheet,
  Platform,
} from 'react-native'

import { actionContextTypes } from '../../utils/actions'
import { getShadowStyles } from '../../utils/styles'
import { normalizeFontFamily, translateFontWeight } from '../../utils/type'

class SubmitButton extends Component {
  static contextTypes = {
    ...actionContextTypes,
  }

  static defaultProps = {
    backgroundColor: '#6200ee',
    color: '#fff',
    borderColor: '#999',
    borderWidth: 0,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: '@body',
    padding: 10,
  }

  render() {
    let {
      text,
      color,
      backgroundColor,
      onSubmit,
      borderRadius,
      borderColor,
      borderWidth,
      shadow,
      fontSize,
      fontWeight,
      fontFamily,
      padding,
      submitting,
      disabled,
    } = this.props

    const { getApp } = this.context
    const { branding } = getApp()

    const wrapperStyles = {
      ...getShadowStyles(shadow),
      backgroundColor,
      borderRadius,
      borderWidth,
      borderColor,
      height: fontSize * 1.4 + 2 * padding,
      opacity: submitting ? 0.5 : 1,
    }

    const textStyles = {
      color,
      fontSize,
      fontWeight:
        Platform.OS !== 'android'
          ? translateFontWeight(fontWeight, fontFamily, branding)
          : null,
      fontFamily: normalizeFontFamily(fontFamily, branding, { fontWeight }),
    }

    text = text || 'SAVE'

    return (
      <TouchableOpacity
        style={styles.buttonWrapper}
        onPress={onSubmit}
        disabled={disabled}
      >
        <View style={[styles.button, wrapperStyles]}>
          <Text style={[styles.buttonText, textStyles]}>{text}</Text>
        </View>
        {submitting ? (
          <View style={styles.submitProgress}>
            <ActivityIndicator color="#999999" />
          </View>
        ) : null}
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  button: {
    height: 40,
    borderRadius: 4,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonWrapper: {
    marginTop: 35,
  },
  buttonText: {
    textAlign: 'center',
  },
  submitProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default SubmitButton
