/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import { isObject as _isObject } from 'lodash'

import { Text, View, Keyboard, StyleSheet, Platform } from 'react-native'

import {
  LABEL,
  INPUT,
  IMAGE_UPLOAD,
  DATE_PICKER,
  CHECKBOX,
  FILE_UPLOAD,
  SELECT,
  LOCATION_INPUT,
  actionTypes,
  sourceTypes,
  borderStyles,
  backgroundStyles,
  bindingTypes,
  dataTypes,
  inputTypes,
  authTypes,
  fields as protonFields,
  DEBOUNCE_TIME,
} from '@adalo/constants'

import { getActionSourceValue } from '../../utils/dependencies'
import { toFlat } from '../../utils/objects'
import { executeAction, hasLink, actionContextTypes } from '../../utils/actions'
import { request } from '../../utils/networking'
import { buildURL } from '../../utils/urls'

import { getId } from '../../utils/ids'
import { validateEmail } from '../../utils/validation'
import { normalizeFontFamily } from '../../utils/type'

import { setValues, getFormInputs } from '../../ducks/formInputs'

import BaseObject from '../BaseObject'
import ObjectRenderer from '../ObjectRenderer'

import SubmitButton from './SubmitButton'
import determineImagePickerHeight from './determineHeight'

class Form extends BaseObject {
  static contextTypes = {
    ...actionContextTypes,
  }

  state = {
    submitting: false,
    success: false,
    errors: [],
  }

  getMethodFromReference = reference => {
    switch (reference) {
      case 'new':
      case 'signup':
        return 'POST'

      default:
        return 'PUT'
    }
  }

  shouldSkipValidationRequest = reference => {
    if (reference === 'login') {
      return true
    }

    return false
  }

  getTableType = props => {
    const { getApp } = this.context
    const { object } = props
    const { collection } = (object && object.attributes) || {}
    const { datasourceId, tableId } = collection || {}
    const app = getApp()
    const datasource = app.datasources[datasourceId]
    const table = datasource && datasource.tables[tableId]

    return table && table.type
  }

  getBindingData = props => {
    props = props || this.props

    const { bindingData } = props
    const tableType = this.getTableType(props)

    if (!bindingData) {
      return bindingData
    }

    return tableType === 'api' ? toFlat(bindingData) : bindingData
  }

  handleValidation = async () => {
    const { errors } = this.state
    const { formValues, object } = this.props
    const { attributes, id } = object
    const { fields } = attributes
    const payload = {}

    // create a clone of the current error state
    const formErrors = [...errors]

    for (const field of fields) {
      const { fieldId } = field
      const inputId = `${id}-${fieldId}`

      // build payload for server-side validation
      payload[fieldId] = formValues[inputId]

      // if the field is not required, then it does NOT need to be validated
      // unless the field is an email
      if (!field.required && fieldId !== protonFields.EMAIL) continue

      // find the current input error
      const error = formErrors.find(err => err.id === inputId)

      // check if the current error state includes the current input
      if (error) {
        // if the current input is valid, remove from errors
        if (error && formValues[error.id]) {
          // check if field is an email field
          if (fieldId === protonFields.EMAIL) {
            // if email field is not a valid email address, do not remove error
            if (!validateEmail(formValues[error.id])) {
              error.message = undefined
              continue
            }
          }

          const index = formErrors.indexOf(error)
          if (index > -1) formErrors.splice(index, 1)
          continue
        } else {
          if (fieldId === protonFields.EMAIL) {
            error.message = field.requiredText
            continue
          }
        }

        // continue to prevent re-adding to errors
        continue
      }

      // check if the current input value is valid - e.g. not undefined, null
      if (formValues[inputId]) {
        // check if the field is an email field
        if (fieldId === protonFields.EMAIL) {
          // check if the email value is valid
          if (validateEmail(formValues[inputId])) continue
        } else {
          continue
        }
      }

      // set message to undefined if field is an email and contains some content
      // otherwise use the supplied required text
      const message =
        formValues[inputId] && fieldId === protonFields.EMAIL
          ? undefined
          : field.requiredText

      // add inputId to error array
      formErrors.push({
        id: inputId,
        message,
      })
    }

    try {
      const { collection, reference } = object.attributes || {}

      if (this.shouldSkipValidationRequest(reference)) {
        return formErrors
      }

      const method = this.getMethodFromReference(reference)

      const { datasourceId, tableId } = collection
      const bindingData = this.getBindingData()

      const url = buildURL(this.context.getBaseURL(), {
        datasourceId,
        tableId,
        validateMode: true,
        ...(reference !== 'new' && {
          id: bindingData._meta?.id,
        }),
      })

      await request(datasourceId, url, method, payload)
    } catch (e) {
      if (e.response?.data) {
        const { data } = e.response

        if (_isObject(data)) {
          for (const fieldId in data) {
            formErrors.push({
              id: `${id}-${fieldId}`,
              message: data[fieldId],
            })
          }
        }
      }
    }

    return formErrors
  }

  handleSubmit = () => {
    const { getStore } = this.context
    const { object, dispatch } = this.props
    const bindingData = this.getBindingData()

    this.setState({
      submitting: true,
      success: false,
      errors: [],
    })

    // ! race condition
    // input values are debounced and redux state updates
    // can sometimes lag behind before an action tries to fire off
    // resulting in missed data. This setTimeout prevents that from happening.
    setTimeout(async () => {
      const state = getStore().getState()

      const errors = await this.handleValidation()

      if (errors.length > 0) {
        return this.setState({ errors, submitting: false })
      }

      const { fields, hiddenFields, reference, collection, submitButton } =
        object.attributes || {}

      let actionType =
        reference === 'new'
          ? actionTypes.CREATE_OBJECT
          : actionTypes.UPDATE_OBJECT

      const options = { ...collection, fields: [] }

      if (reference === 'login') {
        actionType = actionTypes.AUTHENTICATE
        options.authType = authTypes.EMAIL
      } else if (reference === 'signup') {
        actionType = actionTypes.SIGNUP
      }

      if ([actionTypes.AUTHENTICATE, actionTypes.SIGNUP].includes(actionType)) {
        options.errorMessage = submitButton.errorMessage
      }

      if (actionType === actionTypes.UPDATE_OBJECT) {
        options.objectId = bindingData && bindingData.id
      }

      const fakeAction = {
        id: getId(),
        actionType,
        options,
      }

      let deps = {}
      const dependencies = {}

      const opts = { ...this.context }

      hiddenFields.forEach(field => {
        const value = getActionSourceValue(state, field.value, opts)

        dependencies[field.fieldId] = value
      })

      fields.forEach(field => {
        let inputId = `${object.id}-${field.fieldId}`

        if (field.type.type === 'belongsTo' && field.binding) {
          inputId = field.binding.id
        }

        const source = {
          objectId: inputId,
          type: sourceTypes.INPUT,
          dataType: field.type,
        }

        dependencies[field.fieldId] = getActionSourceValue(state, source, opts)
      })

      if (actionType === actionTypes.AUTHENTICATE) {
        deps = { [fakeAction.id]: dependencies }
      } else {
        deps = { [fakeAction.id]: { fields: dependencies } }
      }

      let actionChain = [fakeAction]

      const actionRef = submitButton.action

      if (actionRef) {
        const action = object.actions[actionRef.actionId]

        actionChain = actionChain.concat(action.actions)
      }

      const wrappedAction = {
        actions: actionChain,
      }

      try {
        await executeAction(wrappedAction, { ...this.context, dispatch }, deps)

        this.setState({ submitting: false, success: true })
      } catch (err) {
        this.setState({ submitting: false })
      }

      this.context.setDirty()

      if (hasLink(wrappedAction)) {
        Keyboard.dismiss()
      }
    }, DEBOUNCE_TIME)
  }

  setFormValues = bindingData => {
    const { object, dispatch } = this.props
    const { fields } = object.attributes || {}

    if (bindingData && Object.keys(bindingData).length > 1) {
      const values = {}

      fields.forEach(field => {
        if (field && field.type === 'password') {
          return
        }

        let inputId = `${object.id}-${field.fieldId}`
        const value = bindingData[field.fieldId]

        if (
          field &&
          field.type &&
          field.type.type === 'belongsTo' &&
          field.binding
        ) {
          inputId = field.binding.id
        }

        values[inputId] = value
      })
      dispatch(setValues(values))
    }
  }

  setUploading = uploading => {
    if (typeof uploading === 'undefined') {
      this.setState({ uploading: false })
    } else {
      this.setState({ uploading })
    }
  }

  componentDidMount() {
    const bindingData = this.getBindingData()

    if (
      bindingData &&
      typeof bindingData === 'object' &&
      Object.keys(bindingData).length > 1
    ) {
      this.setFormValues(bindingData)
    }
  }

  componentDidUpdate(oldProps) {
    const bindingData = this.getBindingData()
    const oldBindingData = this.getBindingData(oldProps)

    if (deepEqual(oldBindingData, bindingData)) {
      return
    }

    if (
      bindingData &&
      typeof bindingData === 'object' &&
      Object.keys(bindingData).length > 1 &&
      (!oldProps.bindingData ||
        typeof oldProps.bindingData !== 'object' ||
        Object.keys(oldProps.bindingData).length === 1)
    ) {
      this.setFormValues(bindingData)
    }
  }

  render() {
    const {
      object,
      component,
      active,
      topScreen,
      app,
      scrollTo,
      isResponsiveComponent,
    } = this.props
    const { layout } = object
    const { fields, submitButton } = object.attributes || {}
    const { submitting, success, uploading } = this.state

    const zIndexStyles = {}
    for (const field of fields) {
      if (field.type === dataTypes.DATE || field.type === dataTypes.DATE_ONLY) {
        zIndexStyles.zIndex = 100 - layout.zIndex
      }
    }

    return (
      <View style={[layout, zIndexStyles]}>
        {fields &&
          fields.map((field, index) => {
            const { errors } = this.state
            const { fieldId } = field
            const inputId = `${object.id}-${fieldId}`

            // * check if field has an error
            const hasError = errors.find(err => err.id === inputId)

            return (
              <FormField
                key={fieldId}
                index={index}
                object={object}
                field={field}
                formSubmitSuccess={success}
                component={component}
                active={active}
                topScreen={topScreen}
                hasError={hasError}
                onSubmit={this.handleSubmit}
                branding={app && app.branding}
                setUploading={
                  field.type === dataTypes.IMAGE ||
                  field.type === dataTypes.FILE
                    ? this.setUploading
                    : null
                }
                appId={app.id}
                scrollTo={scrollTo}
                isResponsiveComponent={isResponsiveComponent}
              />
            )
          })}
        <SubmitButton
          {...submitButton}
          onSubmit={this.handleSubmit}
          submitting={submitting || uploading}
          disabled={uploading}
        />
      </View>
    )
  }
}

const mapStateToProps = (state, { object }) => {
  const { id, attributes } = object
  const { fields } = attributes

  const formValues = {}
  const formInputs = getFormInputs(state)

  for (const key in formInputs) {
    if (key) {
      fields.forEach(field => {
        const inputId = `${id}-${field.fieldId}`
        let inputValueId = inputId

        if (field.type && field.type.type === 'belongsTo' && field.binding) {
          inputValueId = field.binding.id
        }

        if (key === inputValueId) {
          formValues[inputId] = formInputs[key]
        }
      })
    }
  }

  return {
    formValues,
  }
}

export default connect(mapStateToProps)(Form)

export class FormField extends Component {
  static contextTypes = {
    ...actionContextTypes,
  }

  getLabelProps() {
    const { field, object, branding, isResponsiveComponent } = this.props
    const { fieldStyles } = object.attributes || {}
    const labelStyles = (fieldStyles && fieldStyles.labels) || {}
    let { fontFamily } = labelStyles

    // supply the normalized body font family just incase the field styles
    // do not have a fontFamily property
    fontFamily = fontFamily || normalizeFontFamily('@body', branding)

    return {
      id: `${object.id}-${field.fieldId}-label`,
      type: LABEL,
      layout: { flex: 1 },
      isResponsiveComponent,
      attributes: {
        fontSize: 14,
        fontFamily,
        color: '#000',
        text: field.label,
        ...labelStyles,
      },
    }
  }

  getInputStyles = () => {
    const { object, branding } = this.props
    const { fieldStyles } = object.attributes || {}
    let { fontFamily } = fieldStyles

    // supply the normalized body font family just incase the field styles
    // do not have a fontFamily property
    fontFamily = fontFamily || normalizeFontFamily('@body', branding)

    return {
      ...(fieldStyles && fieldStyles.inputs),
      fontFamily,
    }
  }

  getInputProps() {
    const { object, field, hasError, isResponsiveComponent } = this.props
    const inputStyles = this.getInputStyles()
    const fontSize = inputStyles.fontSize || 16
    const padding = inputStyles.padding === undefined ? 10 : inputStyles.padding
    const multiline = field.multiline || inputStyles.multiline
    let type = INPUT
    let height = fontSize * 1.3 + 2 * padding
    const { width } = object.attributes
    let inputType

    let id = `${object.id}-${field.fieldId}`
    let dataBinding

    if (field.type === dataTypes.IMAGE) {
      type = IMAGE_UPLOAD
      inputStyles.color = inputStyles.accentColor
      inputStyles.imageUploadType = 'fullWidth'
      height = determineImagePickerHeight(object)
    } else if (field.type === dataTypes.TEXT) {
      if (field.fieldId === protonFields.EMAIL) inputType = protonFields.EMAIL
    } else if (field.type === dataTypes.DATE) {
      type = DATE_PICKER
    } else if (field.type === dataTypes.DATE_ONLY) {
      const { datePickerStyle } = field

      type = DATE_PICKER
      inputStyles.datePickerStyle = datePickerStyle || dataTypes.DATE_ONLY
    } else if (field.type === dataTypes.FILE) {
      type = FILE_UPLOAD
      inputStyles.color = inputStyles.accentColor || '#6200f3'
      height = 80
    } else if (field.type === dataTypes.NUMBER) {
      inputType = inputTypes.NUMBER
    } else if (field.type === dataTypes.PASSWORD) {
      inputType = inputTypes.PASSWORD
    } else if (field.type === dataTypes.LOCATION) {
      type = LOCATION_INPUT
    } else if (field.type.type === 'belongsTo') {
      type = SELECT

      if (field.binding) {
        dataBinding = {
          ...field.binding,
          bindingType: bindingTypes.LIST,
        }

        id = field.binding.id
      }
    }

    if (multiline) height = 80

    const errorColor = inputStyles.errorColor || 'red'

    const layoutMarginTop =
      isResponsiveComponent && Platform.OS !== 'web' ? 20 : 10

    return {
      id,
      type,
      dataBinding,
      layout: { marginTop: layoutMarginTop },
      attributes: {
        borderWidth: 1,
        ...inputStyles,
        fontSize,
        multiline,
        inputType,
        borderColor: hasError ? errorColor : inputStyles.borderColor || '#ddd',
        borderStyle: borderStyles.SOLID,
        borderRadius: inputStyles.borderRadius || 4,
        placeholder: field.placeholder,
        padding: inputStyles.padding || 10,
        height,
        width,
        backgroundStyle: backgroundStyles.COLOR,
      },
      icon: field.icon,
      isFormField: true,
      isResponsiveComponent,
    }
  }

  getCheckboxProps() {
    const { object, field, isResponsiveComponent } = this.props
    let { fieldStyles, submitButton } = object.attributes

    fieldStyles = fieldStyles || { inputs: {} }
    submitButton = submitButton || {}

    const borderColor = fieldStyles.inputs.borderColor || '#ccc'
    const backgroundColor = submitButton.backgroundColor || '#6200ee'

    return {
      id: `${object.id}-${field.fieldId}`,
      type: CHECKBOX,
      attributes: {
        activeColor: backgroundColor,
        inactiveColor: borderColor,
      },
      layout: {
        marginRight: 10,
      },
      isResponsiveComponent,
    }
  }

  renderCheckbox() {
    const { component, active, topScreen, hasError } = this.props

    return (
      <View style={styles.formRow}>
        <View style={styles.checkboxRow}>
          <ObjectRenderer
            object={this.getCheckboxProps()}
            component={component}
            active={active}
            topScreen={topScreen}
          />
          <ObjectRenderer
            object={this.getLabelProps()}
            component={component}
            active={active}
            topScreen={topScreen}
          />
        </View>
        {hasError ? this.renderErrorMessage() : null}
      </View>
    )
  }

  renderErrorMessage = () => {
    const { hasError } = this.props
    const { message } = hasError
    const inputStyles = this.getInputStyles()

    const errorStyles = {
      color: inputStyles.errorColor,
      fontFamily: inputStyles.fontFamily,
    }

    if (!hasError) return null
    if (!message) return null
    return <Text style={[styles.errorMessage, errorStyles]}>{message}</Text>
  }

  render() {
    const {
      component,
      active,
      topScreen,
      scrollTo,
      field,
      hasError,
      onSubmit,
      setUploading,
      index,
      formSubmitSuccess,
    } = this.props

    if (field.type === dataTypes.BOOLEAN) {
      return this.renderCheckbox()
    }

    const formRowStyles = {}
    if (field.type === dataTypes.DATE || field.type === dataTypes.DATE_ONLY) {
      formRowStyles.zIndex = 100 - index
    }

    return (
      <View style={[styles.formRow, formRowStyles]}>
        <ObjectRenderer
          object={this.getLabelProps()}
          component={component}
          active={active}
          topScreen={topScreen}
        />
        <ObjectRenderer
          object={this.getInputProps()}
          component={component}
          active={active}
          topScreen={topScreen}
          scrollTo={scrollTo}
          onEnter={onSubmit}
          setUploading={setUploading}
          formSubmitSuccess={formSubmitSuccess}
        />
        {hasError ? this.renderErrorMessage() : null}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  formRow: {
    marginBottom: 15,
  },
  checkboxRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorMessage: {
    marginTop: 6,
  },
})
