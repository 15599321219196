import { evaluateBinding } from './dependencies'
import { formatLibraryValue } from './library-values'
import { normalizeStyles } from './styles'
import { getValue } from '../ducks/formInputs'
import { deepSet } from './objects'

export function solveBindings(app, object, context) {
  const {
    getBinding,
    getAssetURL,
    getImageUploadsBaseURL,
    getFileUploadsBaseURL,
    state,
  } = context

  const formatOpts = {
    getAssetURL,
    getImageUploadsBaseURL,
    getFileUploadsBaseURL,
    branding: app && app.branding,
  }

  const { libraryName, componentName } = object.attributes

  const manifest =
    app.libraryComponentManifests?.[libraryName]?.[componentName] ||
    object.libraryComponentManifest

  if (!manifest) {
    return {}
  }

  const values = object.attributes
  const allGlobals = (app && app.libraryGlobals) || {}

  const libraryGlobals =
    (allGlobals[libraryName] && allGlobals[libraryName][componentName]) || {}

  const result = {}

  const childComponents = manifest.childComponents || []
  const normalChildComponents = []
  const references = {}
  const normalProps = []
  const referenceProps = {}

  childComponents.forEach(child => {
    if (hasRole(child, 'listItem') && child.reference) {
      references[child.reference] = references[child.reference] || []
      references[child.reference].push(child)
    } else {
      normalChildComponents.push(child)
    }
  })

  manifest.props.forEach(prop => {
    if (hasRole(prop, 'listItem') && prop.reference) {
      referenceProps[prop.reference] = referenceProps[prop.reference] || []
      referenceProps[prop.reference].push(prop)
    } else {
      normalProps.push(prop)
    }
  })

  const bindingValues = {}

  // TODO: should something happne here for images
  normalProps.forEach(prop => {
    const value = prop.global ? libraryGlobals[prop.name] : values[prop.name]

    let formattedValue

    if (value) {
      let val = evaluateBinding(state, value, {
        ...context,
        listItemId: values.listItemId,
      })

      // if (value.filename1x) {
      //   formattedValue = {
      //     uri: getAssetURL(value.filename1x.url),
      //     filename: value.filename1x.filename,
      //   }
      // }

      if (!val && val !== 0 && val !== false && val !== '') {
        if (
          value.type === 'imageBinding' &&
          value.options &&
          value.options.placeholderImageEnabled &&
          value.options.placeholderImage
        ) {
          formattedValue = getAssetURL(value.options.placeholderImage)
        } else {
          return
        }
      }

      if (Array.isArray(val)) {
        // Map list items
        val = val.map(itm => {
          const childProps = {}

          const getListBinding = listId => {
            if (listId === value.id) {
              return itm
            }

            if (getBinding) {
              return getBinding(listId)
            }
          }

          const refProps = referenceProps[prop.name] || []

          refProps.forEach(prop => {
            let value = values[prop.name]
            let binding

            if (value) {
              if (value.filename1x) {
                value = value.filename1x
              } else {
                binding = value

                value = evaluateBinding(state, value, {
                  ...context,
                  getBinding: getListBinding,
                  listItemId: itm.id,
                })
              }
            }

            childProps[prop.name] = formatLibraryValue(value, prop, binding, {
              ...formatOpts,
              values,
              object,
            })
          })

          const refs = references[prop.name] || []

          refs.forEach(child => {
            let props = {}

            child.props.forEach(prop => {
              const childValues = values[child.name]
              let value = childValues && childValues[prop.name]
              const binding = value

              if (value) {
                value = evaluateBinding(state, value, {
                  ...context,
                  getBinding: getListBinding,
                  listItemId: itm.id,
                })
              }

              props[prop.name] = formatLibraryValue(value, prop, binding, {
                ...formatOpts,
                values: childValues,
                object,
              })

              if (prop.styles) {
                props = deepSet(
                  props,
                  ['styles', prop.name],
                  normalizeStyles(prop, values[child.name], app.branding)
                )
              }
            })

            childProps[child.name] = props
          })

          return {
            ...childProps,
            id: (itm._meta && itm._meta.id) || itm.id,
            _meta: { ...itm._meta, record: itm },
          }
        })

        bindingValues[value.id] = val
      }

      formattedValue = formatLibraryValue(val, prop, value, {
        ...formatOpts,
        values,
        object,
      })
    } else {
      formattedValue = formatLibraryValue(value, prop, null, {
        ...formatOpts,
        values,
        object,
      })
    }

    if (
      !formattedValue &&
      value &&
      value.type === 'imageBinding' &&
      value.options &&
      value.options.placeholderImageEnabled &&
      value.options.placeholderImage
    ) {
      formattedValue = getAssetURL(value.options.placeholderImage)
    }

    if (hasRole(prop, 'formValue')) {
      result[prop.name] = {
        value: getValue(state, `${object.id}.${prop.name}`),
        initial: formattedValue,
      }
    } else {
      result[prop.name] = formattedValue
    }
  })

  normalChildComponents.forEach(child => {
    const childValues = values[child.name] || {}
    const childGlobals = libraryGlobals[child.name] || {}

    result[child.name] = {}

    child.props.forEach(prop => {
      let value = prop.global ? childGlobals[prop.name] : childValues[prop.name]
      let binding = null

      if (value) {
        binding = value

        value = evaluateBinding(state, value, { ...context, getBinding })
      }

      if (hasRole(prop, 'formValue')) {
        result[child.name][prop.name] = {
          value: getValue(state, `${object.id}.${child.name}.${prop.name}`),
          initial: formatLibraryValue(value, prop, binding, {
            ...formatOpts,
            values: childValues,
            object,
          }),
        }
      } else {
        result[child.name][prop.name] = formatLibraryValue(
          value,
          prop,
          binding,
          {
            ...formatOpts,
            values: childValues,
            object,
          }
        )
      }
    })
  })

  return {
    bindingValues,
    componentProps: result,
  }
}

export const hasRole = (prop, role) => {
  if (Array.isArray(prop.role)) {
    return prop.role.includes(role)
  } else {
    return prop.role === role
  }
}
