import axios from 'axios'
import { Platform, Dimensions } from 'react-native'
import { actionTypes, ACTION_NAMES } from '@adalo/constants'
import { getPayload } from './jwt'
import { isExternalCollection } from './externalCollections'
import { unsafeGetToken } from '../ducks/auth'

const PRODUCTION_URL = 'https://analytics.adalo.com'

const getAnalyticsURL = () => {
  const analyticsURL =
    process.env.NODE_ENV === 'production'
      ? PRODUCTION_URL
      : process.env.REACT_APP_ANALYTICS_URL

  return analyticsURL
}

const isPWA = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone ||
  document.referrer.includes('android-app://')

window.isPWA = isPWA

export const logEvent = (event, app, component, metadata = {}, opts = {}) => {
  window.setTimeout(async () => {
    const url = getAnalyticsURL()

    const appId = app.id
    const token = getPayload(unsafeGetToken(null, app))

    if (!url || opts.disableAnalytics) {
      return
    }

    const { userId } = token || {}
    const additional = { Screen: component && component.name }

    if (event === actionTypes.NAVIGATE) {
      const { target } = metadata
      const screen = app.components[target]

      if (screen) {
        additional['Target Screen'] = screen.name
      } else {
        event = 'Link Back'
      }
    }

    if (opts.appVersion) {
      additional.appVersion = opts.appVersion
    }

    const eventName = ACTION_NAMES[event] || event

    let platform = Platform.OS

    if (platform === 'web') {
      if (isPWA()) {
        platform = 'web-pwa'
      }
    }

    const windowWidth = Dimensions.get('window').width
    const windowHeight = Dimensions.get('window').height
    const windowSize = { width: windowWidth, height: windowHeight }
    let device

    if (windowWidth <= 767) {
      device = 'mobile'
    } else if (windowWidth > 767 && windowWidth <= 1024) {
      device = 'tablet'
    } else {
      device = 'desktop'
    }

    await axios.post(`${url}/events`, {
      appId,
      userId,
      event: eventName,
      metadata: {
        ...additional,
        platform,
        windowSize,
        device,
        afterAppActions: true,
      },
    })
  }, 0)
}

export const identifyUser = async (appId, user) => {
  const url = getAnalyticsURL()

  if (!url) {
    return
  }

  const { id, name, email } = user

  await axios.post(`${url}/users`, {
    appId,
    name,
    email,
    userId: id,
  })
}

const uncountedActions = [actionTypes.NOTIFICATION_PERMISSION]

export const logAppAction = async (action, options, helpers) => {
  try {
    const { appId, orgId } = action
    let { actionType } = action
    const { getBaseURL, screen, disableAnalytics } = helpers

    if (disableAnalytics || uncountedActions.includes(actionType)) {
      return
    }

    if (
      (actionType === actionTypes.CREATE_OBJECT ||
        actionType === actionTypes.UPDATE_OBJECT ||
        actionType === actionTypes.DELETE_OBJECT) &&
      options.bindingId
    ) {
      const {
        bindingId: { datasourceId, tableId },
      } = options
      const { getDatasources } = helpers
      const datasource = getDatasources()[datasourceId]

      if (isExternalCollection({ datasource, tableId })) {
        switch (actionType) {
          case actionTypes.CREATE_OBJECT:
            actionType = actionTypes.EXTERNAL_COLLECTION_CREATE
            break
          case actionTypes.UPDATE_OBJECT:
            actionType = actionTypes.EXTERNAL_COLLECTION_UPDATE
            break
          case actionTypes.DELETE_OBJECT:
            actionType = actionTypes.EXTERNAL_COLLECTION_DELETE
            break
        }
      }
    }

    const baseUrl = getBaseURL()
    const url = `${baseUrl}/app-actions`

    await axios.post(url, {
      actionType,
      sourceType: screen ? 'screen' : 'component',
      date: new Date(),
      appId,
      orgId,
    })
  } catch (err) {
    console.error('Error sending app action', err)
  }
}
