import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import chroma from 'chroma-js'

import {
  Animated,
  Easing,
  Dimensions,
  StyleSheet,
  View,
  Platform,
  Linking,
} from 'react-native'

import { NAVIGATION_BACK, transitions } from '@adalo/constants'

import { BackHandler } from 'utils/history'
import {
  TARGET_FORGOT_PASSWORD,
  TARGET_RESET_PASSWORD,
} from 'utils/forgotPassword'

import { register } from 'utils/notification-wrapper'
import { createStack, push, peek, pop } from 'utils/stacks'
import { registerDevice, parseURL } from 'utils/notifications'
import { TRANSITION_CONFIG } from 'utils/transitions'
import { unsafeGetToken } from 'ducks/auth'
import { showNotification } from 'ducks/notifications'
import { resetRandomNumbers } from 'ducks/random'

import { normalizeColor, defaultBranding } from 'utils/colors'
import Screen from './Screen'
import ErrorScreen from './Error'
import { ForgotPassword, ResetPassword } from './ForgotPassword'

export const SLIDE_TRANSITIONS = [
  transitions.SLIDE_LEFT,
  transitions.SLIDE_RIGHT,
  transitions.SLIDE_UP,
  transitions.SLIDE_DOWN,
]

export const FLOAT_TRANSITIONS = [
  transitions.FLOAT_LEFT,
  transitions.FLOAT_RIGHT,
  transitions.FLOAT_UP,
  transitions.FLOAT_DOWN,
]

const OVERLAPPING_TRANSITIONS = [
  ...FLOAT_TRANSITIONS,
  transitions.PUSH,
  transitions.MODAL,
]

class Navigator extends Component {
  static childContextTypes = {
    navigate: PropTypes.func,
    getDeviceId: PropTypes.func,
    instantNavigation: PropTypes.func,
  }

  static contextTypes = {
    getTransitionStyle: PropTypes.func,
    getNotificationsURL: PropTypes.func,
    getFlags: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.setupRoutes(props)
  }

  getChildContext() {
    return {
      navigate: this.navigate,
      getDeviceId: this.getDeviceId,
      instantNavigation: this.instantNavigation,
    }
  }

  getDeviceId = () => {
    const { deviceId } = this.state
    return deviceId
  }

  handleRegister = async (deviceId, isPreviewer = false) => {
    const { app, authenticated } = this.props
    const { getNotificationsURL } = this.context

    if (deviceId && deviceId.token) {
      deviceId = deviceId.token
    }

    this.setState({ deviceId })

    if (authenticated) {
      await registerDevice(
        app,
        deviceId,
        getNotificationsURL(),
        unsafeGetToken(null, app),
        isPreviewer
      )
    }
  }

  handleNotification = (route, notif = null) => {
    if (notif) {
      return this.handleForegroundNotification(route, notif)
    } else {
      return this.handleNotificationSub(route)
    }
  }

  handleNotificationSub = route => {
    // handle
    const { initialComponentId } = this.props

    let routeStack = createStack({
      target: initialComponentId,
      params: {},
    })

    if (route.target !== routeStack[0].target) {
      routeStack = push(routeStack, this.getRoute(route), false)
    }

    this.setState({
      routeStack,
    })
  }

  handleForegroundNotification = (route, notif) => {
    const { showNotification } = this.props

    showNotification(notif, () => this.handleNotificationSub(route))
  }

  getRoute(route) {
    return {
      params: {},
      transition: transitions.PUSH,
      ...route,
    }
  }

  setupRoutes = (initialProps = null) => {
    const props = initialProps || this.props
    const { initialRoute, deviceId } = props

    let routeStack = createStack({
      target: props.initialComponentId,
      params: {},
      transition: null,
    })

    if (initialRoute) {
      routeStack = push(routeStack, this.getRoute(initialRoute))
    }

    const newState = {
      routeStack,
      deviceId,
      baseOffset: -1,
      navigationInProgress: false,
      currentViewOffset: new Animated.Value(0),
      loadingScreen: false,
    }

    this.notify(peek(routeStack))

    if (initialProps !== null) {
      this.state = newState
    } else {
      this.setState(newState)
    }
  }

  isAndroid = () => {
    const { getTransitionStyle } = this.context

    const transitionStyle = getTransitionStyle()

    return (transitionStyle || Platform.OS) === 'android'
  }

  getTiming = transitionType => {
    const animationTiming = this.isAndroid() ? 200 : 500

    if (transitionType === transitions.NONE) {
      return 0
    }

    return animationTiming
  }

  goBack = (...args) => {
    const { routeStack } = this.state

    if (routeStack.length > 1) {
      this.navigateBack()
      return true
    }

    return false
  }

  goBackWrapped = () => {
    if (window.history.length > 1) {
      window.history.go(-1)
    } else {
      this.navigateBack()
    }
  }

  navigateBack = () => {
    const { currentViewOffset, routeStack, baseOffset, screenLoading } =
      this.state
    const { resetRandomNumbers } = this.props

    const currentRoute = peek(routeStack)
    if (!currentRoute) {
      return
    }

    if (routeStack.length <= 1) {
      return
    }

    const transition = screenLoading
      ? transitions.NONE
      : currentRoute.transition

    resetRandomNumbers()

    Animated.timing(currentViewOffset, {
      toValue: baseOffset,
      easing: Easing.bezier(0.3, 1.06, 0.65, 1),
      duration: this.getTiming(transition),
      delay: 50,
      useNativeDriver: true,
    }).start(this.postPop)

    this.notify(peek(routeStack, 1))

    this.setState({ navigationInProgress: true })
  }

  handlePasswordReset = () => {
    const { userUsedTemporaryLogin } = this.props
    if (userUsedTemporaryLogin) {
      this.navigate({
        target: TARGET_RESET_PASSWORD,
        transition: transitions.MODAL,
      })
    }
  }

  navigate = action => {
    let {
      currentViewOffset,
      routeStack,
      baseOffset,
      navigationInProgress,
      instantNavigationInProgress,
      screenLoading,
    } = this.state

    const currentRoute = peek(routeStack)
    if (!currentRoute) {
      return
    }
    if (navigationInProgress) {
      return
    }

    if (instantNavigationInProgress) {
      setTimeout(() => {
        this.navigate(action)
      }, 400)
      return
    }

    if (screenLoading) {
      baseOffset -= 1
      const isAndroid = this.isAndroid()
      if (
        !isAndroid &&
        (action.target === NAVIGATION_BACK || action.type === NAVIGATION_BACK)
      ) {
        setTimeout(() => this.resetOffset(baseOffset), 200)
      } else {
        this.resetOffset(baseOffset)
      }
    }

    const transition = screenLoading ? transitions.NONE : action.transition

    if (action.target === NAVIGATION_BACK || action.type === NAVIGATION_BACK) {
      if (Platform.OS === 'web') {
        this.goBackWrapped()
      } else {
        this.navigateBack()
      }
    } else {
      const timing = Animated.timing(currentViewOffset, {
        toValue: baseOffset + 2,
        easing: Easing.bezier(0.3, 1.06, 0.65, 1),
        duration: this.getTiming(transition),
        delay: 150,
        useNativeDriver: true,
      })

      window.setTimeout(() => {
        timing.start(() => {
          this.setState(
            {
              navigationInProgress: false,
              screenLoading: false,
            },
            () => this.handlePasswordReset()
          )
        })
      }, 0)

      const newParams = action.params
      const oldParams = currentRoute.params

      this.notify(action)

      const params = { ...oldParams, ...newParams }

      this.setState(state => ({
        baseOffset: baseOffset + 1,
        navigationInProgress: true,
        routeStack: push(state.routeStack, { ...action, params }),
      }))
    }
  }

  // TODO: remove loadingBackgroundColor logic when skeleton states are shipped
  instantNavigation = (options = {}) => {
    const { app } = this.props
    const { currentViewOffset, baseOffset, navigationInProgress, routeStack } =
      this.state
    const { getFlags } = this.context

    const { target, type } = options || {}
    let { transition } = options || {}
    let loadingBackgroundColor =
      app.components[target]?.backgroundColor || '#ffffff'
    let delay = 150
    let instantNavBack = false
    let nextScreen = target

    const { hasInstantNavigation } = getFlags()

    if (navigationInProgress || !hasInstantNavigation) {
      return
    }

    if (target === NAVIGATION_BACK || type === NAVIGATION_BACK) {
      const currentRoute = peek(routeStack)
      const prevRoute = peek(routeStack, 1)

      transition = currentRoute?.transition
      nextScreen = prevRoute.target

      loadingBackgroundColor =
        app.components[prevRoute.target]?.backgroundColor || '#ffffff'

      delay = 50
      instantNavBack = true
    }
    const branding = (app && app.branding) || defaultBranding
    loadingBackgroundColor = normalizeColor(
      loadingBackgroundColor || '@background',
      branding
    )

    const opacity = chroma(loadingBackgroundColor).alpha()

    if (opacity < 1) {
      return
    }

    transition = transition || transitions.NONE

    const timing = Animated.timing(currentViewOffset, {
      toValue: baseOffset + 2,
      easing: Easing.bezier(0.3, 1.06, 0.65, 1),
      duration: this.getTiming(transition),
      delay,
      useNativeDriver: true,
    })

    window.setTimeout(() => {
      timing.start(() => {
        this.setState({
          instantNavigationInProgress: false,
        })
      })
    }, 0)

    this.setState(() => ({
      baseOffset: baseOffset + 1,
      instantNavigationInProgress: true,
      instantNavBack,
      screenLoading: true,
      loadingTransition: transition,
      nextScreen,
    }))
  }

  resetOffset = baseOffset => {
    this.setState({ baseOffset })
  }

  // Cleanup that happens after back animation finishes
  postPop = () => {
    const { baseOffset, routeStack: oldRouteStack } = this.state

    const [, routeStack] = pop(oldRouteStack)

    this.setState({
      routeStack,
      navigationInProgress: false,
      baseOffset: baseOffset - 1,
      instantNavBack: false,
      screenLoading: false,
    })
  }

  setURL = url => {
    const { initialComponentId } = this.props

    // Prevent url.url from causing error
    if (!url) {
      return
    }

    let { screen, prevScreen, params } = parseURL(url.url)

    // This is not a formatted launch URL, so just go to home
    if (!screen) {
      return
    }

    // Catch case with no previous screen
    if (!prevScreen) {
      prevScreen = initialComponentId
    }

    let routeStack = createStack({
      target: prevScreen,
      params: {},
      transition: null,
    })

    routeStack = push(routeStack, {
      target: screen,
      params,
      transition: transitions.PUSH,
    })

    this.setState({
      routeStack,
      baseOffset: -1,
      navigationInProgress: false,
      currentViewOffset: new Animated.Value(0),
    })
  }

  notify = route => {
    const { onNavigate } = this.props

    if (!route || !onNavigate || !route.target) {
      return
    }

    onNavigate({ componentId: route.target })
  }

  componentDidMount() {
    const { skipNotifications, deviceId, resetRandomNumbers } = this.props
    const { routeStack } = this.state

    const currentTarget = peek(routeStack)?.target

    resetRandomNumbers(currentTarget)

    BackHandler.addEventListener('hardwareBackPress', this.goBack)

    Linking.getInitialURL().then(this.setURL)
    Linking.addEventListener('url', this.setURL)

    // Setup notifications if not defined
    if (Platform.OS !== 'web' && !skipNotifications) {
      register({
        onRegister: this.handleRegister,
        onNotification: this.handleNotification,
      })
    } else if (deviceId) {
      this.handleRegister(deviceId, true)
    }
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.goBack)

    Linking.removeEventListener('url', this.setURL)
  }

  componentDidUpdate(_, prevState) {
    const { resetRandomNumbers } = this.props

    const { routeStack, navigationInProgress } = this.state

    const currentTarget = peek(routeStack)?.target

    if (navigationInProgress) {
      resetRandomNumbers(currentTarget)
    }
  }

  render() {
    const { app, layoutGuides } = this.props
    const {
      routeStack,
      currentViewOffset,
      navigationInProgress,
      instantNavigationInProgress,
      baseOffset,
      screenLoading,
      loadingTransition,
      nextScreen,
      instantNavBack,
    } = this.state

    const isAndroid = this.isAndroid()

    const { width, height } = Dimensions.get('window')

    const currentRoute = peek(routeStack)
    const previousRoute =
      screenLoading && !navigationInProgress
        ? peek(routeStack)
        : peek(routeStack, 1)

    const currentRouteIndex =
      screenLoading && !navigationInProgress
        ? routeStack.length
        : routeStack.length - 1
    const previousRouteIndex =
      screenLoading && !navigationInProgress
        ? routeStack.length - 1
        : routeStack.length - 2

    if (!currentRoute || !currentRoute.target) {
      window.setTimeout(() => {
        this.setupRoutes()
      }, 0)

      return null
    }

    let transition = currentRoute && currentRoute.transition

    if (screenLoading) {
      if (navigationInProgress) {
        transition = transitions.NONE
      } else {
        transition = loadingTransition
      }
    }

    if (!transition) {
      transition = transitions.PUSH
    }

    const inputRange = [baseOffset, baseOffset + 1]

    // Get basic styles

    let {
      transformType,
      transformDirection,
      underViewMultiple,
      dimUnderView,
      gap,
    } = TRANSITION_CONFIG[transition]

    const reverseInstantNav =
      instantNavBack && OVERLAPPING_TRANSITIONS.includes(transition)

    if (instantNavBack && SLIDE_TRANSITIONS.includes(transition)) {
      transformDirection *= -1
    }

    gap *= transformDirection

    let transformDistance = transformType === 'translateX' ? width : height
    transformDistance *= transformDirection

    // Cover cases for android push / modal

    let fadeStyles = {}
    let prevFadeStyles = {}
    if (
      isAndroid &&
      [transitions.MODAL, transitions.PUSH].indexOf(transition) !== -1
    ) {
      if (instantNavBack) {
        prevFadeStyles = {
          opacity: currentViewOffset.interpolate({
            inputRange,
            outputRange: [1, 0],
          }),
        }
      } else {
        fadeStyles = {
          opacity: currentViewOffset.interpolate({
            inputRange,
            outputRange: [0, 1],
          }),
        }
      }

      transformDistance = 100
      transformType = 'translateY'
    }

    const currentComponent = app.components[currentRoute.target]
    const previousComponent =
      previousRoute && app.components[previousRoute.target]

    let currentScreen

    const datasourceIds = Object.keys(app.datasources)
    const datasourceId = datasourceIds[0]

    if (
      [TARGET_FORGOT_PASSWORD, TARGET_RESET_PASSWORD].includes(
        currentRoute.target
      )
    ) {
      if (currentRoute.target === TARGET_FORGOT_PASSWORD) {
        currentScreen = (
          <ForgotPassword
            layoutGuides={layoutGuides}
            datasourceId={datasourceId}
            screenLoading={screenLoading}
          />
        )
      } else {
        currentScreen = (
          <ResetPassword
            layoutGuides={layoutGuides}
            datasourceId={datasourceId}
            screenLoading={screenLoading}
          />
        )
      }
    } else {
      currentScreen = currentComponent ? (
        <Screen
          topScreen
          active={!navigationInProgress && !screenLoading}
          component={currentComponent}
          params={currentRoute.params}
          layoutGuides={layoutGuides}
          nextScreen={screenLoading && nextScreen && app.components[nextScreen]}
        />
      ) : (
        <ErrorScreen message="Screen Missing" />
      )
    }

    const previousScreen = previousComponent ? (
      <Screen
        active={false}
        topScreen={false}
        component={previousComponent}
        params={previousRoute.params}
        layoutGuides={layoutGuides}
        nextScreen={
          screenLoading &&
          !instantNavigationInProgress &&
          !navigationInProgress &&
          nextScreen &&
          app.components[nextScreen]
        }
      />
    ) : (
      <ErrorScreen message="Screen Missing" />
    )

    const children = []

    if (previousComponent) {
      const prevScreenEnd = reverseInstantNav
        ? transformDistance + gap
        : -underViewMultiple * transformDistance - gap

      // Keep past screen mounted during instant navigation to avoid losing data
      if (!navigationInProgress) {
        if (instantNavBack) {
          if (!reverseInstantNav) {
            const prevPrevScreen = (
              <Screen
                topScreen
                active={false}
                component={app.components[nextScreen]}
                params={currentRoute.params}
                layoutGuides={layoutGuides}
              />
            )

            children.push(
              <Animated.View
                key={
                  currentRoute.target
                    ? `${routeStack.length - 2}-${nextScreen}`
                    : '0'
                }
                style={[
                  {
                    transform: [
                      {
                        [transformType]: currentViewOffset.interpolate({
                          inputRange,
                          outputRange: [0, prevScreenEnd],
                        }),
                      },
                    ],
                  },
                  styles.inner,
                ]}
              >
                {prevPrevScreen}
              </Animated.View>
            )
          }
        } else if (screenLoading) {
          const prevPrevRoute = peek(routeStack, 1)
          if (prevPrevRoute) {
            const prevPrevComponent =
              prevPrevRoute && app.components[prevPrevRoute.target]
            const prevPrevScreen = (
              <Screen
                topScreen
                active={false}
                component={prevPrevComponent}
                params={prevPrevRoute.params}
                layoutGuides={layoutGuides}
              />
            )

            children.push(
              <Animated.View
                key={`${previousRouteIndex - 1}-${prevPrevRoute.target}`}
                style={[
                  {
                    transform: [
                      {
                        [transformType]: currentViewOffset.interpolate({
                          inputRange,
                          outputRange: [0, prevScreenEnd],
                        }),
                      },
                    ],
                  },
                  styles.inner,
                ]}
              >
                {prevPrevScreen}
              </Animated.View>
            )
          }
        }
      }

      children.push(
        <Animated.View
          style={[
            prevFadeStyles,
            styles.inner,
            {
              transform: [
                {
                  [transformType]: currentViewOffset.interpolate({
                    inputRange,
                    outputRange: [0, prevScreenEnd],
                  }),
                },
              ],
            },
          ]}
          key={`${previousRouteIndex}-${previousRoute.target}`}
        >
          {previousScreen}
        </Animated.View>
      )

      if (dimUnderView) {
        const fadeOutputRange = reverseInstantNav ? [1, 0] : [0, 1]
        children.push(
          <Animated.View
            key="fader"
            style={[
              styles.inner,
              styles.fader,
              {
                opacity: currentViewOffset.interpolate({
                  inputRange,
                  outputRange: fadeOutputRange,
                }),
              },
            ]}
          />
        )
      }
    }

    const currentScreenStart = reverseInstantNav
      ? -underViewMultiple * transformDistance - gap
      : transformDistance + gap

    children.push(
      <Animated.View
        key={
          currentRoute.target
            ? `${currentRouteIndex}-${currentRoute.target}`
            : '0'
        }
        style={[
          styles.inner,
          {
            ...fadeStyles,
            transform: [
              {
                [transformType]: currentViewOffset.interpolate({
                  inputRange,
                  outputRange: [currentScreenStart, 0],
                }),
              },
            ],
          },
        ]}
      >
        {currentScreen}
      </Animated.View>
    )

    if (reverseInstantNav) {
      const prevPrevScreen = (
        <Screen
          topScreen
          active={false}
          component={app.components[nextScreen]}
          params={currentRoute.params}
          layoutGuides={layoutGuides}
        />
      )

      children.push(
        <Animated.View
          key={
            currentRoute.target ? `${routeStack.length - 2}-${nextScreen}` : '0'
          }
          style={[styles.inner]}
        >
          {prevPrevScreen}
        </Animated.View>
      )

      children.reverse()
    }

    return <View style={styles.wrapper}>{children}</View>
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: '#222',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  fader: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
})

const mapStateToProps = state => ({
  userUsedTemporaryLogin: state.auth.userUsedTemporaryLogin,
})

export default connect(mapStateToProps, {
  showNotification,
  resetRandomNumbers,
})(Navigator)
