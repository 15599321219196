import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import DatePickerMobile from '@protonapp/react-mobile-date-picker'

import PropTypes from 'prop-types'
import moment from 'moment'
import { bindingTypes, dataTypes } from '@adalo/constants'
import '@protonapp/react-mobile-date-picker/styles.css'

import { serializeDate } from '../../utils/dates'
import { evaluateBinding } from '../../utils/dependencies'
import { isiOSBrowser } from '../../utils/browsers'

import { changeValue, setDefaultValue, getValue } from '../../ducks/formInputs'

import BaseObject from '../BaseObject'

import ActionWrapper from '../ActionWrapper'
import PortalContainer from './PortalContainer'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css'

const defaults = {
  PLACEHOLDER_COLOR: '#9e9e9e',
}

class Picker extends BaseObject {
  static contextTypes = {
    getOffsetTop: PropTypes.func,
    getApp: PropTypes.func,
  }

  handleDateChange = (_dateString, dateObj) => {
    const { object, changeValue } = this.props

    const date = serializeDate(dateObj)

    changeValue(object.id, date)
  }

  handleChange = newValue => {
    if (Object.prototype.toString.call(newValue) === '[object Date]') {
      newValue = moment(newValue)
      const { changeValue, object } = this.props

      const date = serializeDate(newValue)

      if (!newValue.isValid()) {
        return
      }

      changeValue(object.id, date)
    }
  }

  handleOnSelect = newValue => {
    const { object, value, changeValue } = this.props

    if (!value) {
      const date = serializeDate(newValue)
      changeValue(object.id, date)
    }
  }

  handleChangeInput = e => {
    const newValue = e.target.value || null

    const { changeValue, object } = this.props

    if (newValue && newValue.match(/^\d{5}/)) {
      return
    }

    changeValue(object.id, newValue)
  }

  hasDateBinding = () => {
    const { object } = this.props

    return (
      object.dataBinding &&
      object.dataBinding.bindingType === bindingTypes.SET_DATE
    )
  }

  getDefaultValue = () => {
    const { bindingData, defaultDate } = this.props

    if (defaultDate) {
      return defaultDate
    }

    if (this.hasDateBinding() && bindingData) {
      return bindingData
    }

    return ''
  }

  getDateString = () => {
    const { object, app } = this.props
    const { datePickerStyle } = object.attributes

    if ([null, 'mobile'].includes(app.primaryPlatform)) {
      if (datePickerStyle === 'dateTextInput') {
        return 'L'
      }

      return this.getShowTime() ? 'ddd, MMM DD, YYYY LT' : 'dddd, MMMM DD, YYYY'
    } else {
      if (datePickerStyle === 'dateTextInput') {
        return 'YYYY-MM-DD'
      }
      return this.getShowTime() ? 'E, LLL d, yyyy p' : 'EEEE, LLLL d, yyyy'
    }
  }

  getShowTime = () => {
    const { object } = this.props
    const { datePickerStyle } = object.attributes

    return !datePickerStyle || datePickerStyle === 'date'
  }

  componentDidMount() {
    const { object, setDefaultValue } = this.props

    setDefaultValue(object.id, this.getDefaultValue())
  }

  componentDidUpdate(prevProps) {
    const { object, bindingData, setDefaultValue, value } = this.props

    if (value !== prevProps.value) {
      // check if date picker has a value, otherwise set a default value
      if (!value) setDefaultValue(object.id, this.getDefaultValue())
    }

    if (
      this.hasDateBinding() &&
      bindingData &&
      bindingData !== prevProps.bindingData
    ) {
      setDefaultValue(object.id, bindingData)
    }
  }

  datePickerRef = el => {
    this._datePickerEl = el
  }

  render() {
    const { object, component, bindingData, value, app } = this.props
    const { attributes, layout } = object

    const showTime = this.getShowTime()
    const dateString = this.getDateString()
    let mode = 'datetime'

    let initialDate = value ? moment(value) : moment()
    if (attributes.datePickerStyle === dataTypes.DATE_ONLY) {
      mode = 'date'

      if (value && typeof value === 'string' && value.includes('T')) {
        // remove the timestamp from the value
        // since the value should be date only

        initialDate = moment(value.split('T')[0])
      }
    }

    const bgStyles = this.backgroundStyles()
    const textStyles = this.textStyles()

    const isPlaceholder = value
      ? { color: `${textStyles.color}` }
      : { color: `${defaults.PLACEHOLDER_COLOR}` }

    // check if background color exists, otherwise default to white
    bgStyles.backgroundColor = bgStyles.backgroundColor || 'white'

    let dateValue
    if ([null, 'mobile'].includes(app.primaryPlatform)) {
      if (isiOSBrowser()) {
        dateValue = value && value.length > 0 ? initialDate.format() : null
      } else {
        dateValue = value && value.length > 0 ? initialDate.format() : undefined
      }

      return (
        <ActionWrapper
          style={[layout, this.getOpacity()]}
          component={component}
          object={object}
          bindingData={bindingData}
        >
          <DatePickerMobile
            style={{ width: '100%' }}
            date={dateValue}
            mode={mode}
            confirmBtnText="OK"
            cancelBtnText="Cancel"
            onDateChange={this.handleDateChange}
            showIcon={false}
            format={dateString}
            is24Hour={false}
            customStyles={{
              dateInput: {
                ...bgStyles,
                ...textStyles,
                ...this.borderStyles(),
                ...this.shadowStyles(),
                ...isPlaceholder,
                alignItems: 'left',
                paddingLeft: attributes.padding,
                paddingRight: attributes.padding,
                height: attributes.height,
              },
              btnTextText: {
                color: '#07f',
              },
            }}
          />
        </ActionWrapper>
      )
    } else {
      if (attributes.datePickerStyle === 'dateTextInput') {
        dateValue =
          value && value.length > 0 ? initialDate.format(dateString) : ''
      } else {
        dateValue =
          value && value.length > 0 ? initialDate.toDate() : moment().toDate()
      }
    }

    if (attributes.datePickerStyle === 'dateTextInput') {
      const styles = {
        ...layout,
        ...this.borderStyles(),
        ...this.backgroundStyles(),
        ...this.getOpacity(),
        height: attributes.height,
      }

      const inputStyles = {
        ...textStyles,
        ...isPlaceholder,
        paddingLeft: attributes.padding,
      }

      return (
        <View style={styles}>
          <input
            type="date"
            className="date-text-input"
            style={inputStyles}
            value={dateValue || ''}
            onChange={this.handleChangeInput}
          />
        </View>
      )
    }

    return (
      <div
        ref={this.datePickerRef}
        style={{
          ...this.borderStyles(),
          ...this.backgroundStyles(),
          ...this.shadowStyles(),
          ...this.textStyles(),
          ...this.getOpacity(),
          ...layout,
          alignItems: 'flex-start',
          paddingLeft: attributes.padding,
          height: attributes.height,
          color: attributes.color,
        }}
      >
        <DatePicker
          className={`data-object-form-input-input ${
            !value && 'input-placeholder'
          }`}
          onSelect={this.handleOnSelect}
          selected={dateValue}
          onChange={this.handleChange}
          showTimeInput={showTime}
          dateFormat={dateString}
          popperContainer={PortalContainer}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  value: getValue(state, props.object.id),
  defaultDate: evaluateBinding(
    state,
    props.object.attributes.defaultDate,
    props
  ),
})

export default connect(mapStateToProps, { changeValue, setDefaultValue })(
  Picker
)
