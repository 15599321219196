/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Image, PixelRatio, StyleSheet, Platform } from 'react-native'
import { bindingTypes } from '@adalo/constants'

import { getScaledSources } from '../utils/images'
import { solveBindings } from '../utils/library-binding'
import Section from './Section'

const libraryComponentManifest = {
  props: [
    {
      name: 'image',
      displayName: 'Image',
      type: 'image',
    },
  ],
}

class ImageView extends Section {
  static contextTypes = {
    getAssetURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
  }

  getURL = () => {
    const { object, componentProps, getAssetURL } = this.props

    if (object.attributes.imageType === 'dynamic') {
      if (componentProps && componentProps.image) {
        const image = Array.isArray(componentProps.image)
          ? componentProps.image[0]
          : componentProps.image
        return image.uri
      } else if (
        object &&
        object.attributes &&
        object.attributes.imageBinding &&
        typeof object.attributes.imageBinding.options !== 'undefined'
      ) {
        const { options } = object.attributes.imageBinding
        const placeholderImageEnabled =
          typeof options.placeholderImageEnabled !== 'undefined'
            ? options.placeholderImageEnabled
            : null
        const placeholderImage =
          typeof options.placeholderImage !== 'undefined'
            ? options.placeholderImage
            : null
        if (placeholderImageEnabled && placeholderImage) {
          return getAssetURL(options.placeholderImage)
        }
      } else {
        return undefined
      }
    }

    if (object.attributes.imageType === 'url') {
      const { attributes } = object
      if (Array.isArray(attributes.imageURL)) {
        if (componentProps && componentProps.image) {
          return componentProps.image.uri
        } else if (
          attributes &&
          attributes.imageBinding &&
          typeof attributes.imageBinding.options !== 'undefined'
        ) {
          const { options } = object.attributes.imageBinding
          const placeholderImageEnabled =
            typeof options.placeholderImageEnabled !== 'undefined'
              ? options.placeholderImageEnabled
              : null
          const placeholderImage =
            typeof options.placeholderImage !== 'undefined'
              ? options.placeholderImage
              : null
          if (placeholderImageEnabled && placeholderImage) {
            return getAssetURL(options.placeholderImage)
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      }

      return object.attributes.imageURL
    }

    const bindingInfo = this.getDataBindingOfType(bindingTypes.SET_IMAGE)
    if (bindingInfo) {
      let { binding, data } = bindingInfo
      if (data && typeof data === 'object') {
        data = data.url
      }

      const baseURL = this.context.getImageUploadsBaseURL()

      if (data || !binding.includeDefault) {
        if (data && typeof data === 'string' && data.match(/^(file|data):/)) {
          return data
        }

        return data && `${baseURL}/${data}?orient`
      }
    }

    const ratio = PixelRatio.getPixelSizeForLayoutSize(1)
    let sizes = [3, 2, 1]
    sizes.sort((a, b) => Math.abs(ratio - a) - Math.abs(ratio - b))

    const largerSizes = sizes.filter(s => s >= ratio)
    const smallerSizes = sizes.filter(s => s < ratio)
    sizes = largerSizes.concat(smallerSizes)

    let filename = null

    if (object.imageId) {
      filename = `${object.imageId}@${sizes[0]}x.png`
    } else {
      for (let i = 0; i < sizes.length; i += 1) {
        const size = sizes[i]
        const key = `filename${size}x`

        if (object.attributes[key]) {
          filename = object.attributes[key]
          break
        }
      }
    }

    if (!filename) {
      return null
    }

    if (typeof filename === 'object') {
      filename = filename.url
    }

    return this.context.getAssetURL(filename)
  }

  getSource = () => {
    const { bindingData, object } = this.props
    const { attributes } = object

    let src = this.getURL()
    if (typeof src === 'string' && src.match('^http://')) {
      src = src.replace(/^http:\/\//i, 'https://')
    }

    const cache = { cache: 'force-cache' }
    const isWeb = Platform.OS === 'web'

    if (src && bindingData && typeof bindingData === 'object' && !isWeb) {
      return getScaledSources(src, bindingData.width, bindingData.height)
    }

    // URL images
    if (attributes.imageType === 'url') {
      const { imageURL } = attributes
      if (!Array.isArray(imageURL)) return { uri: imageURL }
    }

    // Local images
    if (typeof src === 'number') return src

    // Remote images
    return { ...cache, uri: src }
  }

  renderBackground() {
    const { object } = this.props
    const { attributes } = object
    const source = this.getSource()

    return (
      <Image
        source={source}
        style={styles.image}
        resizeMode={attributes.imageResize || 'cover'}
      />
    )
  }
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    width: null,
    height: null,
  },
})

const mapStateToProps = (state, props) => {
  const {
    object,
    getApp,
    getBinding,
    getParams,
    getImageUploadsBaseURL,
    listItemId,
  } = props

  if (
    object.attributes.imageType === 'dynamic' ||
    object.attributes.imageType === 'url'
  ) {
    const app = getApp && getApp()

    const { width, height } = object.attributes

    const image =
      object.attributes.imageType === 'url'
        ? { type: 'binding', source: object.attributes.imageURL }
        : object.attributes.imageBinding

    const fakeObject = {
      attributes: {
        image,
        width,
        height,
        listItemId,
      },
      libraryComponentManifest,
    }

    return solveBindings(app, fakeObject, {
      state,
      getBinding,
      getParams,
      getImageUploadsBaseURL,
    })
  }

  return {}
}

const ConnectedImageView = connect(mapStateToProps)(ImageView)

export default class WrappedImageView extends Component {
  static contextTypes = {
    getApp: PropTypes.func,
    getBinding: PropTypes.func,
    getParams: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
    getAssetURL: PropTypes.func,
  }

  render() {
    const {
      getApp,
      getBinding,
      getParams,
      getImageUploadsBaseURL,
      getAssetURL,
    } = this.context

    return (
      <ConnectedImageView
        {...this.props}
        getApp={getApp}
        getBinding={getBinding}
        getParams={getParams}
        getImageUploadsBaseURL={getImageUploadsBaseURL}
        getAssetURL={getAssetURL}
      />
    )
  }
}
