import { Buffer as ClassicBuffer } from 'buffer/'

import {
  dataTypes,
  sourceTypes,
  selectors as selectorTypes,
  sortDirections,
  imageTypes,
  fileTypes,
  actionTypes,
  comparators,
} from '@adalo/constants'

import { getTableData, getCollection, getItemById } from 'ducks/data'
import { unsafeGetToken } from 'ducks/auth'
import { getValue, getFinalValue } from 'ducks/formInputs'
import {
  getRandomNumber,
  evaluateRandomNumber,
  createRandomHash,
} from 'ducks/random'
import { getStore } from 'ducks'
import { getCurrentLocation } from 'ducks/location'

import { newId } from './counter'
import { formatValue } from './formats'
import { generateFilter, encodeFilter } from './filtering'
import { getDateSourceValue, serializeDate } from './dates'
import { values, uniqueElements } from './arrays'
import { buildObject } from './objects'
import { applyFilter, evaluateFilters } from './filter'
import { applySort } from './sorting'
import { applyLimit } from './limiting'

import {
  getActionSources,
  getAllSources,
  flattenSourceList,
} from './action-classes'

import { shouldHaveCollection, isAggregateType, getFieldValue } from './sources'
import {
  isExternalCollection as verifyExternalCollection,
  getExternalCollectionBindings,
} from './externalCollections'
import selectors, { shouldSkipFetch } from './selectors'
import { parseStringToNumber } from './numbers'

import {
  getIncludes,
  isChainedRelations,
  getRootSource,
  getFlatIncludes,
} from './includes'

import { getPayload } from './jwt'
import {
  asKilometers,
  kilometersBetweenLocations,
  convertToMiles,
} from './location'

const ONE_DAY = 24 * 60 * 60 * 1000

const DATA_SOURCES = [
  sourceTypes.DATA,
  sourceTypes.BELONGS_TO,
  sourceTypes.HAS_MANY,
  sourceTypes.MANY_TO_MANY,
  sourceTypes.PARAM,
  sourceTypes.API_ENDPOINT,
  sourceTypes.DEVICE_LOCATION,
]

const relationTypes = [
  sourceTypes.BELONGS_TO,
  sourceTypes.HAS_MANY,
  sourceTypes.MANY_TO_MANY,
]

const distanceComparators = [
  comparators.DISTANCE_LESS_THAN,
  comparators.DISTANCE_GREATER_THAN,
]

const objectDataTypes = [dataTypes.FILE, dataTypes.IMAGE, dataTypes.LOCATION]

const isFieldType = type => {
  return type === sourceTypes.FIELD || type === sourceTypes.API_FIELD
}

const isExternalUsersType = type => {
  return (
    type === sourceTypes.EXTERNAL_USERS_ID ||
    type === sourceTypes.EXTERNAL_USERS_TOKEN
  )
}

export const fetchValueFromCollection = (fields, collection) => {
  if (!fields) {
    return null
  }

  if (fields.length === 1) {
    return collection[fields[0]]
  }

  const newCollection = collection[fields[0]]
  return fetchValueFromCollection(fields.slice(1), newCollection)
}

export const getAggregateValue = (collection, sourceType, fieldId) => {
  if (typeof collection === 'number') {
    return collection
  }

  if (!collection || !Array.isArray(collection)) {
    return null
  }

  if (sourceType === sourceTypes.COUNT) {
    if (collection.length === 1 && collection[0].id === 'count') {
      for (const itm in collection[0]) {
        if (itm.includes('_count')) {
          return collection[0][itm]
        }
      }
    }

    return collection.length
  }

  const values = []

  for (let i = 0; i < collection.length; i += 1) {
    const item = fetchValueFromCollection(fieldId.split('.'), collection[i])

    if (typeof item === 'number') {
      values.push(item)
    }
  }

  if (values.length === 0) {
    return null
  }

  switch (sourceType) {
    case sourceTypes.MIN:
      return Math.min(...values)

    case sourceTypes.MAX:
      return Math.max(...values)

    case sourceTypes.MIN_MAX: {
      const min = Math.min(...values)
      const max = Math.max(...values)
      return min === max ? [min] : [min, max]
    }
    case sourceTypes.SUM:
      return values.reduce((a, b) => a + b)

    case sourceTypes.AVERAGE:
      return values.reduce((a, b) => a + b) / values.length
  }

  return 'Error'
}

export const evaluateBinding = (state, binding, opts, isAction) => {
  if (!binding || typeof binding === 'string') return binding

  return getObjectBinding(
    state,
    { id: binding.id, dataBinding: binding },
    opts,
    null,
    isAction
  )
}

// Return relevant binding data for a particular object
// Alternate format: getObjectBinding(state, obj, opts, null, isActionParent)
export const getObjectBinding = (
  state,
  object,
  getParams,
  getBinding,
  isActionParent
) => {
  let opts = {
    state,
    getParams,
    getBinding,
  }

  let isAction = isActionParent || false

  if (typeof getParams === 'object') {
    opts = {
      ...getParams,
      state,
    }

    isAction = isAction || getParams.isAction
    getParams = getParams.getParams
  }

  const { id, dataBinding } = object

  opts = { ...opts, objectId: id }

  let bindingValue

  if (dataBinding) {
    const sourceType = dataBinding.source && dataBinding.source.type
    const collection = getCollection(state, id, getParams())

    if (shouldHaveCollection(dataBinding.source)) {
      if (!collection) {
        return
      }

      const source = isAggregateType(sourceType)
        ? dataBinding.source.source
        : dataBinding.source

      const filteredCollection = getFilteredCollection(collection, source, opts)

      if (isAggregateType(sourceType)) {
        const value = getAggregateValue(
          filteredCollection,
          sourceType,
          dataBinding.source.fieldId
        )

        return formatValue(dataBinding, value, dataBinding.format, opts)
      }

      if (isFieldType(sourceType)) {
        const obj = filteredCollection[0]

        if (obj) {
          return formatValue(
            dataBinding,
            obj[source.fieldId],
            dataBinding.format,
            opts
          )
        }

        return undefined
      }

      return filteredCollection
    }

    bindingValue = getBindingValue(
      dataBinding,
      opts,
      isAction,
      dataBinding.format
    )

    // following if-statement added for counts performance
    // https://trello.com/c/bg0ndc2J
    if (
      dataBinding?.source?.dataType === dataTypes.LIST &&
      Number.isInteger(bindingValue)
    ) {
      bindingValue = collection
    }

    if (
      dataBinding.source &&
      dataBinding.source.dataType === dataTypes.LIST &&
      bindingValue
    ) {
      bindingValue = getFilteredCollection(
        bindingValue,
        dataBinding.source,
        opts
      )
    }
  }

  return bindingValue
}

export const getFilteredCollection = (collection, source, opts) => {
  if (source.dataType !== dataTypes.LIST) {
    return collection
  }

  let { sort, options } = source
  options = options || {}

  const { filter } = options

  if (filter) {
    const getValue = (binding, moreOpts) => {
      const newOpts = { ...opts, ...moreOpts }

      return evaluateBinding(opts.state, binding, newOpts, opts.isAction)
    }

    collection = applyFilter(collection, filter, getValue, opts)
  }

  collection = applySort(collection, sort, opts)

  if (options.limit) {
    collection = applyLimit(collection, options.limit)
  }

  return collection
}

export const getInputs = (action, opts) => {
  const { getBindingsList } = opts
  const sources = flattenSourceList(getActionSources(action))

  const inputSources = sources
    .map(([path, src]) => (src.type === 'binding' ? src.source : src))
    .filter(
      src =>
        src && src.type === sourceTypes.INPUT && src.dataType !== dataTypes.DATE
    )

  return inputSources.map(src => {
    if (Array.isArray(src.objectId)) {
      const bindingIds = getBindingsList()
      const parentListLength = src.objectId.length - 1
      const inputId = src.objectId[parentListLength]
      return bindingIds.slice(0, parentListLength).concat([inputId]).join('.')
    }
    return src.objectId
  })
}

export const getSingleActionDependencies = (state, action, opts) => {
  let members = getActionSources(action)

  let filename

  if (
    Array.isArray(action.options.fields) &&
    action.actionType === actionTypes.UPDATE_OBJECT
  ) {
    filename = action.options.fields[0].fileName
  }

  members = members.map(([key, source]) => {
    return [key, getActionSourceValue(state, source, opts)]
  })

  if (filename) {
    try {
      const value = { url: members[1][1].value, filename }
      members[1][1].value = value
    } catch (e) {
      console.warn('error setting file name')
    }
  }

  const inputIds = getInputs(action, opts)

  return [buildObject(members), inputIds]
}

export const getActionDependencyBindings = component => {
  const actionsMap = component.actions || {}
  const result = getAllSources(actionsMap)
  const sources = result.map(spread => spread[1])

  const bindings = {}

  sources
    .filter(itm => itm && typeof itm === 'object')
    .forEach(source => {
      if (source.type === 'binding') {
        bindings[source.id] = source
      } else {
        bindings[newId()] = { source }
      }
    })

  return bindings
}

const getDataSource = source => {
  if (!source) return source

  const { type } = source

  if (DATA_SOURCES.includes(type)) {
    return source
  } else if (source.source) {
    return getDataSource(source.source)
  }
}

export const getDependencies = (component, opts) => {
  opts = { ...opts }

  if (!opts.getBinding) {
    opts.getBinding = () => {}
  }

  let { dataBindings } = component

  // Merge in action bindings
  const actionBindings = getActionDependencyBindings(component)
  dataBindings = {
    ...dataBindings,
    ...actionBindings,
  }

  return getDependenciesSub(dataBindings, opts)
}

export const evaluateLimit = (cachedLimit, newLimit) => {
  if (newLimit === null || cachedLimit === null) return null
  if (cachedLimit < newLimit) return newLimit
  return cachedLimit
}

export const getDependenciesSub = (dataBindings, opts) => {
  const cache = {}
  const idMap = {}

  // Maps list object -> base include path
  const baseIncludes = {}

  // Use this to map parents -> list children
  const includesMap = {}

  // Param values for sourceTypes.API_ENDPOINT
  const apiParams = {}

  // Check if current user
  let isCurrentUser = false

  const baseOpts = opts

  let externalCollectionDependencies

  for (const bindingId in dataBindings) {
    const opts = { ...baseOpts, objectId: bindingId }
    const binding = dataBindings[bindingId]
    const { listenForChanges } = binding.options || {}

    try {
      let includes = []

      let { source } = binding
      const originalSource = source

      source = getDataSource(source)

      if (!source) {
        continue
      }

      // TODO: Remove this and properly fetch counts
      if (isAggregateType(source.type)) {
        source = source.source
      }

      // Just fetch the main source for autosaves
      if (source.type === sourceTypes.AUTOSAVE) {
        source = source.source
      }

      // Inside list
      const rootSource = getRootSource(source)

      if (
        (rootSource &&
          rootSource !== source &&
          isChainedRelations(source) &&
          rootSource.selector &&
          rootSource.selector.type === selectorTypes.LIST_ITEM) ||
        originalSource.type === sourceTypes.AUTOSAVE
      ) {
        includes = getIncludes(source)
        source = rootSource

        baseIncludes[bindingId] = includes[0] // eslint-disable-line prefer-destructuring

        // Add parent to map
        const parentId = source.selector.listObjectId
        includesMap[parentId] = includesMap[parentId] || []
        includesMap[parentId].push(bindingId)
      }

      // Belongs-to
      // NOTE: This is hit for param bindings
      // TODO: Figure out if this was needed for anything else
      if (
        rootSource !== source &&
        isChainedRelations(source) &&
        source.dataType !== dataTypes.LIST
      ) {
        includes = getIncludes(source)
        source = rootSource
        baseIncludes[bindingId] = includes[0] // eslint-disable-line prefer-destructuring
      }

      let { datasourceId, tableId, collectionId, endpointId, selector } = source

      const authToken = unsafeGetToken(datasourceId)

      let id = null
      let filter = null

      // Check if the binding refers to an external collection. If so, check if
      // the external collection has bindings in it, and if so, add a dependency
      // for those bindings.
      const { getDatasources } = opts
      const datasource = getDatasources()[datasourceId]
      const isExternalCollection = verifyExternalCollection({
        datasource,
        tableId,
      })

      if (!opts.externalCollectionRun && isExternalCollection) {
        // Merge in external collection bindings
        const externalCollectionBindings = getExternalCollectionBindings({
          datasource,
          tableId,
        })

        if (externalCollectionBindings) {
          opts.externalCollectionRun = true
          externalCollectionDependencies = getDependenciesSub(
            externalCollectionBindings,
            opts
          )
        }

        externalCollectionDependencies = externalCollectionDependencies.filter(
          // eslint-disable-next-line no-return-assign
          dep => (dep.fetchFirst = true)
        )
      }

      // No need to evaluate for params + list items
      // TODO: this may break adding / removing relationships + pressing back
      if (selector && shouldSkipFetch(selector.type)) {
        continue
      }

      if (selector) {
        const { getParams } = opts
        const selectorFunc = selectors[selector.type]
        id = selectorFunc(source, { authToken, getParams })

        if (!id) {
          throw new Error(`Selector failed: ${JSON.stringify(selector)}`)
        }

        if (selector.type === selectorTypes.CURRENT_USER) {
          isCurrentUser = true
        }
      }

      if (source.type === sourceTypes.PARAM) {
        const { getParams } = opts

        if (source.dataType !== dataTypes.OBJECT) {
          continue
        }

        datasourceId = source.datasourceId
        tableId = source.tableId
        id = getParams()[source.paramId]

        if (!id) {
          continue
        }
      }

      if (source.type === sourceTypes.API_ENDPOINT) {
        const params = (source.options && source.options.params) || {}

        for (const key in params) {
          try {
            apiParams[key] = getBindingValue(params[key], opts)
          } catch (err) {
            console.warn(`Error get param value: ${key} -`, err)
          }
        }
      }

      // TODO: Replace when converting to graphQL
      if (
        source.type === sourceTypes.HAS_MANY ||
        source.type === sourceTypes.MANY_TO_MANY ||
        (source.type === sourceTypes.BELONGS_TO &&
          source.source.dataType === 'list')
      ) {
        const { getParams } = opts

        filter = generateFilter(source, {
          authToken,
          getParams,
          getBinding: () => null,
        })
      }

      let sort = null
      let sortReference = ''
      let paginate = false
      let columnFilter = ''
      let apiFilter = ''
      let limit = null

      if (source.sort && source.sort.fieldId) {
        const direction =
          source.sort.direction === sortDirections.DESC ? '-' : ''
        sort = `${direction}${source.sort.fieldId}`
        if (source.sort.type === dataTypes.LOCATION) {
          const { fallback, source: sortSource } = source.sort.reference || {}
          let bindingValue = getBindingValue(sortSource, opts)
          if (!bindingValue && fallback) {
            bindingValue = fallback
          }

          const { coordinates } = bindingValue || {}

          if (coordinates) {
            const { latitude, longitude } = coordinates
            sortReference = `${latitude},${longitude}`
          }
        }
      }

      if (source.options) {
        if (source.options.limit) {
          limit = parseInt(source.options.limit)
        }

        if (source.options.filter) {
          columnFilter = buildColumnFilter(source.options.filter, opts)
        }

        if (source.options.queryParams) {
          apiFilter = getAPIFilter(source.options.queryParams, opts)
        }
      }

      if (binding.options && binding.options.paginate) {
        paginate = true
      }

      let cacheKey = `${datasourceId}.${tableId || collectionId}`

      if (endpointId) {
        cacheKey = `${cacheKey}.${endpointId}`
      }

      if (id) {
        cacheKey = `${cacheKey}.${id}`
      }

      if (filter) {
        cacheKey = `${cacheKey}?${encodeFilter(filter)}`
      }

      if (columnFilter) {
        cacheKey = `${cacheKey}?${columnFilter}`
      }

      if (apiFilter) {
        cacheKey = `${cacheKey}?${apiFilter}`
      }

      if (sort) {
        cacheKey = `${cacheKey}?${sort}`
      }

      idMap[bindingId] = cacheKey

      if (cache[cacheKey]) {
        if (includes.length > 0) {
          if (cache[cacheKey].includes) {
            includes = includes.concat(cache[cacheKey].includes)
          }

          cache[cacheKey].includes = includes
        }

        if (Object.keys(apiParams).length > 0) {
          cache[cacheKey].apiParams = {
            ...cache[cacheKey].apiParams,
            ...apiParams,
          }
        }

        // Append binding ID to list of bindingIds for this cache
        cache[cacheKey].bindingId = (cache[cacheKey].bindingId || []).concat([
          bindingId,
        ])

        if (listenForChanges) {
          cache[cacheKey].listenForChanges = true
        }

        if (paginate) {
          cache[cacheKey].paginate = true
        }

        cache[cacheKey].limit = evaluateLimit(cache[cacheKey].limit, limit)
        continue
      }

      const routeParams = opts.getParams()
      const counts = buildCounts(dataBindings, bindingId)

      const result = {
        isExternalCollection,
        datasourceId,
        tableId,
        collectionId,
        endpointId,
        id,
        filter,
        counts,
        includes,
        sort,
        sortReference,
        limit,
        routeParams,
        listenForChanges,
        paginate,
        columnFilter,
        apiFilter,
        apiParams,
        isCurrentUser,
        bindingId: [bindingId],
        appId: opts.getApp().id,
      }

      cache[cacheKey] = result
    } catch (err) {
      // Do nothing
      console.warn(
        'SKIPPING ERRONEOUS DEPENDENCY',
        err,
        JSON.stringify(binding)
      )
    }
  }

  const appendIncludes = getFlatIncludes(includesMap, baseIncludes)

  appendIncludes.forEach(([listObjectId, includes]) => {
    const cacheKey = idMap[listObjectId]

    if (!cache[cacheKey]) {
      return
    }

    cache[cacheKey].includes = (cache[cacheKey].includes || []).concat(includes)
  })

  let results = Object.keys(cache).map(k => cache[k])

  results = results.filter(({ datasourceId, tableId, collectionId }) => {
    return datasourceId && (tableId || collectionId)
  })

  if (externalCollectionDependencies) {
    results = results.concat(externalCollectionDependencies)
  }

  return results
}

export const buildCounts = (dataBindings, bindingId) => {
  const counts = []
  let countId
  let binding
  let hasFilter
  let isVisibilityBinding
  let hasSiblingRequest

  for (const itm in dataBindings) {
    binding = dataBindings[itm]

    if (binding.source?.type === 'count') {
      // check for a sibling request, ie. same tableId
      for (const id in dataBindings) {
        if (id === bindingId) continue

        if (
          (binding?.source?.source?.tableId ===
            dataBindings[id]?.source?.source?.tableId ||
            binding?.source?.source?.tableId ===
              dataBindings[id]?.source?.tableId) &&
          dataBindings[id]?.source?.type !== 'count'
        ) {
          hasSiblingRequest = true
          break
        }
      }

      // Check if the count is being filtered
      hasFilter = binding?.source?.source?.options?.filter?.length > 0

      // Check if the count is a visibility binding
      isVisibilityBinding = binding.bindingType === 'visibility'

      if (!hasSiblingRequest && !hasFilter && !isVisibilityBinding) {
        countId = binding.source.source.fieldId || binding.source.source.tableId
        counts.push(countId)
      }
    }
  }

  return counts
}

const evaluateFormula = (formula, opts, isAction) => {
  const { parsedFormula, outputDataType, componentId, id: formulaId } = formula

  if (!parsedFormula) {
    return undefined
  }

  const result = evaluateFormulaSub(
    parsedFormula,
    { ...opts, componentId, formulaId },
    isAction
  )

  const dataType = outputDataType || getFormulaDataType(formula.formula)

  if ([dataTypes.DATE, dataTypes.DATE_ONLY].includes(dataType)) {
    if (!result) {
      return null
    }

    const dateValue = new Date(result * ONE_DAY)

    const dateString = serializeDate(dateValue)

    if (dataType === dataTypes.DATE_ONY) {
      return dateString.split('T')[0]
    }

    return dateString
  }

  if (Number.isNaN(result)) {
    return null
  }

  return result
}

const getFormulaDataType = args => {
  let dataType = dataTypes.NUMBER

  for (const arg of args) {
    if (!arg || typeof arg !== 'object') {
      continue
    }

    const source = arg.type === 'binding' ? arg.source : arg

    if (
      source &&
      [dataTypes.DATE, dataTypes.DATE_ONLY].includes(source.dataType)
    ) {
      dataType = dataTypes.DATE
    }
  }

  return dataType
}

const evaluateFormulaSub = (expression, opts, isAction) => {
  const { index: rootIndex = 0 } = opts

  if (Array.isArray(expression)) {
    const [type, ...args] = expression
    const evaluatedArgs = args.map((arg, index) => {
      const innerIndex = `${rootIndex}.${index}`

      return evaluateFormulaSub(arg, { ...opts, index: innerIndex }, isAction)
    })

    let result = 0

    switch (type) {
      case 'sum':
        result = +evaluatedArgs[0] + +evaluatedArgs[1]
        break
      case 'subtraction':
        result = +evaluatedArgs[0] - +evaluatedArgs[1]
        break
      case 'product':
        result = +evaluatedArgs[0] * +evaluatedArgs[1]
        break
      case 'division':
        result = +evaluatedArgs[0] / +evaluatedArgs[1]
        break
      case 'negative':
        result = -evaluatedArgs[0]
        break
      case 'round':
        result = Math.round(evaluatedArgs[0])
        break
      case 'floor':
        result = Math.floor(evaluatedArgs[0])
        break
      case 'abs':
        result = Math.abs(evaluatedArgs[0])
        break
      case 'sqrt':
        result = Math.sqrt(evaluatedArgs[0])
        break
      case 'pow':
        result = evaluatedArgs[0] ** evaluatedArgs[1]
        break
      case 'random': {
        result = evaluateRandomValue(evaluatedArgs, opts)
        break
      }
      case 'log10':
        result = Math.log10(evaluatedArgs[0])
        break
      case 'kilometers':
      case 'miles':
        result = kilometersBetweenLocations(
          { latitude: evaluatedArgs[0], longitude: evaluatedArgs[1] },
          { latitude: evaluatedArgs[2], longitude: evaluatedArgs[3] }
        )

        if (type === 'miles') {
          result = convertToMiles(result)
        }

        break
    }

    return +result
  } else {
    if (expression.type === 'binding' && expression.source) {
      expression = { ...expression, format: undefined }
    }

    const { dataType } = expression.source || {}

    const result = evaluateBinding(opts.state, expression, opts, isAction)

    if (dataType === dataTypes.DATE || dataType === dataTypes.DATE_ONLY) {
      const date = new Date(result)
      const numericValue = +date / ONE_DAY

      return numericValue
    }

    return parseStringToNumber(result)
  }
}

const evaluateRandomValue = (evaluatedArgs, opts) => {
  const {
    objectId,
    index: rootIndex = 0,
    componentId,
    formulaId,
    listItemId,
  } = opts

  const offset = evaluatedArgs[0]
  const multiple = evaluatedArgs[1] - offset

  if (listItemId || !objectId) {
    return Math.round(Math.random() * multiple + offset)
  }

  const store = getStore()

  // Limitation for random numbers inside lists

  const id = createRandomHash(formulaId, objectId, rootIndex)

  const result = fetchRandomValue(store, id)

  // Workaround for render problem (don't evaluate if already exists)
  if (!result) {
    store.dispatch(
      evaluateRandomNumber({
        id,
        componentId,
        multiple,
        offset,
      })
    )

    return fetchRandomValue(store, id)
  }

  return result
}

const fetchRandomValue = (store, id) => {
  const { random: state } = store.getState()

  return getRandomNumber(state, id)
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const getBindingValue = (source, opts, isAction = false, format) => {
  const { state, getParams, getBinding, getBindingsList, getAssetURL } = opts

  let parentSourceValue
  let tableId
  let binding = { source }

  // this addresses issues with "hasMany" COUNTS, specifically in "visibility"
  if (source && source.source && source.source.type === 'hasManyRelation') {
    if (source.type === sourceTypes.COUNT) {
      const table = getTableData(state, source.source.tableId) || {}
      return Object.keys(table).length
    }
  }

  let imageSource
  if (source && source.type === 'imageBinding') {
    imageSource = source
    if (
      imageSource.imageType === imageTypes.UPLOADED &&
      imageSource.filename1x
    ) {
      return imageSource.filename1x
    }
    source = source.binding
  }

  let fileSource
  if (source && source.type === 'fileBinding') {
    fileSource = source
    if (fileSource.fileType === fileTypes.UPLOADED && fileSource.filename1x) {
      return {
        url: fileSource.filename1x.url,
        filename: fileSource.filename1x.filename,
        size: fileSource.filename1x.size,
      }
    }
    source = source.binding
  }

  if (source && source.type === 'binding') {
    binding = source
    format = source.format
    source = source.source
  }

  if (Array.isArray(source)) {
    let pieces = source.map(itm => evaluateBinding(state, itm, opts, isAction))

    if (
      pieces.filter(p => [undefined, null].includes(p)).length > 0 &&
      imageSource &&
      imageSource.options &&
      imageSource.options.placeholderImageEnabled &&
      imageSource.options.placeholderImage
    ) {
      const url = getAssetURL(imageSource.options.placeholderImage)
      return typeof url === 'number' ? null : url
    }

    pieces = pieces.filter(p => p || typeof p === 'number')

    return pieces.join('')
  }

  if (source && source.type === 'formula') {
    const value = evaluateFormula(source, opts, isAction)
    const binding = {
      source: {
        ...source,
        dataType: typeof value === 'string' ? dataTypes.DATE : dataTypes.NUMBER,
      },
    }

    if (typeof value === 'undefined') {
      return value
    }

    return formatValue(binding, value, source.format, opts)
  }

  if (!source || typeof source !== 'object') {
    return source
  }

  if (source.source) {
    tableId = source.source.tableId
    parentSourceValue = getBindingValue(source.source, opts, isAction)
  }

  if (isFieldType(source.type)) {
    let { fieldId } = source
    const { isUploadURL, uri } = source
    // This deals with some naming inconsistencies between uri and url
    if ((uri || parentSourceValue?.uri) && fieldId === 'url') {
      fieldId = 'uri'
    }

    const parentSource = source.source

    let parentVal
    if (parentSourceValue) {
      parentVal = getFieldValue(parentSourceValue, fieldId)
    }

    if (parentVal === undefined) {
      if (tableId) {
        const itmFromRelation = getItemById(state, tableId, parentSourceValue)
        parentVal = itmFromRelation && itmFromRelation[fieldId]
      }
      return parentVal
    }

    if (isUploadURL) opts = { ...opts, isUploadURL, parentSource }

    return formatValue(binding, parentVal, format, opts)
  }

  // Autosave Inputs

  if (source.type === sourceTypes.AUTOSAVE) {
    const comparisonValue = getBindingValue(source.value, opts, isAction)

    const result = (Array.isArray(parentSourceValue) ? parentSourceValue : [])
      .map(itm => itm.id)
      .includes(comparisonValue && comparisonValue.id)

    return result
  }

  // Aggregators

  if (isAggregateType(source.type)) {
    const value = getAggregateValue(
      parentSourceValue,
      source.type,
      source.fieldId
    )

    if (value === null || value === undefined) {
      return value
    }

    return formatValue(binding, value, format, opts)
  }

  // Custom Actions

  if (source.type === sourceTypes.CUSTOM_ACTION) {
    const { getCustomActionOutput } = opts

    return getCustomActionOutput(source.customActionId, source.key)
  }

  // Action Arguments

  if (source.type === sourceTypes.ACTION_ARGUMENT) {
    const { getActionArguments } = opts
    const { argumentIndex } = source
    return getActionArguments(argumentIndex)
  }

  // External Users Info
  if (isExternalUsersType(source.type)) {
    const { datasourceId } = source
    const token = unsafeGetToken(datasourceId)
    const payload = getPayload(token)

    if (!payload) return

    if (source.type === sourceTypes.EXTERNAL_USERS_ID) {
      return payload.id
    }

    if (source.type === sourceTypes.EXTERNAL_USERS_TOKEN) {
      return payload.authToken
    }
  }

  // Input

  if (source.type === sourceTypes.INPUT) {
    let inputId = source.objectId

    if (Array.isArray(inputId)) {
      const bindingsList = getBindingsList && getBindingsList()

      const bindingsListId =
        (bindingsList && bindingsList.slice(0, inputId.length - 1)) || []

      inputId = bindingsListId.concat([inputId[inputId.length - 1]]).join('.')
    }

    const value = isAction
      ? getFinalValue(state, inputId)
      : getValue(state, inputId)

    if (!format) {
      return value
    }

    return formatValue(binding, value, format, opts)
  }

  // Date / Time

  if (source.type === sourceTypes.DATETIME) {
    const date = getDateSourceValue(source, isAction)

    if (!format) {
      return date
    }

    return formatValue(binding, date, format, opts)
  }

  // Param

  if (source.type === sourceTypes.PARAM) {
    const { paramId } = source

    const value = getParams()[paramId]

    if (source.dataType === dataTypes.OBJECT) {
      const { tableId, collectionId } = source
      const map = getTableData(state, tableId || collectionId)

      return map && map[value]
    }

    return formatValue(binding, value, format, opts)
  }

  const relationKey = `${source.fieldId}`

  if (relationTypes.includes(source.type)) {
    if (!parentSourceValue) {
      return undefined
    }

    let collection = null
    if (parentSourceValue[`${relationKey}_value`]) {
      collection = parentSourceValue[`${relationKey}_value`]
    } else if (
      parentSourceValue[`${relationKey}_count`] ||
      parentSourceValue[`${relationKey}_count`] === 0
    ) {
      collection = parentSourceValue[`${relationKey}_count`]
    } else {
      collection = parentSourceValue[relationKey]
    }

    return getFilteredCollection(collection, source, opts)
  }

  if (source.type === sourceTypes.DEVICE_LOCATION) {
    const { location } = getCurrentLocation(state)

    if (location) {
      if (source.fieldId) {
        let value = getFieldValue(location, source.fieldId)

        // there may not be a name on the location object
        // so use fullAddress (which is guaranteed) as a fallback
        if (!value && source.fieldId === 'name') {
          value = getFieldValue(location, 'fullAddress')
        }

        return formatValue(binding, value, format)
      }

      return location
    }
  }

  // Data

  if (source.type === sourceTypes.DATA) {
    const { tableId, collectionId, datasourceId, selector } = source
    const map = getTableData(state, tableId || collectionId)
    let bindingData = map && values(map)
    const authToken = unsafeGetToken(datasourceId)

    if (selector) {
      const selectorFunc = selectors[selector.type]

      const getInputValue = inputId => {
        return isAction
          ? getFinalValue(state, inputId)
          : getValue(state, inputId)
      }

      if (selector.type === selectorTypes.LIST_ITEM) {
        const result = getBinding(source.selector.listObjectId)

        return result
      }

      if (selectorFunc) {
        const id = selectorFunc(source, {
          ...opts,
          authToken,
          getParams,
          getBinding,
          getInputValue,
        })

        if (map) {
          bindingData = map[id]
        } else {
          bindingData = { id }
        }
      }
    }

    // Filter the binding data
    if (!selector) {
      return getFilteredCollection(bindingData, source, opts)
    }

    return bindingData
  }
}

function getBindingDataType(binding) {
  if (!binding) {
    return dataTypes.TEXT
  }

  if (binding.type === 'binding') {
    return getBindingDataType(binding.source)
  }

  return binding.dataType || dataTypes.TEXT
}

export const getActionSourceValue = (state, source, opts) => {
  if (!source) {
    return { value: source }
  }

  let inputId

  const binding =
    source.type === 'binding' ? source : { source, type: 'binding' }

  let result = evaluateBinding(state, binding, opts, true)

  const bindingDataType = getBindingDataType(source)

  if (Array.isArray(result)) {
    result = result.map(itm => (itm._meta && itm._meta.id) || itm.id)
  } else if (
    result &&
    typeof result === 'object' &&
    !objectDataTypes.includes(bindingDataType)
  ) {
    result = (result._meta && result._meta.id) || result.id
  }

  if (source.type === sourceTypes.INPUT) {
    inputId = source.objectId
  }

  if (source.dataType === dataTypes.NUMBER) {
    result = parseStringToNumber(result)
  }

  return {
    inputId,
    value: result,
  }
}

export const buildColumnFilter = (filters, opts) => {
  if (!filters) {
    return ''
  }

  let columnFilters = filters.slice()

  if (!Array.isArray(columnFilters)) {
    columnFilters = [columnFilters]
  }

  const isSimpleFilter = !Array.isArray(columnFilters[0])

  if (isSimpleFilter) {
    columnFilters = [columnFilters]
  }

  columnFilters = evaluateFilters(columnFilters, opts)

  let formattedFilter = []

  for (const filter of columnFilters) {
    const result = filter
      .map(filterItem => {
        let {
          fieldId,
          comparator,
          comparatorOptions,
          comparison,
          comparison2,
        } = filterItem

        let filterObj = {}

        if (typeof fieldId === 'object' && fieldId.source.type === 'field') {
          fieldId = {
            targetTable: fieldId.source.source.tableId,
            mainFieldId: fieldId.source.source.fieldId,
            id: fieldId.source.fieldId,
          }
        } else {
          if (typeof fieldId !== 'string') {
            return ''
          }
        }

        filterObj = {
          ...filterObj,
          field: fieldId,
        }

        if (
          [comparators.TRUE, comparators.FALSE].includes(comparator) &&
          !comparison
        ) {
          filterObj = {
            value: comparator,
            type: comparator,
            field: fieldId,
          }

          return filterObj
        }

        const boundOptions = {}
        if (comparatorOptions) {
          for (const key of Object.keys(comparatorOptions)) {
            boundOptions[key] = getBindingValue(comparatorOptions[key], opts)
          }
        }

        const value = getBindingValue(comparison, opts)
        const value2 = getBindingValue(comparison2, opts)

        if (distanceComparators.includes(comparator)) {
          const { radius, unit } = boundOptions
          filterObj = {
            ...filterObj,
            options: {
              radiusKms: asKilometers(radius, unit),
            },
          }
        }

        if (value === undefined) {
          filterObj = {
            ...filterObj,
            value: '',
            type: comparator,
          }

          return filterObj
        }

        filterObj = {
          ...filterObj,
          value,
          type: comparator,
        }

        if (value2) {
          filterObj.value2 = value2
        }

        return filterObj
      })
      .filter(itm => itm)

    if (result.length) {
      formattedFilter.push(result)
    }
  }

  const uniqueFiltersSet = new Set(formattedFilter.map(JSON.stringify))
  formattedFilter = Array.from(uniqueFiltersSet).map(JSON.parse)

  if (!formattedFilter.length) {
    return ''
  }

  return ClassicBuffer(JSON.stringify(formattedFilter)).toString('base64')
}

const getAPIFilter = (queryParams, opts) => {
  if (!queryParams) {
    return ''
  }

  const result = {}

  queryParams.forEach(item => {
    const { name } = item

    if (!name) {
      return
    }

    const value = getBindingValue(item.value || '', opts)

    result[name] = value
  })

  return ClassicBuffer(JSON.stringify(result)).toString('base64')
}

export const indexInputs = (list, source) => {
  if (!source || typeof source !== 'object') {
    return
  }

  const isInput = source.type === sourceTypes.INPUT && source.objectId
  const isDropdown =
    source.selector && source.selector.type === selectorTypes.SELECT_VALUE

  if (isInput) {
    list.push(source.objectId)
  } else if (isDropdown) {
    list.push(source.selector.selectObjectId)
  } else if (Array.isArray(source)) {
    source.forEach(itm => indexInputs(list, itm))
  } else if (source.type === 'formula') {
    indexInputs(list, source.formula)
  } else if (source.type === 'binding') {
    indexInputs(list, source.source)
  } else if (source.source) {
    indexInputs(list, source.source)
  }
}

const forEachBinding = (filter, callback) => {
  if (!filter) {
    return
  }

  const { comparison, comparison2, comparatorOptions } = filter

  if (comparison && comparison.type) {
    callback(comparison)
  }

  if (comparison2 && comparison2.type) {
    callback(comparison2)
  }

  if (comparatorOptions) {
    for (const value of Object.values(comparatorOptions)) {
      if (value.type) {
        callback(value)
      }
    }
  }
}

export const getInputDependencies = component => {
  const { dataBindings } = component
  const inputIds = []

  for (const bindingId in dataBindings) {
    if (bindingId) {
      const binding = dataBindings[bindingId]
      let { source } = binding

      source = getDataSource(source)

      if (!source || !source.options) {
        continue
      }

      if (source.options.queryParams) {
        const { queryParams } = source.options

        queryParams.forEach(param => {
          if (param && param.value) {
            indexInputs(inputIds, param.value)
          }
        })
      }

      if (source.options.filter) {
        let { filter } = source.options

        if (!Array.isArray(filter)) {
          filter = [filter]
        }

        const isSimpleFilter = !Array.isArray(filter[0])

        if (isSimpleFilter) {
          filter = [filter]
        }

        for (const item of filter) {
          item.forEach(filter => {
            forEachBinding(filter, binding => {
              switch (binding.type) {
                case sourceTypes.INPUT:
                  inputIds.push(binding.objectId)
                  break
                case sourceTypes.FIELD:
                  if (binding.source.selector) {
                    inputIds.push(binding.source.selector.selectObjectId)
                  }
                  break
                default:
                  break
              }
            })
          })
        }
      }

      if (source.sort?.reference) {
        const { source: sortSource } = source.sort.reference
        indexInputs(inputIds, sortSource)
      }
    }
  }

  return uniqueElements(inputIds)
}
