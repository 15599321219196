/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  LABEL,
  SECTION,
  ELLIPSE,
  SHAPE,
  IMAGE,
  WRAPPER,
  GROUP,
  LIST,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  CHECKBOX,
  FORM,
  SELECT,
  ROW,
  COLUMN,
  ROW_SPACER,
  COLUMN_SPACER,
  WEB_VIEW,
  COMPONENT_INSTANCE,
  LIBRARY_COMPONENT,
  LINE,
  LOCATION_INPUT,
} from '@adalo/constants'

import { getPushId } from 'utils/responsive'
import { getDeviceType } from 'utils/device'
import { getObjectBinding } from '../utils/dependencies'
import { getVisible, getVisibleOnDevice } from '../utils/visibility'
import { normalizeAttributes } from '../utils/styles'

import Label from './Label'
import Section from './Section'
import Line from './Line'
import Ellipse from './Ellipse'
import Shape from './Shape'
import Image from './Image'
import Input from './Input'
import ImageUpload from './ImageUpload'
import FileUpload from './FileUpload'
import DatePicker from './DatePicker'
import Select from './Select'
import Checkbox from './Checkbox'
import Form from './Form'
import WebView from './WebView'
import Group from './Group'
import List, { WrappedListItem } from './List'
import Wrapper from './Wrapper'
import Row from './Row'
import Column from './Column'
import RowSpacer from './RowSpacer'
import ColumnSpacer from './ColumnSpacer'
import ComponentInstance from './ComponentInstance'
import LibraryComponent from './LibraryComponent'
import LocationInput from './LocationInput'

import { ResponsiveComponent } from './ResponsiveComponent'

export const mapping = {
  [LABEL]: Label,
  [SECTION]: Section,
  [LINE]: Line,
  [IMAGE]: Image,
  [ELLIPSE]: Ellipse,
  [SHAPE]: Shape,
  [GROUP]: Group,
  [WRAPPER]: Wrapper,
  [ROW]: Row,
  [COLUMN]: Column,
  [LIST]: List,
  [INPUT]: Input,
  [IMAGE_UPLOAD]: ImageUpload,
  [FILE_UPLOAD]: FileUpload,
  [DATE_PICKER]: DatePicker,
  [CHECKBOX]: Checkbox,
  [FORM]: Form,
  [SELECT]: Select,
  [ROW_SPACER]: RowSpacer,
  [COLUMN_SPACER]: ColumnSpacer,
  [WEB_VIEW]: WebView,
  [COMPONENT_INSTANCE]: ComponentInstance,
  [LIBRARY_COMPONENT]: LibraryComponent,
  [LOCATION_INPUT]: LocationInput,
  listItem: WrappedListItem,
}

export class ObjectRenderer extends Component {
  renderChildren = (children, item) => {
    const {
      active,
      component,
      topScreen,
      setScrollEnabled,
      scrollTo,
      object,
      listItemId,
      pushId: parentPushId,
      isResponsiveComponent,
    } = this.props

    if (children.filter(c => !c).length > 0) {
      console.log('--------> INFO:', object)
      throw new Error('ENCOUNTERED NULL CHILD!')
    }

    const itemId = item?.[object.id]?.id
    const listItemIdProp = listItemId || itemId

    return children.map(child => {
      const childId = child.id
      const pushId = getPushId(listItemId, childId, itemId, parentPushId)

      return (
        <WrappedObjectRenderer
          key={pushId}
          pushId={pushId}
          object={child}
          component={component}
          active={active}
          topScreen={topScreen}
          setScrollEnabled={setScrollEnabled}
          isResponsiveComponent={isResponsiveComponent}
          scrollTo={scrollTo}
          listItemId={listItemIdProp}
          hasGroupParent={object.type === GROUP}
        />
      )
    })
  }

  getChild = object => {
    const { component, app, isResponsiveComponent } = this.props

    if (isResponsiveComponent) {
      const {
        setHeight,
        pushId,
        visible,
        visibleOnDevice,
        setVisible,
        bindingData,
      } = this.props
      const ObjectClass = this.getObjectClass(object)
      const branding = app && app.branding
      return (
        <ResponsiveComponent
          branding={branding}
          ObjectClass={ObjectClass}
          object={object}
          component={component}
          getBaseProps={this.getBaseProps}
          renderChildren={this.renderChildren}
          pushId={pushId}
          visible={visible}
          visibleOnDevice={visibleOnDevice}
          setVisible={setVisible}
          setHeight={setHeight}
          bindingData={bindingData}
        />
      )
    }

    return this.getNonResponsiveChild()
  }

  getNonResponsiveChild = () => {
    const { object, visible } = this.props

    if (object.hidden || !visible) {
      return null
    }

    const ObjectClass = this.getObjectClass(object)
    const baseProps = this.getBaseProps(object)
    const { children } = object

    return (
      <ObjectClass {...baseProps}>
        {children ? this.renderChildren(children) : null}
      </ObjectClass>
    )
  }

  getObjectClass = object => {
    const ObjectClass = mapping[object.type]

    if (!ObjectClass) {
      console.warn(`I don't know how to render ${object.type}`)

      return null
    }

    return ObjectClass
  }

  getBaseProps = (object, extraProps = {}) => {
    const {
      app,
      active,
      topScreen,
      component,
      bindingData,
      parentBindingData,
      isReusableComponent,
      getBinding,
      getParams,
      getApp,
      getFileUploadsBaseURL,
      getImageUploadsBaseURL,
      onEnter,
      setUploading,
      formSubmitSuccess,
      setScrollEnabled,
      scrollTo,
      getFlags,
      isResponsiveComponent,
      listItemId,
      pushId,
    } = this.props

    const baseProps = {
      isResponsiveComponent,
      isReusableComponent,
      app,
      component,
      object,
      active,
      bindingData,
      parentBindingData,
      topScreen,
      getBinding,
      getParams,
      getApp,
      renderChildren: this.renderChildren,
      getFileUploadsBaseURL,
      getImageUploadsBaseURL,
      onEnter,
      formSubmitSuccess,
      setScrollEnabled,
      scrollTo,
      listItemId,
      getFlags,
      pushId,
      ...extraProps,
    }

    if (object.type === IMAGE_UPLOAD || object.type === FILE_UPLOAD) {
      baseProps.setUploading = setUploading
    }

    return baseProps
  }

  render() {
    const { app, object } = this.props

    // normalize all object attributes to their desired value
    if (app && app.branding) {
      object.attributes = normalizeAttributes(object.attributes, app.branding)
    }

    return this.getChild(object)
  }
}

const mapStateToProps = (state, props) => {
  const {
    object,
    getBinding,
    getBindingsList,
    getParams,
    getFileUploadsBaseURL,
    getImageUploadsBaseURL,
    getApp,
    listItemId,
    pushId: listPushId,
    app,
  } = props

  const { magicLayout } = app || {}

  const pushId = listPushId || object.id

  const bindingData = getObjectBinding(state, object, {
    getParams,
    getBinding,
    getBindingsList,
    getFileUploadsBaseURL,
    getImageUploadsBaseURL,
    getApp,
    listItemId,
  })

  const deviceType = magicLayout ? getDeviceType() : null

  const visible = getVisible(
    state,
    object,
    {
      getParams,
      getBinding,
      getBindingsList,
      getApp,
    },
    deviceType
  )

  const visibleOnDevice = magicLayout
    ? getVisibleOnDevice(object, deviceType)
    : true

  return {
    pushId,
    bindingData,
    visible,
    visibleOnDevice,
  }
}

const ConnectedObjectRenderer = connect(mapStateToProps)(ObjectRenderer)

export default class WrappedObjectRenderer extends Component {
  static contextTypes = {
    getBinding: PropTypes.func,
    getBindingsList: PropTypes.func,
    getParams: PropTypes.func,
    getApp: PropTypes.func,
    getFileUploadsBaseURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
    getFlags: PropTypes.func,

    setVisible: PropTypes.func,
    setHeight: PropTypes.func,
  }

  render() {
    const { getApp } = this.context
    const { object } = this.props

    const app = getApp()

    return (
      <ConnectedObjectRenderer
        {...this.props}
        {...this.context}
        app={app}
        object={object}
      />
    )
  }
}

export class MultipleObjectRenderer extends Component {
  render() {
    const { objects, ...props } = this.props

    return (
      <>
        {objects.map(obj => (
          <WrappedObjectRenderer {...props} key={obj.id} object={obj} />
        ))}
      </>
    )
  }
}
