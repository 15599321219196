/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ImagePicker } from 'utils/uploads'
import {
  cameraPermissionsMessages,
  errorUploadingImageMessages,
  getLocalizedText,
} from 'utils/languageLocale'
import { Alert } from 'utils/alerts'

import {
  uploadImage,
  changeValue,
  setDefaultValue,
  resetValue,
  getRawValue,
} from 'ducks/formInputs'

import BaseObject from '../BaseObject'

export default class BaseImageUpload extends BaseObject {
  static contextTypes = {
    getBaseURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.handlePress = this.handlePress.bind(this)
  }

  handlePress() {
    const { object, uploadImage, resetValue } = this.props

    ImagePicker.showImagePicker(
      { quality: 0.7, includeBase64: true },
      response => {
        if (response.errorCode) {
          console.error('error:', response)
          if (response.errorCode === 'permission') {
            const { title, body } = getLocalizedText(cameraPermissionsMessages)
            return Alert.alert(title, body)
          } else {
            return Alert.alert('Error', response.errorMessage)
          }
        }

        if (!response.uri) {
          return
        }

        const previewURI = response.uri
        const data = response.base64 || response.data
        let filename = ''

        if (response.fileName) {
          filename = response.fileName
        } else if (response.filename) {
          filename = response.filename
        } else {
          // eslint-disable-next-line prefer-destructuring
          filename = previewURI.split('/').slice(-1)[0]
        }

        resetValue(object.id)

        const baseURL = this.context.getBaseURL()

        uploadImage(baseURL, object.id, filename, data, previewURI).catch(
          err => {
            console.error('ERROR:', err)
            const { title, body } = getLocalizedText(
              errorUploadingImageMessages
            )
            Alert.alert(title, body)
            resetValue(object.id)
          }
        )
      }
    )
  }

  getImageURL() {
    const { value } = this.props
    let imageURL = null

    if (value && value.preview) {
      imageURL = value.preview
    } else if (value && typeof value === 'object') {
      imageURL = `${this.context.getImageUploadsBaseURL()}/${value.url}?orient`
    } else if (typeof value === 'string') {
      imageURL = `${this.context.getImageUploadsBaseURL()}/${value}?orient`
    }

    return imageURL
  }

  getPending() {
    const { value } = this.props

    return value && value._pending
  }
}

export const connectImageUpload = component => {
  const mapStateToProps = (state, { object }) => ({
    value: getRawValue(state, object.id),
  })

  const ConnectedImageUpload = connect(mapStateToProps, {
    uploadImage,
    changeValue,
    setDefaultValue,
    resetValue,
  })(component)

  class WrappedImageUpload extends Component {
    static contextTypes = {
      getBindingsList: PropTypes.func,
    }

    render() {
      const { getBindingsList } = this.context

      return (
        <ConnectedImageUpload
          {...this.props}
          getBindingsList={getBindingsList}
        />
      )
    }
  }

  return WrappedImageUpload
}
