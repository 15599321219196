import React, { ReactElement } from 'react'
import { responsivePositioningOptions } from '@adalo/constants'
import { connect } from 'react-redux'
import { View, StyleSheet, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { RunnerObject, Screen } from 'types'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { getNodeHeight, RootPushTreeState } from 'ducks/pushTreeMap'
import { IApp } from 'interfaces'

const { FIXED_ON_SCROLL } = responsivePositioningOptions

interface Props {
  children: ReactElement[]
  isResponsiveComponent: boolean
  object: RunnerObject
  screenHeight: number
  component: Screen
  app: IApp
}

interface Context {
  getOffsetTop: () => number
}

const Wrapper = (
  {
    children,
    isResponsiveComponent,
    object: { layout: _layout, responsivity },
    screenHeight,
    component,
    app,
  }: Props,
  { getOffsetTop }: Context
): ReactElement => {
  const layout = { ..._layout }

  const { height: viewportHeight } = useWindowDimensions()

  if (isResponsiveComponent) {
    layout.height = screenHeight

    if (responsivity?.verticalPositioning === FIXED_ON_SCROLL) {
      layout.height = viewportHeight - getOffsetTop()
    }

    // TODO(dyego): instead of applying this patch we should aim to fix the core issue which is to
    // keep everything inside the screen for both platforms.
    //
    // Overview: This patch applies two fixes per native platform
    // iOS: reduces the height to avoid a situation where elements are getting cutoff from the screen
    // Android: reduces the height ONLY when the status bar is hidden, when that happens the entire view gets pushed down a bit so this adjustment aims to fix that
    const isWebPreview =
      app?.webSettings?.previewType === 'mobile' &&
      Platform.OS === 'web' &&
      window.innerWidth === 375 &&
      window.outerWidth >= 890

    if (isWebPreview) {
      layout.height -= 75
    } else if (Platform.OS === 'ios') {
      layout.height -= 75
    } else if (
      Platform.OS === 'android' &&
      component.statusBarStyle === 'hidden'
    ) {
      layout.height -= 30
    }
  }

  return (
    <View style={[styles.wrapper, layout]} pointerEvents="box-none">
      {children}
    </View>
  )
}

Wrapper.contextTypes = {
  getOffsetTop: PropTypes.func,
}

const styles = StyleSheet.create({
  wrapper: {},
})

interface ConnectProps {
  isResponsiveComponent: boolean
  pushId: string
  component: Screen
}

const mapStateToProps = (
  state: RootPushTreeState,
  { component, isResponsiveComponent, pushId }: ConnectProps
): {
  screenHeight: number
} => {
  const screenHeight = isResponsiveComponent
    ? getNodeHeight(state, component.id, pushId)
    : 0

  return { screenHeight }
}

export default connect(mapStateToProps)(Wrapper)
