import React from 'react'
import {
  View,
  Text,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
} from 'react-native'
import PropTypes from 'prop-types'

import {
  choosePhotoMessages,
  changePhotoMessages,
  getLocalizedText,
} from 'utils/languageLocale'

import BaseImageUpload, { connectImageUpload } from './BaseImageUpload'

class FullWidthUpload extends BaseImageUpload {
  static contextTypes = {
    getBaseURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
    getApp: PropTypes.func,
  }

  getBranding() {
    const { getApp } = this.context
    const app = getApp() || {}

    return app.branding
  }

  renderImage() {
    const pending = this.getPending()
    const imageURL = this.getImageURL()

    const { object } = this.props
    const { height } = object.attributes

    const textStyles = {
      ...this.textStyles(),
    }

    const innerWrapperStyles = {
      ...this.borderStyles(),
      ...this.shadowStyles(),
      ...this.backgroundStyles(),
      borderRadius: this.getBorderRadius(),
    }

    if (imageURL) {
      return (
        <View>
          <View style={[styles.innerWrapper, innerWrapperStyles, { height }]}>
            <TouchableOpacity onPress={this.handlePress} style={{ flex: 1 }}>
              <View style={styles.preview}>
                <Image
                  style={[styles.previewImage, pending ? styles.faded : {}]}
                  source={{ uri: imageURL }}
                  resizeMode="contain"
                />
                {pending ? <ActivityIndicator color="#999999" /> : null}
              </View>
            </TouchableOpacity>
          </View>
          {pending ? null : (
            <TouchableOpacity
              onPress={this.handlePress}
              style={styles.selectAnother}
            >
              <Text style={[styles.selectImage, textStyles]}>
                {getLocalizedText(changePhotoMessages).body}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )
    }

    return (
      <View style={[styles.innerWrapper, innerWrapperStyles, { height }]}>
        <TouchableOpacity
          style={styles.emptyWrapper}
          onPress={this.handlePress}
        >
          <Text style={[styles.selectImage, textStyles]}>
            {getLocalizedText(choosePhotoMessages).body}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  componentDidUpdate(prevProps) {
    const { value, setUploading } = this.props

    if (prevProps.value !== value) {
      if (
        (prevProps && prevProps.value && prevProps.value._pending) !==
        (this.props.value && value && value._pending)
      ) {
        if (setUploading) setUploading(!!value && value._pending)
      }
    }
  }

  render() {
    const {
      object: { layout },
    } = this.props

    return <View style={[styles.wrapper, layout]}>{this.renderImage()}</View>
  }
}

const styles = StyleSheet.create({
  wrapper: {},
  innerWrapper: {
    overflow: 'hidden',
  },
  preview: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#eee',
  },
  previewImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  faded: {
    opacity: 0.5,
  },
  icon: {
    opacity: 0.2,
    width: 40,
    height: 30,
  },
  emptyWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectImage: {
    color: '#06f',
    fontWeight: '500',
    fontSize: 14,
  },
  selectAnother: {
    marginTop: 8,
    alignItems: 'center',
  },
})

export default connectImageUpload(FullWidthUpload)
