/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { View, Keyboard, Dimensions } from 'react-native'
import {
  actionTypes,
  dataTypes,
  selectors,
  sourceTypes,
} from '@adalo/constants'

// ducks
import {
  errorUploadingFileMessages,
  errorUploadingImageMessages,
  getLocalizedText,
} from 'utils/languageLocale'
import { getDeviceType } from 'utils/device'
import { getItemById } from '../ducks/data'
import { changeValue, uploadFile, resetValue } from '../ducks/formInputs'
import MissingComponent from './MissingComponent'
import { unsafeGetToken } from '../ducks/auth'

// utils
import { executeAction, hasLink, actionContextTypes } from '../utils/actions'
import { deepGet, deepSet } from '../utils/objects'
import { getUserValue, setUserValue } from '../utils/user-datastore'
import { solveBindings, hasRole } from '../utils/library-binding'
import { oauth } from '../utils/oauth'
import { normalizeStyles } from '../utils/styles'
import { normalizeFontFamily } from '../utils/type'
import { compareFormValues } from '../utils/formValues'

import { getData } from '../utils/filedata'
import { Alert } from '../utils/alerts'

import { connectInput } from './Input'

class LibraryComponent extends Component {
  constructor(constructorProps) {
    super(constructorProps)

    this.setupState()
  }

  static contextTypes = {
    ...actionContextTypes,
    getLibraryComponent: PropTypes.func,
    isPreviewer: PropTypes.bool,
    getLayoutGuides: PropTypes.func,
  }

  componentWillUnmount() {
    const { object, dispatch } = this.props

    const manifest = this.getManifest()

    const formValues = manifest.props.filter(prop => prop.role === 'formValue')
    const childComponents = manifest.childComponents || []

    formValues.forEach(prop => {
      if (prop.role === 'formValue') {
        dispatch(resetValue(`${object.id}.${prop.name}`))
      }
    })

    childComponents.forEach(child => {
      child.props.forEach(prop => {
        if (prop.role === 'formValue') {
          dispatch(resetValue(`${object.id}.${child.name}.${prop.name}`))
        }
      })
    })
  }

  setupState = () => {
    const manifest = this.getManifest()

    if (manifest) {
      const props = manifest.props || []
      const childComponents = manifest.childComponents || []

      const { componentProps } = this.props

      const stateObject = { props: {}, childComponents: {} }

      props.forEach(prop => {
        if (hasRole(prop, 'formValue')) {
          stateObject.props = {
            ...stateObject.props,
            [prop.name]: {
              lastInitial: componentProps[prop.name]
                ? componentProps[prop.name].initial
                : undefined,
            },
          }
        }
      })
      childComponents.forEach(child => {
        stateObject.childComponents = {
          ...stateObject.childComponents,
          [child.name]: {},
        }

        child.props.forEach(prop => {
          if (prop.role === 'formValue') {
            stateObject.childComponents[child.name] = {
              ...stateObject.childComponents[child.name],
              [prop.name]: {
                lastInitial:
                  componentProps[child.name] &&
                  componentProps[child.name][prop.name]
                    ? componentProps[child.name][prop.name].initial
                    : undefined,
              },
            }
          }
        })
      })

      this.state = stateObject
    }
  }

  getUserValue = key => {
    const { object } = this.props
    const { libraryName } = object.attributes
    const { getBaseURL, getApp } = this.context
    const baseURL = getBaseURL()

    return getUserValue(getApp(), baseURL, libraryName, key)
  }

  setUserValue = (key, value) => {
    const { object } = this.props
    const { libraryName } = object.attributes
    const { getBaseURL, getApp } = this.context
    const baseURL = getBaseURL()

    return setUserValue(getApp(), baseURL, libraryName, key, value)
  }

  getComponentAuthToken = () => {
    const { getApp } = this.context

    return unsafeGetToken(null, getApp())
  }

  handleAction =
    (action, key, itm, i) =>
    async (...args) => {
      const value = args[0]
      const { getBindings, getBinding, getStore, setDirty } = this.context
      const bindings = {}

      if (itm) {
        const { datasourceId, tableId, collectionId } = itm._meta
        const key = `${datasourceId}.${tableId || collectionId}`

        bindings[key] = itm.id
      }

      let { object, dispatch, bindingValues } = this.props
      const { actions } = object

      if (key) {
        // this changes the value of the text input
        const inputId = `${object.id}.${key.join('.')}`
        dispatch(changeValue(inputId, value))
        await Promise.resolve()
      }

      if (!action) {
        return
      }

      const { actionId } = action
      bindingValues = bindingValues || {}

      if (!action || !actions || !actions[actionId]) {
        return
      }

      const localGetBindings = () => ({ ...getBindings(), ...bindings })

      const state = getStore().getState()

      const localGetBinding = listId => {
        if (listId in bindingValues) {
          const dep = bindingValues[listId] && bindingValues[listId][i]
          return getItemById(state, dep._meta.tableId, dep.id)
        }

        return getBinding(listId)
      }

      const localGetActionArguments = index => {
        return args[index]
      }
      await executeAction(actions[actionId], {
        ...this.context,
        dispatch,
        getBinding: localGetBinding,
        getBindings: localGetBindings,
        getActionArguments: localGetActionArguments,
      })

      if (hasLink(actions[actionId])) {
        Keyboard.dismiss()
      }

      setDirty()
    }

  handleAutosaveChange =
    (propName, childComponent = null, opts = {}) =>
    async newValue => {
      const {
        object: { attributes },
        dispatch,
      } = this.props

      const { setDirty } = this.context

      const manifest = this.getManifest()

      let prop
      let propValue

      if (childComponent) {
        const child = manifest.childComponents.find(
          itm => itm.name === childComponent
        )
        prop = child.props.find(itm => itm.name === propName)
        propValue = attributes[childComponent][propName]
      } else {
        prop = manifest.props.find(itm => itm.name === propName)
        propValue = attributes[propName]
      }

      let action

      if (!propValue) {
        return
      }

      // TODO: if it's a collection, do something different
      if (
        prop.type === dataTypes.BOOLEAN &&
        prop.role === 'autosaveInput' &&
        propValue.source &&
        propValue.source.type === sourceTypes.AUTOSAVE
      ) {
        const actionType = newValue
          ? actionTypes.CREATE_ASSOCIATION
          : actionTypes.DELETE_ASSOCIATION

        const { source } = propValue

        if (!source) {
          return
        }

        action = {
          id: 'syntheticAction',
          actionType,
          options: {
            fieldId: source.source.fieldId,
            object1: source.source.source,
            object2: source.value,
            ...opts,
          },
        }
      } else {
        const fieldId = propValue.source && propValue.source.fieldId
        let source = propValue.source && propValue.source.source

        const parentSource = source?.source || {}
        const isRelationship =
          parentSource.dataType === dataTypes.OBJECT &&
          parentSource.type === sourceTypes.BELONGS_TO

        const referencesCurrentUser =
          parentSource.selector?.type === selectors.CURRENT_USER

        const isComponentLoggedInUserRelationship =
          isRelationship &&
          referencesCurrentUser &&
          parentSource?.source?.type === sourceTypes.DATA

        if (isComponentLoggedInUserRelationship) {
          source = parentSource
        }

        if (!source || !fieldId) {
          console.warn('ERROR: Could not update autosave value')
          return
        }

        action = {
          id: 'syntheticAction',
          actionType: actionTypes.UPDATE_OBJECT,
          options: {
            bindingId: source,
            fields: [{ fieldId, source: newValue }],
            ...opts,
          },
        }
      }

      await executeAction({ actions: [action] }, { ...this.context, dispatch })
      setDirty()
    }

  handleFileUpload =
    (propName, childComponent = null, propType = PropTypes.FILE, opts = {}) =>
    async newValue => {
      const { getBaseURL } = this.context
      const { setDirty } = this.context
      const {
        object: { attributes },
        dispatch,
      } = this.props
      const { object, uploadFile } = this.props
      const manifest = this.getManifest()
      const baseURL = getBaseURL()
      const { uri } = newValue
      let { data, filename } = newValue
      let propValue

      if (childComponent) {
        manifest.childComponents.find(itm => itm.name === childComponent)
        propValue = attributes[childComponent][propName]
      } else {
        propValue = attributes[propName]
      }

      let action

      if (!propValue) {
        return
      }

      try {
        const fileInfo = await getData(filename, data, uri)
        filename = fileInfo.filename
        data = fileInfo.data
        const res = await uploadFile(baseURL, object.id, filename, data)
        const fileURL = res.value.request.data.url
        const fileSize = res.value.request.data.size
        const fieldId = propValue?.source.fieldId
        const source = propValue?.source.source

        if (!source || !fieldId) {
          console.warn('ERROR: Could not update file')
          return
        }

        action = {
          id: 'syntheticAction',
          actionType: actionTypes.UPDATE_OBJECT,
          options: {
            bindingId: source,
            fields: [
              { fieldId, source: fileURL, fileName: filename, size: fileSize },
            ],
            ...opts,
          },
        }

        await executeAction(
          { actions: [action] },
          { ...this.context, dispatch }
        )
        setDirty()
      } catch (err) {
        const { title, body } = getLocalizedText(
          propType === PropTypes.FILE
            ? errorUploadingFileMessages
            : errorUploadingImageMessages
        )
        Alert.alert(title, body)
        console.error('ERROR UPLOADING FILE:', err)
      }
    }

  setAutoSaveOnChange = (prop, childComponent = null, opts = {}) => {
    if (prop.type === 'file' || prop.type === 'image') {
      return this.handleFileUpload(
        prop.name,
        childComponent && childComponent.name,
        prop.type,
        opts
      )
    } else {
      return this.handleAutosaveChange(
        prop.name,
        childComponent && childComponent.name,
        opts
      )
    }
  }

  handleFormValueChange =
    (propName, childComponent, propType) => async newValue => {
      const { object, dispatch, uploadFile } = this.props
      const inputId = childComponent
        ? `${object.id}.${childComponent}.${propName}`
        : `${object.id}.${propName}`

      const { getBaseURL } = this.context
      const baseURL = getBaseURL()

      if (propType === dataTypes.FILE || propType === dataTypes.IMAGE) {
        try {
          const { uri } = newValue
          let { data, filename } = newValue

          const fileInfo = await getData(filename, data, uri)

          filename = fileInfo.filename
          data = fileInfo.data

          const uploadRes = await uploadFile(baseURL, object.id, filename, data)
          const newURL = uploadRes.value.request.data.url
          const newSize = uploadRes.value.request.data.size

          newValue.uri = newURL
          newValue.key = newURL
          newValue.size = newSize

          await dispatch(changeValue(inputId, newValue))
        } catch (e) {
          const { title, body } = getLocalizedText(
            propType === dataTypes.FILE
              ? errorUploadingFileMessages
              : errorUploadingImageMessages
          )
          Alert.alert(title, body)
          console.warn('error uploading file: ', e)
        }
      } else {
        await dispatch(changeValue(inputId, newValue))
      }
    }

  getManifest = () => {
    const { app, object } = this.props

    const {
      attributes: { libraryName, componentName },
    } = object

    if (app?.libraryComponentManifests?.[libraryName]?.[componentName]) {
      return app.libraryComponentManifests[libraryName][componentName]
    } else if (object.libraryComponentManifest) {
      return object.libraryComponentManifest
    } else {
      console.error(
        'Manifest not found for component',
        componentName,
        'from library',
        libraryName
      )
      return null
    }
  }

  checkAuth = manifest => {
    if (manifest.auth) {
      const {
        auth: { config, mapping },
      } = manifest
      const { dispatch } = this.props

      const auth = async (
        token,
        clientId,
        hostedDomain = null,
        user = null
      ) => {
        const helpers = { dispatch, ...this.context }
        const oAuthConfig = {
          token,
          clientId,
          hostedDomain,
          ...config,
          mapping,
          user,
        }
        try {
          const retcode = await oauth(oAuthConfig, helpers)
          return retcode
        } catch (error) {
          console.log('oauth error: ', error)
        }
      }
      return auth
    }
    return null
  }

  getAttributes = () => {
    const { object, componentProps, getApp } = this.props
    const { actionRefs, attributes } = object
    let result = componentProps
    const manifest = this.getManifest()
    if (!manifest) return {}
    const props = manifest.props || []
    const childComponents = manifest.childComponents || []
    const propMap = {}

    const { branding } = getApp() || {}

    const auth = this.checkAuth(manifest)
    if (auth) {
      result.oauth = auth
    }

    const autosaves = manifest.props.filter(prop =>
      hasRole(prop, 'autosaveInput')
    )
    const formValues = manifest.props.filter(prop => hasRole(prop, 'formValue'))

    props.forEach(prop => {
      propMap[prop.name] = prop

      if (
        prop.type === 'image' &&
        !hasRole(prop, 'formValue') &&
        !hasRole(prop, 'autosaveInput')
      ) {
        const value = result[prop.name]

        result[prop.name] = value
      }

      if (prop.styles) {
        result = deepSet(
          result,
          ['styles', prop.name],
          normalizeStyles(prop, attributes, branding)
        )
      }
    })

    childComponents.forEach(child => {
      propMap[child.name] = child
      child.props.forEach(prop => {
        propMap[child.name][prop.name] = prop

        // custom fonts
        if (prop.styles) {
          result = deepSet(
            result,
            [child.name, 'styles', prop.name],
            normalizeStyles(prop, attributes[child.name], branding)
          )
        }

        // Autosave Inputs for Child Component Props
        if (hasRole(prop, 'autosaveInput')) {
          if (hasRole(child, 'listItem')) {
            const { reference } = child
            result[reference] =
              Array.isArray(result[reference]) &&
              result[reference].map(itm => {
                const value = itm[child.name][prop.name]
                const { datasourceId, tableId } = itm._meta
                const onChange = this.setAutoSaveOnChange(prop, child, {
                  objectId: itm.id,
                  datasourceId,
                  tableId,
                })

                if (prop.type === 'file') {
                  itm[child.name][prop.name] = { ...value, onChange }
                } else {
                  itm[child.name][prop.name] = { value, onChange }
                }
                return itm
              })
          } else {
            const propName = prop.name
            const value = result[child.name][propName]
            const onChange = this.setAutoSaveOnChange(prop, child)

            if (prop.type === 'file') {
              result[child.name][propName] = { ...value, onChange }
            } else {
              result[child.name][propName] = { value, onChange }
            }
          }
        }

        // formValues for child components
        if (hasRole(prop, 'formValue') && !hasRole(child, 'listItem')) {
          // Doesn't currently work with the list item role
          const propName = prop.name
          let initial = result[child.name][propName]
            ? result[child.name][propName].initial
            : undefined
          let value = result[child.name][propName]
            ? result[child.name][propName].value
            : undefined
          const onChange = this.handleFormValueChange(
            propName,
            child.name,
            prop.type
          )

          if (prop.type === 'file' || prop.type === 'image') {
            if (value && value.value) {
              value = value.value
            } else if (initial && initial.value) {
              initial = initial.value
            }
          }

          if (result[child.name] && result[child.name][propName]) {
            result[child.name][propName] = { value, onChange, initial }
          }
        }

        if (
          prop.type === 'image' &&
          !hasRole(prop, 'autosaveInput') &&
          !hasRole(prop, 'formValue')
        ) {
          if (result[child.name] && result[child.name][prop.name]) {
            const value = result[child.name][prop.name]

            result[child.name][prop.name] = value
          }
        }
      })
    })

    actionRefs.forEach(actionRef => {
      const key = actionRef.split('.')
      const setterKey = key.slice(1)

      const action = deepGet(object, key)

      let refKey

      const parentKey = key[1]
      const parent = propMap[parentKey]

      if (parent && hasRole(parent, 'listItem')) {
        let listData = result[parent.reference]

        if (!listData) {
          return
        }

        listData = listData.map((itm, i) => {
          return deepSet(
            itm,
            setterKey,
            this.handleAction(action, refKey, itm, i)
          )
        })

        result = { ...result, [parent.reference]: listData }
      } else {
        result = deepSet(result, setterKey, this.handleAction(action, refKey))
      }
    })

    autosaves.forEach(prop => {
      const key = prop.name
      const value = result[key]

      if (hasRole(prop, 'listItem')) {
        const { reference } = prop
        result[reference] =
          result[reference] &&
          result[reference].map(itm => {
            const value = itm[prop.name]
            const { datasourceId, tableId } = itm._meta
            const onChange = this.setAutoSaveOnChange(prop, null, {
              objectId: itm.id,
              datasourceId,
              tableId,
            })

            if (prop.type === 'file') {
              itm[prop.name] = { ...value, onChange }
            } else {
              itm[prop.name] = { value, onChange }
            }

            return itm
          })
      } else {
        const onChange = this.setAutoSaveOnChange(prop)

        if (prop.type === 'file') {
          result[key] = { ...value, onChange }
        } else {
          result[key] = { value, onChange }
        }
      }

      return result
    })

    formValues.forEach(prop => {
      const key = prop.name
      const onChange = this.handleFormValueChange(key, null, prop.type)
      let initial = result[key] ? result[key].initial : undefined
      let value = result[key] ? result[key].value : undefined

      if (prop.type === 'file' || prop.type === 'image') {
        if (value?.value) {
          value = value.value
        } else if (initial?.value) {
          initial = initial.value
        }
      }

      result[key] = { value, onChange, initial }
    })

    return result
  }

  checkFormValues = (mount = false) => {
    const { object, componentProps, dispatch } = this.props

    const manifest = this.getManifest()
    if (!manifest) return
    const props = manifest.props || []
    const childComponents = manifest.childComponents || []

    props.forEach(prop => {
      if (hasRole(prop, 'formValue')) {
        const {
          props: {
            [prop.name]: { lastInitial },
          },
        } = this.state

        if (
          (componentProps[prop.name] &&
            componentProps[prop.name].value === undefined &&
            componentProps[prop.name].initial !== undefined) ||
          (mount &&
            componentProps[prop.name] &&
            componentProps[prop.name].initial) ||
          (componentProps[prop.name] &&
            !compareFormValues(lastInitial, componentProps[prop.name].initial))
        ) {
          if (
            compareFormValues(componentProps[prop.name].value, lastInitial) ||
            mount
          ) {
            dispatch(
              changeValue(
                `${object.id}.${prop.name}`,
                componentProps[prop.name].initial || ''
              )
            )
          } else if (componentProps[prop.name].value === undefined) {
            dispatch(changeValue(`${object.id}.${prop.name}`, ''))
          }

          this.setState(state => {
            return {
              ...state,
              props: {
                ...state.props,
                [prop.name]: {
                  lastInitial: componentProps[prop.name].initial,
                },
              },
            }
          })
        }
      }
    })

    childComponents.forEach(child => {
      child.props.forEach(prop => {
        if (hasRole(prop, 'formValue')) {
          const {
            childComponents: {
              [child.name]: {
                [prop.name]: { lastInitial },
              },
            },
          } = this.state

          if (
            (componentProps[child.name] &&
              componentProps[child.name][prop.name] &&
              componentProps[child.name][prop.name].value === undefined &&
              componentProps[child.name][prop.name].initial !== undefined) ||
            (mount &&
              componentProps[child.name] &&
              componentProps[child.name][prop.name] &&
              componentProps[child.name][prop.name].initial) ||
            (componentProps[child.name] &&
              componentProps[child.name][prop.name] &&
              !compareFormValues(
                lastInitial,
                componentProps[child.name][prop.name].initial
              ))
          ) {
            if (
              compareFormValues(
                componentProps[child.name][prop.name].value,
                lastInitial
              ) ||
              mount
            ) {
              dispatch(
                changeValue(
                  `${object.id}.${child.name}.${prop.name}`,
                  componentProps[child.name][prop.name].initial || ''
                )
              )
            } else if (
              componentProps[child.name][prop.name].value === undefined
            ) {
              dispatch(
                changeValue(`${object.id}.${child.name}.${prop.name}`, '')
              )
            }

            this.setState(state => {
              return {
                ...state,
                childComponents: {
                  ...state.childComponents,
                  [child.name]: {
                    ...state.childComponents[child.name],
                    [prop.name]: {
                      lastInitial:
                        componentProps[child.name][prop.name].initial,
                    },
                  },
                },
              }
            })
          }
        }
      })
    })
  }

  getLayout = () => {
    const { object } = this.props
    const { layout } = object
    const manifest = this.getManifest()
    if (!manifest) return {}

    let height

    if (manifest.resizeY) {
      height = object.attributes.height
    }

    return {
      height,
      ...layout,
    }
  }

  getDimensionsAndDeviceType = () => {
    const { app } = this.props
    const { getLayoutGuides, isPreviewer } = this.context
    const { width, height } = Dimensions.get('window')

    const shouldAdjustPreviewHeight =
      isPreviewer &&
      ['web', 'responsive'].includes(app?.webSettings?.previewType || '')

    const layoutGuides = getLayoutGuides()

    return {
      _deviceType: getDeviceType(),
      _screenHeight: shouldAdjustPreviewHeight ? height - 64 : height,
      _screenWidth: width,
      _layoutGuides: layoutGuides,
    }
  }

  getNormalizedBrandingFonts = () => {
    const { app = {} } = this.props
    const { branding = {} } = app

    return {
      body: normalizeFontFamily('@body', branding),
      heading: normalizeFontFamily('@heading', branding),
    }
  }

  componentDidMount() {
    if (this.getManifest()) {
      this.checkFormValues(true)
    }
  }

  componentDidUpdate() {
    if (this.getManifest()) {
      this.checkFormValues(false)
    }
  }

  render() {
    const {
      object,
      active,
      topScreen,
      setScrollEnabled,
      getFlags,
      isResponsiveComponent,
    } = this.props

    const { libraryName, componentName } = object.attributes
    const { getLibraryComponent, getApp, isPreviewer } = this.context
    const authToken =
      libraryName === '@adalo/stripe-kit' ? this.getComponentAuthToken() : null

    const ComponentClass = getLibraryComponent(libraryName, componentName)

    if (!ComponentClass) {
      return null
    }

    if (!this.getManifest()) {
      return <MissingComponent name={`${libraryName}.${componentName}`} />
    }

    const augmentedAttributes = this.getAttributes()

    const componentProps = {
      ...augmentedAttributes,
      appId: getApp().id,
      active,
      authToken,
      topScreen: !!topScreen,
      _fonts: this.getNormalizedBrandingFonts(),
      _height: object.attributes.height,
      _width: object.attributes.width,
      _getUserValue: this.getUserValue,
      _setUserValue: this.setUserValue,
      _setScrollEnabled: setScrollEnabled,
      getFlags,
      isResponsiveComponent,
      isPreviewer,
      ...this.getDimensionsAndDeviceType(),
    }

    const layout = this.getLayout()

    return (
      <View style={[layout]} pointerEvents="box-none">
        <ComponentClass {...componentProps} />
      </View>
    )
  }
}

LibraryComponent.contextTypes = {
  ...actionContextTypes,
  getLibraryComponent: PropTypes.func,
  getLayoutGuides: PropTypes.func,
}
const mapStateToProps = (state, props) => {
  const {
    object,
    getApp,
    getBinding,
    getBindingsList,
    getParams,
    getAssetURL,
    getFileUploadsBaseURL,
    getImageUploadsBaseURL,
    listItemId,
  } = props

  object.attributes = { ...object.attributes, listItemId }

  const app = getApp && getApp()

  return solveBindings(app, object, {
    state,
    getApp,
    getBinding,
    getBindingsList,
    getParams,
    getAssetURL,
    getFileUploadsBaseURL,
    getImageUploadsBaseURL,
    listItemId,
  })
}

const ConnectedLibraryComponent = connect(mapStateToProps)(LibraryComponent)

class WrappedLibraryComponent extends Component {
  render() {
    return <ConnectedLibraryComponent {...this.props} {...this.context} />
  }
}

WrappedLibraryComponent.contextTypes = {
  getApp: PropTypes.func,
  getBinding: PropTypes.func,
  getBindingsList: PropTypes.func,
  getParams: PropTypes.func,
  getFileUploadsBaseURL: PropTypes.func,
  getImageUploadsBaseURL: PropTypes.func,
  getAssetURL: PropTypes.func,
}

export default connectInput(WrappedLibraryComponent, { uploadFile })
