import axios from 'axios'
import { dataTypes } from '@adalo/constants'
import { readBlobAsDataURLAsync } from './sources'

export const fileUpload = async (source, helpers) => {
  if (
    source.dataType === dataTypes.FILE ||
    source.dataType === dataTypes.IMAGE
  ) {
    const { getActionArguments, getBaseURL } = helpers
    const { argumentIndex } = source

    const actionArguments = getActionArguments(argumentIndex)
    const { uri } = actionArguments
    let { data, filename } = actionArguments

    const fileInfo = await getData(filename, data, uri)

    filename = fileInfo.filename
    data = fileInfo.data

    try {
      const baseURL = getBaseURL()
      const uploadRes = await axios.post(`${baseURL}/uploads`, {
        filename,
        data,
      })
      actionArguments.url = uploadRes.data.url
    } catch (e) {
      console.warn('error uploading file', e)
    }
  }
}

export const getData = async (filename, data, uri) => {
  const dataParts = data ? data.split(',') : []
  if (dataParts.length > 0) {
    const filenameParts = filename.split('.')
    if (filenameParts.length < 2) {
      const extension = dataParts[0].split(';')[0].split('/')[1]
      filename = `${filename}.${extension}`
    }
    ;[, data] = data.split(',')
  }

  if (!data) {
    try {
      const getDataRes = await fetch(uri)
      const blob = await getDataRes.blob()
      data = await readBlobAsDataURLAsync(blob)
      ;[, data] = data.split(',')
    } catch (e) {
      console.warn('error getting data', e)
    }
  }

  return { filename, data }
}
