import { Dimensions } from 'react-native'

import { visibility } from '@adalo/constants'

export const DEVICE_TYPE_MOBILE = 'mobile'
export const DEVICE_TYPE_TABLET = 'tablet'
export const DEVICE_TYPE_DESKTOP = 'desktop'

const getWidth = (): number => Dimensions.get('window').width

export const getDeviceType = (width: number = getWidth()): string => {
  if (width < visibility.MOBILE_BREAKPOINT) {
    return DEVICE_TYPE_MOBILE
  } else if (
    width >= visibility.MOBILE_BREAKPOINT &&
    width < visibility.TABLET_BREAKPOINT
  ) {
    return DEVICE_TYPE_TABLET
  } else {
    return DEVICE_TYPE_DESKTOP
  }
}
