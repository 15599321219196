export const sortById = (obj1, obj2) => {
  return obj2.id - obj1.id
}

export const values = (obj, sort = sortById) => {
  if (!obj) {
    return []
  }

  const objects = Object.keys(obj).map(key => obj[key])

  objects.sort(sort)

  return objects
}

// Only works for string-serializable elements
export const uniqueElements = arr => {
  const map = {}
  const result = []

  for (let i = 0; i < arr.length; i += 1) {
    const itm = arr[i]

    if (map[itm]) {
      continue
    }

    map[itm] = true
    result.push(itm)
  }

  return result
}

// Array.indexOf but with an accessor func
// Useful because of immutability
export const indexOf = (arr, accessor) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (accessor(arr[i])) {
      return i
    }
  }

  return -1
}
