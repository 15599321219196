import update from 'immutability-helper'
import { traverse } from './operations'
import { deepMerge } from './objects'
import saveQueue from './saveQueue'

export const setMeta = (obj, datasourceId, tableId, id) => {
  if (!datasourceId || !tableId) {
    return obj
  }

  obj = { ...obj }

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map(itm => setMeta(itm, datasourceId, itm._type))
    } else if (obj[key] && typeof obj[key] === 'object') {
      obj[key] = setMeta(obj[key], datasourceId, obj[key]._type)
    }
  }

  obj._meta = { datasourceId, tableId, id }

  return obj
}

export const formatObjectId = id => {
  if (typeof id === 'object') {
    return JSON.stringify(id)
  }

  return id
}

export const indexObjectSub = (
  tables,
  object,
  datasourceId,
  tableId,
  datasource
) => {
  let idField = 'id'
  let objectId = formatObjectId(object[idField])

  object[idField] = objectId

  if (datasource && datasource.type === 'api') {
    const collection = datasource.collections[tableId]

    if (collection.primaryKeyField) {
      idField = collection.primaryKeyField
    }

    objectId = object[idField]

    if (collection.primaryKeyType === 'url') {
      // eslint-disable-next-line prefer-destructuring
      objectId = objectId.split('/').slice(-1)[0]
    }
  }

  if (!tables[tableId]) {
    tables = update(tables, { [tableId]: { $set: {} } })
  }

  const oldObject = tables[tableId][objectId]

  // Bail if it's pending deletion on the frontend
  if (saveQueue.isPendingDelete(tableId, objectId)) {
    return tables
  }

  // Bail if the object has been modified on the frontend
  if (
    oldObject &&
    new Date(oldObject.updated_at) > new Date(object.updated_at)
  ) {
    return tables
  }

  tables = update(tables, {
    [tableId]: {
      [objectId]: {
        $set: deepMerge(
          oldObject,
          setMeta(object, datasourceId, tableId, objectId),
          itm => itm && itm[idField]
        ),
      },
    },
  })

  return tables
}

export const indexObject = (
  tables,
  object,
  datasourceId,
  tableId,
  datasource
) => {
  tables = indexObjectSub(tables, object, datasourceId, tableId, datasource)

  traverse(object, itm => {
    if (itm && itm.id && itm._type) {
      tables = indexObjectSub(tables, itm, datasourceId, itm._type, datasource)
    }
  })

  return tables
}
