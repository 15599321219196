import { comparators, dataTypes } from '@adalo/constants'
import { serializeDate } from './dates'
import { asKilometers, kilometersBetweenLocations } from './location'

export const empty = itm => {
  return [null, undefined, ''].includes(itm)
}

export const evaluateCondition = (
  value,
  comparator,
  dataType,
  comparison,
  comparison2,
  comparatorOptions = {}
) => {
  const equality = [comparators.EQUAL, comparators.NOT_EQUAL].includes(
    comparator
  )

  if (value === undefined && !equality) {
    return false
  }

  if ([comparators.TRUE, comparators.FALSE].includes(comparator)) {
    dataType = dataTypes.BOOLEAN
  }

  // Date
  if (dataType === dataTypes.DATE) {
    if (!empty(value)) {
      value = +new Date(value)
    }
    if (!empty(comparison)) {
      comparison = +new Date(comparison)
    }
    if (!empty(comparison2)) {
      comparison2 = +new Date(comparison2)
    }
  }

  // Date-only
  if (dataType === dataTypes.DATE_ONLY) {
    if (!empty(value)) {
      if (comparator !== comparators.BETWEEN) {
        value = value.slice(0, 10)
      } else {
        value = serializeDate(value)
      }
    }

    if (!empty(comparison)) {
      if (comparator !== comparators.BETWEEN) {
        comparison = comparison.slice(0, 10)
      } else {
        comparison = serializeDate(comparison)
      }
    }

    if (!empty(comparison2)) {
      if (comparator !== comparators.BETWEEN) {
        comparison2 = comparison2.slice(0, 10)
      } else {
        comparison2 = serializeDate(comparison2)
      }
    }
  }

  // Text
  if (dataType === dataTypes.TEXT) {
    if (!empty(value)) {
      value = String(value)
    }
    if (!empty(comparison)) {
      comparison = String(comparison)
    }
    if (!empty(comparison2)) {
      comparison2 = String(comparison2)
    }
  }

  // Numbers
  if (dataType === dataTypes.NUMBER) {
    if (typeof value === 'string') {
      value = value.trim() === '' ? undefined : Number(value)
    }

    if (typeof comparison === 'string') {
      comparison = comparison.trim() === '' ? undefined : Number(comparison)
    }

    if (typeof comparison2 === 'string') {
      comparison2 = comparison2.trim() === '' ? undefined : Number(comparison2)
    }
  }

  switch (comparator) {
    case comparators.EQUAL:
      if (empty(comparison)) {
        return !value
      }

      return value === comparison
    case comparators.NOT_EQUAL:
      if (empty(comparison)) {
        return !!value
      }

      return value !== comparison
    case comparators.GREATER_THAN:
      return value > comparison
    case comparators.LESS_THAN:
      return value < comparison
    case comparators.GREATER_THAN_OR_EQUAL:
      return value >= comparison
    case comparators.LESS_THAN_OR_EQUAL:
      return value <= comparison
    case comparators.BETWEEN:
      return value >= comparison && value < comparison2
    case comparators.DISTANCE_LESS_THAN: {
      if (
        empty(comparison) ||
        empty(comparatorOptions) ||
        empty(comparatorOptions.radius) ||
        empty(comparatorOptions.unit) ||
        typeof comparison !== 'object'
      ) {
        return true
      }

      if (empty(value)) {
        // Comparing distance between anything and an empty value is always false.
        return false
      }

      const radiusKms = asKilometers(
        comparatorOptions.radius,
        comparatorOptions.unit
      )
      const result =
        kilometersBetweenLocations(value.coordinates, comparison.coordinates) <
        radiusKms
      return result
    }
    case comparators.DISTANCE_GREATER_THAN: {
      if (
        empty(comparison) ||
        empty(comparatorOptions) ||
        empty(comparatorOptions.radius) ||
        empty(comparatorOptions.unit) ||
        typeof comparison !== 'object'
      ) {
        return true
      }

      if (empty(value)) {
        // Comparing distance between anything and an empty value is always false.
        return false
      }

      const radiusKms = asKilometers(
        comparatorOptions.radius,
        comparatorOptions.unit
      )
      const result =
        kilometersBetweenLocations(value.coordinates, comparison.coordinates) >
        radiusKms
      return result
    }
    case comparators.TRUE:
      return !!value
    case comparators.FALSE:
      return !value
    case comparators.EMPTY:
      return !value
    case comparators.NOT_EMPTY:
      return !!value
    case comparators.CONTAINS:
      return (
        String(value)
          .toLowerCase()
          .indexOf((comparison || '').toLowerCase().trim()) !== -1
      )
    case comparators.LIST_CONTAINS: {
      const listIds = (value || []).map(itm => itm.id || itm)
      const comparisonId = (comparison && comparison.id) || comparison

      return listIds.includes(comparisonId)
    }
    case comparators.LIST_NOT_CONTAINS: {
      const listIds = (value || []).map(itm => itm.id || itm)
      const comparisonId = (comparison && comparison.id) || comparison

      return !listIds.includes(comparisonId)
    }
  }

  return false
}
