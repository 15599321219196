/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { bindingTypes } from '@adalo/constants'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actionContextTypes } from '../../utils/actions'
import { evaluateBinding } from '../../utils/dependencies'
import ActionWrapper from '../ActionWrapper'
import BaseObject from '../BaseObject'
import LabelSub from './LabelSub'

class Label extends BaseObject {
  static contextTypes = {
    ...actionContextTypes,
  }

  render() {
    const { object, component, textBindingData, bindingData } = this.props
    const binding = object.dataBinding
    const { attributes } = object

    const { selectable } = attributes
    let text = textBindingData

    if (binding) {
      if (binding.bindingType === bindingTypes.SET_TEXT) {
        text = bindingData
      }

      if (!text && binding.includeDefault) {
        text = attributes.text
      }
    }

    const { maxLength } = attributes
    if (maxLength > 0 && text.length > maxLength) {
      text = `${text.substring(0, maxLength).trim()}...`
    }

    const wrapperStyles = {
      ...object.layout,
      opacity: attributes.opacity,
    }

    const textStyles = this.textStyles()

    const { fontSize } = textStyles

    textStyles.minHeight = Math.round(fontSize * 1.2)

    if (text && typeof text !== 'string') {
      text = String(text)
    }

    let numberOfLines

    if (attributes.multiline === false) {
      numberOfLines = 1
    }

    return (
      <ActionWrapper
        bindingData={bindingData}
        component={component}
        object={object}
        style={wrapperStyles}
      >
        <LabelSub
          selectable={selectable}
          numberOfLines={numberOfLines}
          style={textStyles}
        >
          {text}
        </LabelSub>
      </ActionWrapper>
    )
  }
}

const mapStateToProps = (
  state,
  {
    object,
    getBinding,
    getParams,
    getBindingsList,
    getApp,
    getFileUploadsBaseURL,
    getImageUploadsBaseURL,
    listItemId,
  }
) => ({
  textBindingData: evaluateBinding(state, object.attributes.text, {
    getParams,
    getBinding,
    getBindingsList,
    getApp,
    getFileUploadsBaseURL,
    getImageUploadsBaseURL,
    listItemId,
  }),
})

const ConnectedLabel = connect(mapStateToProps)(Label)

export default class LabelWrapper extends Component {
  static contextTypes = {
    getBinding: PropTypes.func,
    getBindingsList: PropTypes.func,
    getParams: PropTypes.func,
    getApp: PropTypes.func,
    getFileUploadsBaseURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
  }

  render() {
    const { getApp } = this.context
    const app = getApp()

    return <ConnectedLabel {...this.props} {...this.context} app={app} />
  }
}
