import React, { Component } from 'react'
import { FlatList, View, StyleSheet } from 'react-native'

export default class ListWrapper extends Component {
  static defaultProps = {
    numberOfColumns: 1,
  }

  listRef = list => {
    this._list = list
  }

  renderItem = ({ item }) => {
    const { renderItem } = this.props

    const renderedItem = !item || item.empty ? null : renderItem({ item })

    return (
      <View key={item.id} style={[styles.listItem]}>
        {renderedItem}
      </View>
    )
  }

  getColumns() {
    const { data, numberOfColumns } = this.props
    const columns = []

    for (let i = 0; i < data.length; i += numberOfColumns) {
      for (let j = 0; j < numberOfColumns; j += 1) {
        const pos = i + j

        if (!columns[j]) {
          columns[j] = []
        }

        if (data[pos]) {
          columns[j].push(data[pos])
        }
      }
    }

    return columns
  }

  getData() {
    let { data, numberOfColumns } = this.props

    if (data.length % numberOfColumns !== 0) {
      const padCount = numberOfColumns - (data.length % numberOfColumns)
      data = data.slice()

      for (let i = 0; i < padCount; i += 1) {
        data.push({ empty: true, key: `pad-${i}` })
      }
    }
    return data
  }

  keyExtractor = itm => {
    return String(itm.id)
  }

  render() {
    const { numberOfColumns, listKey, object, isResponsiveComponent } =
      this.props
    const columns = this.getColumns()
    const { rowMargin } = object.attributes

    const gutterWidth = rowMargin
    const gutterStyles = [styles.gutter, { width: gutterWidth }]

    // At the moment we're only applying gutters to responsive apps' lists,
    // Though legacy desktop apps aren't displaying the column spaces correctly.
    const canUseGutters =
      isResponsiveComponent && !isIncompatibleWithGutters(object)

    if (numberOfColumns === 1 || !object.attributes.masonry) {
      return (
        <FlatList
          ref={this.listRef}
          data={this.getData()}
          keyExtractor={this.keyExtractor}
          listKey={listKey}
          renderItem={this.renderItem}
          showsVerticalScrollIndicator={false}
          numColumns={numberOfColumns}
        />
      )
    } else {
      return (
        <View style={styles.wrapper}>
          {columns
            .map((column, index) => {
              const columnKey = `${object.id}__column__${index}`
              const isLastColumn = index === numberOfColumns - 1
              let gutter = null

              if (!isLastColumn && canUseGutters) {
                const gutterKey = `${object.id}__gutter__${index}`
                gutter = (
                  <View
                    key={gutterKey}
                    style={gutterStyles}
                    pointerEvents="box-none"
                  />
                )
              }

              return [
                <View key={columnKey} style={styles.column}>
                  {column.map(item => this.renderItem({ item }))}
                </View>,
                gutter,
              ]
            })
            .flat()
            .filter(Boolean)}
        </View>
      )
    }
  }
}

// Lists set to masonry off show some issues with gutters in runner, so we're falling back on
// the older way to calculate column spacing until we can fix it
const isIncompatibleWithGutters = obj => !obj?.attributes?.masonry

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  gutter: {
    flexDirection: 'column',
  },
  column: {
    flexDirection: 'column',
    flex: 1,
  },
  listItem: {
    flex: 1,
  },
})
