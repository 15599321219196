import axios from 'axios'
import { Platform } from 'react-native'
import qs from 'qs'

import { unsafeGetToken } from '../ducks/auth'

export const registerDevice = async (
  app,
  deviceId,
  baseURL,
  token,
  isPreviewer,
  unregister = false
) => {
  const appId = app.id
  const platform = Platform.OS
  const debug = process.env.NODE_ENV !== 'production'
  let url = `${baseURL}/devices`

  if (!deviceId) {
    return
  }

  if (unregister) {
    url = `${url}/unregister`
  }

  await axios.post(url, {
    token,
    deviceId,
    appId,
    platform,
    debug,
    isPreviewer,
  })
}

export const unregisterDevice = (
  app,
  deviceId,
  baseURL,
  token,
  isPreviewer
) => {
  return registerDevice(app, deviceId, baseURL, token, isPreviewer, true)
}

export const sendNotification = async (options, deps, helpers) => {
  const baseURL = helpers.getNotificationsURL()
  const { audience, title, body } = deps
  let { screenId } = options

  const app = helpers.getApp()
  let appId = app.id
  const token = unsafeGetToken(Object.keys(app.datasources)[0])
  let appName = app.name

  if (screenId && screenId.appId && screenId.appId) {
    appId = screenId.appId
    screenId = screenId.screenId

    const associatedApp = app.associatedApps.filter(a => a.id === appId)[0]

    if (associatedApp) {
      appName = associatedApp.name
    }
  }

  if (!audience || !audience.value || audience.value.length === 0) {
    return
  }

  const explicitParams = deps.params || {}
  const explicitParamValues = {}

  for (const param in explicitParams) {
    const val = explicitParams[param]
    explicitParamValues[param] = val && val.value
  }

  const params = {
    ...helpers.getParams(),
    ...(helpers.getBindings && helpers.getBindings()),
    ...helpers.createdObjects,
    ...explicitParamValues,
  }

  await axios.post(`${baseURL}/notifications`, {
    appId,
    token,
    appName,
    audience: audience.value,
    titleText: title.value,
    bodyText: body.value,
    payload: {
      route: { target: screenId, params },
    },
  })
}

export const parseURL = url => {
  const parts = (url || '').split('/')
  const path = parts[parts.length - 1]
  const queryIndex = path.indexOf('?')
  const search = path.substring(queryIndex + 1)

  const params = qs.parse(search)

  return params
}
