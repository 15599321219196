export const getUserLocale = () => {
  let locale

  if (window.navigator) {
    locale = window.navigator.userLanguage || window.navigator.language
  }

  return locale || 'en-US'
}

export const getUserLanguageCode = () => {
  const locale = getUserLocale()
  const [languageCode] = locale.split('-')

  return languageCode
}
