import { buildURL, apiURL } from '../utils/urls'
import { indexObject } from '../utils/metadata'
import { hashObject } from '../utils/hashing'
import { request } from '../utils/networking'
import { getEndpoint } from '../utils/apis'
import { identifyUser } from '../utils/analytics'
import saveQueue, { requiresValidation } from '../utils/saveQueue'
import { LOGOUT } from './auth'

const FETCH_COLLECTION = 'FETCH_COLLECTION'
const FETCH_OBJECT = 'FETCH_OBJECT'
const CREATE_OBJECT = 'CREATE_OBJECT'
const UPDATE_OBJECT = 'UPDATE_OBJECT'
const DELETE_OBJECT = 'DELETE_OBJECT'
const CREATE_ASSOCIATION = 'CREATE_ASSOCIATION'
const DELETE_ASSOCIATION = 'DELETE_ASSOCIATION'

const INITIAL_STATE = {
  collections: {},
  pagination: {},
  tables: {},
  errors: {},
}

// REDUCER

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action) => {
  if (
    action.type === `${FETCH_COLLECTION}_FULFILLED` ||
    action.type === `${FETCH_COLLECTION}_REJECTED`
  ) {
    let { data } = action.payload

    let {
      datasourceId,
      tableId,
      collectionId,
      bindingId,
      routeParams,
      offset,
      datasource,
      endpointId,
    } = action.meta

    let { tables, collections } = state

    const endpoint = getEndpoint(datasource, collectionId, endpointId)

    if (endpoint && endpoint.collectionPath) {
      data = data[endpoint.collectionPath] || []
    }

    if (!data) {
      data = []
    }

    if (!Array.isArray(data)) {
      data = [data]
    }

    const routeHash = hashObject(routeParams)

    data.forEach(itm => {
      tables = indexObject(
        tables,
        itm,
        datasourceId,
        tableId || collectionId,
        datasource
      )
    })

    if (!bindingId) {
      bindingId = []
    } else if (!Array.isArray(bindingId)) {
      bindingId = [bindingId]
    }

    let idField = 'id'
    let prepareId = id => id

    if (datasource && datasource.type === 'api') {
      const collection = datasource.collections[collectionId]

      if (collection && collection.primaryKeyField) {
        idField = collection.primaryKeyField
      }

      if (collection && collection.primaryKeyType === 'url') {
        prepareId = id => id.split('/').slice(-1)[0]
      }
    }

    for (let i = 0; i < bindingId.length; i += 1) {
      const collectionGroup = collections[bindingId[i]] || {}
      let ids = (collectionGroup[routeHash] || {}).ids || []
      ids = ids.slice(0, offset || 0)
      ids = ids.concat(data.map(itm => prepareId(itm[idField])))

      const collection = {
        tableId: tableId || collectionId,
        ids,
      }

      collections = {
        ...collections,
        [bindingId[i]]: {
          ...collections[bindingId[i]],
          [routeHash]: collection,
        },
      }
    }

    return {
      ...state,
      tables,
      collections,
    }
  }

  if (action.type === `${FETCH_OBJECT}_FULFILLED`) {
    let { tables } = state

    const {
      appId,
      datasourceId,
      tableId,
      collectionId,
      datasource,
      isCurrentUser,
    } = action.meta

    let item = action.payload.data
    const table = tables[tableId] || {}

    if (isCurrentUser) {
      identifyUser(appId, item)
    }

    item = {
      ...table[item.id],
      ...item,
    }

    tables = indexObject(
      tables,
      item,
      datasourceId,
      tableId || collectionId,
      datasource
    )

    const newState = {
      ...state,
      tables,
    }

    return newState
  }

  if (action.type === `${UPDATE_OBJECT}_PENDING`) {
    const { tableId, objectId, object, updatedAt } = action.meta

    if (requiresValidation(action.meta)) {
      return state
    }

    const newObject = {
      ...(state.tables[tableId] || {})[objectId],
      ...object,
      updated_at: updatedAt,
    }

    return {
      ...state,
      tables: {
        ...state.tables,
        [tableId]: {
          ...state.tables[tableId],
          [objectId]: newObject,
        },
      },
    }
  }

  if (
    action.type === `${UPDATE_OBJECT}_REJECTED` ||
    action.type === `${DELETE_OBJECT}_REJECTED`
  ) {
    const { payload: error, meta } = action

    if (error.response) {
      // Update + Delete - Restore original object
      console.error('FAILED WITH RESPONSE:', error.response)

      const { tableId, objectId, oldObject, updatedAt } = meta
      const object = getObject(state, tableId, objectId)

      if (object && +new Date(object.updated_at) === +new Date(updatedAt)) {
        return {
          ...state,
          tables: {
            ...state.tables,
            [tableId]: {
              ...state.tables[tableId],
              [objectId]: oldObject,
            },
          },
        }
      }
    } else if (action.type === `${UPDATE_OBJECT}_REJECTED`) {
      // Update Queue
      if (!requiresValidation(action.meta)) {
        console.log('QUEUEING UPDATE...', action.meta)
        saveQueue.push('update', action.meta)
      }
    } else {
      // Delete Queue
      saveQueue.push('delete', action.meta)
    }
  }

  if (
    action.type === `${CREATE_OBJECT}_FULFILLED` ||
    action.type === `${UPDATE_OBJECT}_FULFILLED`
  ) {
    const { datasourceId, tableId } = action.meta
    const { data } = action.payload

    return {
      ...state,
      tables: indexObject(state.tables, data, datasourceId, tableId),
    }
  }

  if (action.type === `${CREATE_OBJECT}_REJECTED`) {
    console.error('ERROR', action.payload, action.meta)
  }

  if (
    action.type === `${DELETE_OBJECT}_PENDING` ||
    action.type === `${DELETE_OBJECT}_FULFILLED`
  ) {
    const { tableId, objectId } = action.meta

    const table = { ...state.tables[tableId] }
    delete table[objectId]

    return {
      ...state,
      tables: {
        ...state.tables,
        [tableId]: table,
      },
    }
  }

  if (action.type === `${CREATE_ASSOCIATION}_FULFILLED`) {
    // Do something
    console.log('---------------> CREATED ASSOCIATION')
  }

  if (action.type === `${CREATE_ASSOCIATION}_REJECTED`) {
    // Do something
    console.log('---------------> FAILED TO CREATE ASSOCIATION')
  }

  if (action.type === `${DELETE_ASSOCIATION}_FULFILLED`) {
    // Do something
    console.log('---------------> DELETED ASSOCIATION')
  }

  if (action.type === `${DELETE_ASSOCIATION}_REJECTED`) {
    // Do something
    console.log('---------------> FAILED TO DELETE ASSOCIATION')
  }

  if (action.type === LOGOUT) {
    // Clear the data cache
    return INITIAL_STATE
  }

  return state
}

// ACTIONS

export const fetchCollection = (baseURL, opts) => {
  const { datasourceId, datasource } = opts

  const url =
    datasource && datasource.type === 'api'
      ? apiURL(opts)
      : buildURL(baseURL, opts)

  if (!url) {
    return
  }

  return {
    payload: request(datasourceId, url),
    type: FETCH_COLLECTION,
    meta: opts,
  }
}

export const fetchItem = (baseURL, opts) => {
  const { datasourceId, datasource } = opts

  const url =
    datasource && datasource.type === 'api'
      ? apiURL(opts)
      : buildURL(baseURL, opts)

  if (!url) {
    return
  }

  return {
    type: FETCH_OBJECT,
    payload: request(datasourceId, url),
    meta: opts,
  }
}

export const fetch = (baseURL, opts) => {
  const { id, collectionId, endpointId, datasource } = opts
  let isCollection = id === null

  const endpoint = getEndpoint(datasource, collectionId, endpointId)

  if (endpoint) {
    isCollection = endpoint.type === 'list' || endpoint.isList
  }

  if (isCollection) {
    return fetchCollection(baseURL, opts)
  } else {
    return fetchItem(baseURL, opts)
  }
}

export const createObject = (baseURL, opts) => {
  const { datasourceId, tableId, object } = opts
  return {
    type: CREATE_OBJECT,
    meta: { datasourceId, tableId },
    payload: request(datasourceId, buildURL(baseURL, opts), 'post', object),
  }
}

export const updateObject =
  (
    baseURL,
    datasourceId,
    tableId,
    objectId,
    object,
    datasource,
    getParams,
    state
  ) =>
  (dispatch, getState) =>
    dispatch({
      type: UPDATE_OBJECT,
      meta: {
        baseURL,
        datasourceId,
        tableId,
        objectId,
        object,
        oldObject: getItemById(getState(), tableId, objectId),
        updatedAt: new Date().toISOString(),
      },
      payload: request(
        datasourceId,
        buildURL(baseURL, {
          datasourceId,
          tableId,
          id: objectId,
          getParams,
          datasource,
          state,
        }),
        'put',
        object
      ),
    })

export const deleteObject = (baseURL, opts) => {
  const { datasourceId, tableId, objectId } = opts

  return {
    type: DELETE_OBJECT,
    meta: {
      baseURL,
      datasourceId,
      tableId,
      objectId,
    },
    payload: request(
      datasourceId,
      buildURL(baseURL, {
        ...opts,
        id: objectId,
        objectId: null,
      }),
      'delete'
    ),
  }
}

export const createAssociation = opts => {
  const {
    baseURL,
    datasourceId,
    table1Id,
    object1Id,
    table2Id,
    object2Id,
    fieldId,
  } = opts

  return {
    type: CREATE_ASSOCIATION,
    payload: async () => ({
      ...opts,
      request: await request(
        datasourceId,
        buildURL(baseURL, {
          datasourceId,
          fieldId,
          tableId: table1Id,
          id: object1Id,
        }),
        'post',
        { tableId: table2Id, id: object2Id }
      ),
    }),
  }
}

export const deleteAssociation = opts => {
  const {
    baseURL,
    datasourceId,
    table1Id,
    object1Id,
    table2Id,
    object2Id,
    fieldId,
  } = opts

  const ending = `${fieldId}/${table2Id}/${object2Id}`
  return {
    type: DELETE_ASSOCIATION,
    payload: async () => ({
      ...opts,
      request: await request(
        datasourceId,
        buildURL(baseURL, {
          datasourceId,
          tableId: table1Id,
          id: object1Id,
          fieldId: ending,
        }),
        'delete'
      ),
    }),
  }
}

// SELECTORS

export const getTableData = (state, tableId) => {
  return state.data.tables[tableId]
}

export const getCollection = (state, collectionId, routeParams) => {
  const routeHash = hashObject(routeParams)
  const collections = state.data.collections[collectionId]
  const collection = collections && collections[routeHash]

  if (!collection) {
    return null
  }

  let { ids, tableId } = collection

  const data = state.data.tables[tableId] || {}
  ids = ids || []

  return ids.map(id => data[id]).filter(itm => itm)
}

export const getNextPageOffset = (state, collectionId, routeParams) => {
  const routeHash = hashObject(routeParams)
  const collections = state.data.collections[collectionId]
  const collection = (collections && collections[routeHash]) || []

  return collection.length
}

export const getItem = (state, tableId, selector) => {
  return selector(state.data.tables[tableId])
}

export const getObject = (dataState, tableId, id) => {
  const data = dataState.tables[tableId]

  return data && data[id]
}

export const getItemById = (state, tableId, id) => {
  return getObject(state.data, tableId, id)
}
