import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SafeAreaInsets from 'safe-area-insets'
import _ from 'lodash'

import {
  statusBarStyles,
  keyboardBehaviors,
  sourceTypes,
  LOCATION_INPUT,
  dataTypes,
} from '@adalo/constants'

import {
  StatusBar,
  Dimensions,
  Platform,
  ScrollView,
  FlatList,
  StyleSheet,
  View,
  Keyboard,
  LogBox,
} from 'react-native'

import { createDataNode } from 'utils/variableHeight/buildPushTree'

import { hasDataAction } from 'utils/action-filtering'
import { Alert } from 'utils/alerts'
import { getDependencies, getInputDependencies } from 'utils/dependencies'
import { actionContextTypes, executeAction } from 'utils/actions'
import { normalizeColor, defaultBranding } from 'utils/colors'
import { isMobileWeb } from 'utils/system'
import { filterObject } from 'utils/objects'
import { shallowEqual } from 'utils/hashing'
import { isListObject } from 'utils/lists'
import { isCloseToBottom } from 'utils/scrolling'
import { removeExternalCollections } from 'utils/trial'
import { searchForSource } from 'utils/sources'
import { searchForInput, searchForField } from 'utils/inputs'
import { getDeviceType } from 'utils/device'

import { fetch } from 'ducks/data'
import { getFormInputs } from 'ducks/formInputs'
import { setCurrentLocation } from 'ducks/location'
import {
  getNodeHeight,
  generatePushTree,
  getScreenPushTree,
} from 'ducks/pushTreeMap'

import { disableFeature } from 'utils/featureAccess'
import {
  finishTransaction,
  sentryTracingInterceptor,
  startTransaction,
} from 'utils/tracing'
import { scheduleRecurringTask } from 'utils/taskScheduler'
import ObjectRenderer from './ObjectRenderer'
import KeyboardAvoidingView from './KeyboardAvoidingView'
import ListBody from './ListBody'
import BackgroundImage from './BackgroundImage'
import Loading from './Loading'

const DEPENDENCY_POLLING_INTERVAL = 2000

const RESPONSIVE_WEB_APPS_ALPHA_VERSION = '0.3'

// Patch to use a custom VirtualList container instead of ScrollView (which might have some issues)
// TODO(enzo): we need to do some testing around this to see what would be the best alternative
const USE_VIRTUAL_LIST_CONTAINER = false

const APPLY_UPDATES_INTERVAL = 300

const APPLY_UPDATES_THROTTLE = 300

//  Used only for applying updates right after a dependency finished fetching (to not wait for the next polling)
const APPLY_UPDATES_DEBOUNCE = 600

if (APPLY_UPDATES_THROTTLE > APPLY_UPDATES_INTERVAL) {
  console.warn(
    `APPLY_UPDATES_THROTTLE shouldn't be bigger than APPLY_UPDATES_INTERVAL`
  )
}

class Screen extends Component {
  static contextTypes = {
    ...actionContextTypes,
    getApp: PropTypes.func,
    getAssetURL: PropTypes.func,
    getBaseURL: PropTypes.func,
    getDatasources: PropTypes.func,
    getFlags: PropTypes.func,
    getStore: PropTypes.func,
    appVersion: PropTypes.string,
  }

  static childContextTypes = {
    getBinding: PropTypes.func,
    getBindings: PropTypes.func,
    getBindingsList: PropTypes.func,
    getComponent: PropTypes.func,
    getParams: PropTypes.func,
    setDirty: PropTypes.func,
    getLayoutGuides: PropTypes.func,

    setListItems: PropTypes.func,
    setVisible: PropTypes.func,
    setHeight: PropTypes.func,
  }

  state = {
    updateVersion: 0,
    offset: 0,
    loading: false,
    loaded: false,
    refreshing: false,
    reachedPaginationEnd: false,
    scrollEnabled: true,
    initialRender: true,
    device: undefined,
  }

  pollTeardowns = []

  pendingUpdates = []

  get screenUsesLocation() {
    const { component } = this.props

    // Search for bindings (Magic Text, Formula, etc.) that depend on device location data
    let usesLocation = searchForSource(
      component.dataBindings,
      sourceTypes.DEVICE_LOCATION
    )

    // Location inputs need to access device location data for biased suggestions
    if (!usesLocation) {
      usesLocation = searchForInput(component.objects, LOCATION_INPUT)
    }

    // Location fields need to access device location data for biased suggestions
    if (!usesLocation) {
      usesLocation = searchForField(component.objects, dataTypes.LOCATION)
    }

    return usesLocation
  }

  getChildContext() {
    const { component, layoutGuides } = this.props

    return {
      getParams: this.getParams,
      setDirty: this.setDirty,
      getBinding: () => null,
      getBindings: () => ({}),
      getBindingsList: () => [],
      getComponent: () => component,
      getLayoutGuides: () => layoutGuides,

      setListItems: this.setListItems,
      setVisible: this.setVisible,
      setHeight: this.setHeight,
    }
  }

  setListItems = (pushId, object, value) => {
    this.addToUpdates(pushId, object, { listItems: value })
  }

  setVisible = (pushId, object, value, visibleOnDevice) => {
    this.addToUpdates(pushId, object, { visible: value, visibleOnDevice })
  }

  setHeight = (pushId, object, value) => {
    this.addToUpdates(pushId, object, { height: Math.round(value) })
  }

  addToUpdates = (pushId, object, update) => {
    this.pendingUpdates.push({
      pushId,
      object,
      update,
    })
  }

  addDeviceChangedUpdate = device => {
    this.pendingUpdates.push({ type: 'deviceChanged', device })
  }

  listenToUpdates = () => {
    this.debouncedApplyUpdates() // Apply update before starting
    // Then apply on intervals, apply them throttled
    this.updatesInterval = scheduleRecurringTask(
      this.throttledApplyUpdates,
      APPLY_UPDATES_INTERVAL
    )
  }

  clearUpdateListener = () => {
    clearInterval(this.updatesInterval)
  }

  applyUpdates = () => {
    const { dataMap: stateDataMap } = this.props
    const hasUpdates = this.pendingUpdates.length
    const newDataMap = _.cloneDeep(stateDataMap)

    while (this.pendingUpdates.length) {
      const nextUpdate = this.pendingUpdates.shift()
      if (nextUpdate?.type === 'deviceChanged') {
        continue
      }

      const { pushId, object, update } = nextUpdate

      let dataNode = newDataMap[pushId]

      if (!dataNode) {
        dataNode = createDataNode(pushId, object)
      }

      newDataMap[pushId] = {
        ...dataNode,
        ...update,
      }
    }
    if (hasUpdates) {
      const { generateNewPushTree, component } = this.props
      const { body, fixed } = component.layout
      const { deviceType } = this.state

      generateNewPushTree({
        // body & fixed components are separated for rendering, but we need to generate push tree for all components,
        // fixed/sticky objects don't push each other, but their children can
        objects: [...body, ...fixed],
        dataMap: newDataMap,
        screenId: component.id,
        device: deviceType,
      })
    }
  }

  throttledApplyUpdates = _.throttle(this.applyUpdates, APPLY_UPDATES_THROTTLE)

  debouncedApplyUpdates = _.debounce(
    this.throttledApplyUpdates,
    APPLY_UPDATES_DEBOUNCE
  )

  startTransaction(name, data = {}) {
    const { component } = this.props
    const { getApp } = this.context
    const { id: appId, Organization } = getApp()
    const { id: componentId } = component

    return startTransaction({
      name,
      op: name,
      data: {
        ...data,
        appId,
        componentId,
        organizationId: Organization?.id,
      },
    })
  }

  getParams = () => {
    const { params } = this.props

    return params || {}
  }

  setDirty = () => {
    this.setState(({ updateVersion }) => ({
      updateVersion: updateVersion + 1,
    }))
  }

  _getDependenciesWithoutTracing = () => {
    const { component, formInputs } = this.props
    const { getStore, getDatasources, getApp } = this.context
    const state = getStore().getState()

    const app = getApp()

    const { Organization } = app
    const { enabledFeatures } = Organization

    const disableExternalCollections = disableFeature(
      enabledFeatures,
      'customIntegrations'
    )

    let dependencies = []

    try {
      dependencies = getDependencies(component, {
        formInputs,
        getDatasources,
        getApp,
        state,
        getParams: this.getParams,
      })

      if (disableExternalCollections) {
        dependencies = removeExternalCollections(getDatasources, dependencies)
      }
    } catch (err) {
      Alert.alert(
        'Failed to load dependencies',
        'Try checking your authentication configuration and make sure your ' +
          'user is properly authenticated before reaching this page.',
        [{ text: 'OK', onPress: () => {} }]
      )
      console.log('ERROR FETCHING DEPENDENCIES:', err)
    }

    return dependencies
  }

  getDependencies = sentryTracingInterceptor(
    this._getDependenciesWithoutTracing,
    'getDependencies'
  )

  _handleRefreshWithoutTracing = async (skipPolling = true) => {
    this.setState({
      offset: 0,
      refreshing: true,
      reachedPaginationEnd: false,
    })

    const dependencies = await this.getDependencies()
    await this.fetchDependencies(dependencies, skipPolling)

    this.setState({ refreshing: false })
  }

  handleRefresh = async (skipPolling = true) => {
    const transaction = this.startTransaction('Screen::handleRefresh', {
      skipPolling,
    })
    try {
      const result = await this._handleRefreshWithoutTracing(skipPolling)
      transaction?.setStatus('ok')
      return result
    } catch (err) {
      transaction?.setStatus('unknown_error')
      throw err
    } finally {
      transaction?.finish()
    }
  }

  _fetchDependenciesWithoutTracing = async (
    dependencies,
    skipPolling = false
  ) => {
    this.teardown()

    await Promise.all(
      dependencies.filter(dep => dep.fetchFirst).map(this.fetchDependency)
    ).then(() => setTimeout(this.debouncedApplyUpdates, 100))

    const promises = dependencies.map(dep => {
      if (dep.listenForChanges && !skipPolling) {
        this.startPolling(dep)
      }

      return this.fetchDependency(dep)
    })

    if (this.screenUsesLocation) {
      const { setCurrentLocation } = this.props
      const { getBaseURL, getApp } = this.context
      const baseUrl = getBaseURL()
      const { id } = getApp()
      promises.push(setCurrentLocation(baseUrl, id, true))
    }

    return Promise.all(promises)
  }

  // Figure out what data is needed and fetch it
  fetchDependencies = sentryTracingInterceptor(
    this._fetchDependenciesWithoutTracing,
    'fetchDependencies'
  )

  fetchDependency = (dependency, offset = 0) => {
    const {
      fetch,
      component: { id: componentId, dataBindings = {} },
    } = this.props

    const { getBaseURL, appVersion, getStore } = this.context
    const { offset: prevOffset } = this.state

    if (offset === 0 && prevOffset > 0) {
      offset = prevOffset
    }

    if (dependency.paginate) {
      dependency = { ...dependency, offset, limit: 20 }
    }

    return fetch(getBaseURL(), {
      ...dependency,
      datasource: this.getDatasource(dependency.datasourceId),
      componentId,
      bindingIds: Object.keys(dataBindings),
      appVersion,
      state: getStore().getState(),
      getParams: this.getParams,
    })
  }

  getDatasource = datasourceId => {
    const { getDatasources } = this.context
    const datasources = getDatasources()

    return datasources[datasourceId]
  }

  startPolling = dependency => {
    const teardownPoll = scheduleRecurringTask(
      () => this.fetchDependency(dependency),
      DEPENDENCY_POLLING_INTERVAL
    )

    this.pollTeardowns.push(teardownPoll)
  }

  _setupWithoutTracing = async () => {
    const { getFlags } = this.context
    const { hasRevisedScreenActions } = getFlags()
    let { component, nextScreen } = this.props
    component = component || {}

    const prevDependencies = await this.getDependencies()
    await this.fetchDependencies(prevDependencies)

    const newDependencies = (await this.getDependencies()).filter(item =>
      prevDependencies.some(
        prevDep =>
          prevDep.id === item.id &&
          (prevDep.columnFilter !== item.columnFilter ||
            prevDep.sortReference !== item.sortReference)
      )
    )

    if (newDependencies.length > 0) {
      await this.fetchDependencies(newDependencies)
    }

    this._loaded = true

    window.setTimeout(() => {
      this.setState({ loaded: true })
    }, 500)

    const { componentActions, onVisit } = component

    const isInitialMount = !this.previouslyMounted
    this.previouslyMounted = true
    const { inputsDifferent } = this

    const returning = this.backActionTaken
    const exiting = this.exitingScreen
    this.backActionTaken = false
    this.exitingScreen = false

    if (
      (isInitialMount || !inputsDifferent) &&
      onVisit &&
      componentActions &&
      !nextScreen
    ) {
      const { actionId } = onVisit
      const action = componentActions[actionId]

      if (!hasRevisedScreenActions) {
        await this.executeAction(componentActions[actionId])
      } else if (!exiting || returning) {
        await this.executeAction(componentActions[actionId])
      }

      const reloadRequired = hasDataAction(action)

      if (
        reloadRequired &&
        (isInitialMount || (hasRevisedScreenActions && returning))
      ) {
        this.handleRefresh(false)
      }
    }

    if (!this.useFlatList) {
      this.checkPagination()
    }
  }

  setup = async () => {
    const transaction = this.startTransaction('Screen::setup')
    try {
      const result = await this._setupWithoutTracing()

      transaction?.setStatus('ok')
      return result
    } catch (err) {
      transaction?.setStatus('unknown_error')
      transaction?.setData('error', err && err.message)
      throw err
    } finally {
      if (transaction !== undefined) {
        finishTransaction(transaction)
      }
    }
  }

  _executeActionWithoutTracing = async action => {
    if (!action) {
      return
    }

    await executeAction(action, {
      ...this.context,
      ...this.getChildContext(),
      screen: true,
    })
  }

  executeAction = sentryTracingInterceptor(
    this._executeActionWithoutTracing,
    'executeAction'
  )

  teardown = () => {
    this.pollTeardowns.forEach(pollTeardown => pollTeardown())

    this.pollTeardowns = []
  }

  isResponsiveWebApp = () => {
    const { component, nextScreen } = this.props

    // nextScreen can be undefined, false or an object, which makes it unsafe to change this to null coalescence
    // Don't use `nextScreen ?? component`, it'll break the Screen component behavior.
    const { layoutVersion } = nextScreen || component

    return layoutVersion === RESPONSIVE_WEB_APPS_ALPHA_VERSION
  }

  componentDidMount() {
    const { active } = this.props

    // TODO(enzo): We need to test if the patch with VirtualizedList is a good alternative for not having a ScrollView as container
    // In the meantime, we ignore the warnings
    if (!USE_VIRTUAL_LIST_CONTAINER && LogBox) {
      // Can't avoid having VirtualizedLists in FlatList
      LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
    }

    if (active) {
      this.setup()
    }

    this.keyboardListener = Keyboard.addListener(
      'keyboardDidShow',
      this.handleResize
    )

    Dimensions.addEventListener('change', this.screenSizeChange)

    window.setTimeout(this.handleResize, 100)

    if (this.isResponsiveWebApp()) {
      this.listenToUpdates()
      const deviceType = getDeviceType()
      this.setState({ deviceType })
    }
  }

  componentWillUnmount() {
    if (this.isResponsiveWebApp()) {
      this.clearUpdateListener()
    }
    this.keyboardListener.remove()
    this.teardown()

    Dimensions.removeEventListener('change', this.screenSizeChange)
  }

  screenSizeChange = () => {
    this.forceUpdate()
  }

  getInputsDifferent = (oldValues, newValues) => {
    const { component } = this.props
    const inputIds = getInputDependencies(component)

    const oldDeps = filterObject(oldValues, inputIds)
    const newDeps = filterObject(newValues, inputIds)

    return !shallowEqual(oldDeps, newDeps)
  }

  shouldComponentUpdate(newProps, newState) {
    const { active, component, params, formInputs, nextScreen } = this.props
    const { updateVersion } = this.state

    const inputsDifferent = this.getInputsDifferent(
      formInputs,
      newProps.formInputs
    )

    if (newProps.active !== active) {
      if (active) {
        this.exitingScreen = true
      } else if (this.previouslyMounted) {
        this.backActionTaken = true
      }
    }

    if (
      newProps.active &&
      (newProps.component !== component ||
        newProps.params !== params ||
        (newProps.active && !active) ||
        inputsDifferent ||
        newState.updateVersion !== updateVersion)
    ) {
      this.inputsDifferent = inputsDifferent
      this.setState({
        offset: 0,
        reachedPaginationEnd: false,
        initialRender: true,
      })
      setTimeout(this.setup, 0)
    }

    if (!newProps.active && active) {
      this.teardown()
    }

    return nextScreen || newProps.active || active
  }

  getStatusBarHidden = () => {
    const { component } = this.props
    const { statusBarStyle } = component

    if (statusBarStyle === statusBarStyles.HIDDEN) {
      return true
    }

    return false
  }

  getBarStyle = () => {
    const { component } = this.props
    const { statusBarStyle } = component

    if (statusBarStyle === statusBarStyles.LIGHT_CONTENT) {
      return 'light-content'
    } else if (statusBarStyle === statusBarStyles.DARK_CONTENT) {
      return 'dark-content'
    }
  }

  handleScroll = e => {
    const shouldCheckPagination = isCloseToBottom(e.nativeEvent)
    if (shouldCheckPagination) {
      this.checkPagination()
    }
  }

  setScrollEnabled = s => {
    this.setState({ scrollEnabled: s })
  }

  checkPagination = async () => {
    const {
      loading,
      reachedPaginationEnd,
      offset: prevOffset,
      initialRender,
    } = this.state

    const offset = initialRender ? prevOffset : prevOffset + 20

    const dependencies = (await this.getDependencies()).filter(dep => {
      const { paginate, limit, isExternalCollection } = dep

      // Only fetch external collections one time if it's not paginated
      if (
        (!initialRender && isExternalCollection && !paginate) ||
        isExternalCollection
      ) {
        return false
      }

      let belowLimit = true

      if (limit) {
        belowLimit = offset < Number(limit) + 20
      }

      return paginate && belowLimit
    })

    if (loading || reachedPaginationEnd || dependencies.length === 0) {
      return
    }

    this.setState({ offset, loading: true })

    const promises = dependencies.map(dep => this.fetchDependency(dep, offset))

    const results = await Promise.all(promises)

    const positiveResults = results.filter(
      r => r && r.value && r.value.data && r.value.data.length >= 20
    )

    if (positiveResults.length === 0) {
      this.setState({
        reachedPaginationEnd: true,
      })
    }

    setTimeout(() => {
      this.setState({ loading: false, initialRender: false })
    }, 100)
  }

  handleResize = (width, height) => {
    const { loaded, deviceType: oldDeviceType } = this.state
    const { component } = this.props

    if (height) {
      this._innerHeight = height

      if (this._loaded && height < this._scrollViewHeight) {
        this.checkPagination()
      }
    }

    const deviceType = getDeviceType(width)
    if (this.isResponsiveWebApp() && oldDeviceType !== deviceType) {
      this.addDeviceChangedUpdate(deviceType)
      this.setState({ deviceType })
    }

    if (this._scrollView && component.reverseScroll) {
      window.setTimeout(() => {
        this._scrollView.scrollToEnd({ animated: loaded })
      }, 10)
    }
  }

  handleLayout = ({ nativeEvent }) => {
    this._scrollViewHeight = nativeEvent.layout.height
  }

  scrollViewRef = el => {
    this._scrollView = el
  }

  scrollTo = async (y = 0) => {
    setTimeout(() => {
      this._scrollView.scrollTo({ x: 0, y, animated: true })
    }, 200)
  }

  isListView = () => {
    const { component } = this.props
    const body = component.layout.body[0]

    if (!body) {
      return false
    }

    const bodyChildren = body.children || []
    const listChildren = bodyChildren.filter(obj => isListObject(obj))

    return listChildren.length === 1 && !this.isResponsiveWebApp()
  }

  get useFlatList() {
    return this.isListView()
  }

  render() {
    const {
      active,
      component,
      topScreen,
      layoutGuides,
      nextScreen,
      screenHeight,
    } = this.props

    const { loading, refreshing, scrollEnabled } = this.state
    const { getApp, getAssetURL } = this.context

    const app = getApp()

    let {
      backgroundColor,
      backgroundImage,
      backgroundSize,
      backgroundPositionX,
      backgroundPositionY,
      layout,
    } = nextScreen || component

    const isResponsiveWebApp = this.isResponsiveWebApp()

    const branding = (app && app.branding) || defaultBranding
    backgroundImage = backgroundImage && getAssetURL(backgroundImage)
    backgroundPositionX = backgroundPositionX || 'center'
    backgroundPositionY = backgroundPositionY || 'center'
    backgroundSize = backgroundSize || 'cover'

    const offsetTop = layoutGuides.top - 20

    const offsetBottom = layoutGuides.bottom

    const avoidingBehavior = Platform.OS === 'android' ? undefined : 'padding'

    const paddingStyles = {
      paddingTop: Math.max(0, offsetTop),
      paddingBottom: offsetBottom,
    }

    if (offsetTop < 0 && isMobileWeb()) {
      paddingStyles.top = offsetTop
    }

    const scrollViewInnerStyles = {
      paddingBottom: offsetBottom + 20,
    }

    if (nextScreen) {
      backgroundColor = nextScreen.backgroundColor || '#ffffff'
    }

    const backgroundStyles = {
      backgroundColor: normalizeColor(
        backgroundColor || '@background',
        branding
      ),
    }

    const heightStyles = {}

    if (Platform.OS !== 'web') {
      heightStyles.height = Dimensions.get('window').height
    }

    let screenBody = null

    if (!this.useFlatList) {
      screenBody = layout.body.map(obj => (
        <ObjectRenderer
          key={obj.id}
          object={obj}
          component={component}
          active={active}
          scrollTo={this.scrollTo}
          topScreen={topScreen}
          setScrollEnabled={this.setScrollEnabled}
          isResponsiveComponent={isResponsiveWebApp}
        />
      ))
    }

    const screenFixed = layout.fixed.map(obj => (
      <ObjectRenderer
        key={obj.id}
        object={obj}
        component={component}
        active={active}
        topScreen={topScreen}
        isResponsiveComponent={isResponsiveWebApp}
      />
    ))

    if (nextScreen) {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: backgroundStyles.backgroundColor,
          }}
        >
          {backgroundImage && (
            <BackgroundImage
              source={backgroundImage}
              resizeMode={backgroundSize}
              positionX={backgroundPositionX}
              positionY={backgroundPositionY}
            />
          )}
          <Loading />
        </View>
      )
    }

    let screenHeightStyles = {}
    if (isResponsiveWebApp) {
      screenHeightStyles = {
        height: screenHeight,
        minHeight: '100%',
        // TODO(enzo): Patch for content rendering outside of viewport:
        // The bottom-most components get hidden below the ScrollView. On the screen bottom, you can keep scrolling to see them,
        // but on scroll release the viewport jumps back app and bottom components stay hidden.
        // Still need to figure out if this patch is valid and if it should depend on de device or some other property of SafeAreaInsets
        marginBottom: 84,
      }
    }

    const ContentContainer = USE_VIRTUAL_LIST_CONTAINER
      ? VirtualizedList
      : ScrollView

    return (
      <View style={[styles.root, styles.wrapper, backgroundStyles]}>
        {backgroundImage && (
          <BackgroundImage
            source={backgroundImage}
            resizeMode={backgroundSize}
            positionX={backgroundPositionX}
            positionY={backgroundPositionY}
          />
        )}
        <StatusBar
          translucent
          backgroundColor="rgba(0, 0, 0, 0)"
          barStyle={this.getBarStyle()}
          hidden={this.getStatusBarHidden()}
        />
        <View style={[styles.wrapper]}>
          <KeyboardAvoidingView
            behavior={avoidingBehavior}
            style={styles.wrapper}
          >
            {this.useFlatList ? (
              <ListBody
                body={layout.body}
                style={{ ...paddingStyles, ...scrollViewInnerStyles }}
                component={component}
                active={active}
                topScreen={topScreen}
                onEndReached={this.checkPagination}
                loading={loading}
                onRefresh={this.handleRefresh}
                refreshing={refreshing}
              />
            ) : (
              <ContentContainer
                ref={this.scrollViewRef}
                keyboardShouldPersistTaps="handled"
                style={[styles.scrollView, screenHeightStyles]}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                onContentSizeChange={this.handleResize}
                onLayout={this.handleLayout}
                onScroll={this.handleScroll}
                scrollEventThrottle={200}
                scrollEnabled={scrollEnabled}
              >
                <View
                  style={[
                    paddingStyles,
                    scrollViewInnerStyles,
                    screenHeightStyles,
                  ]}
                >
                  <View style={[screenHeightStyles]}>{screenBody}</View>
                </View>
              </ContentContainer>
            )}
          </KeyboardAvoidingView>
        </View>
        <View
          style={[styles.fixedContent, paddingStyles, heightStyles]}
          pointerEvents="box-none"
        >
          <KeyboardAvoidingView
            behavior="padding"
            style={[styles.wrapper, styles.offset]}
            pointerEvents="box-none"
            enabled={component.keyboardBehavior === keyboardBehaviors.AVOID}
          >
            {screenFixed}
          </KeyboardAvoidingView>
        </View>
      </View>
    )
  }
}

// TODO(enzo): part of testing VirtualizedList to not have a ScrollView with FlatLists nested
const VirtualizedList = React.forwardRef(({ children, ...listProps }, ref) => (
  <FlatList
    {...listProps}
    ref={ref}
    data={[]}
    keyExtractor={() => 'key'}
    renderItem={null}
    ListHeaderComponent={<>{children}</>}
  />
))

const styles = StyleSheet.create({
  root: {
    overflow: 'hidden',
  },
  wrapper: {
    flex: 1,
  },
  offset: {
    ...Platform.select({
      web: {
        paddingBottom: SafeAreaInsets.bottom,
      },
    }),
  },
  scrollView: {
    flex: 1,
  },
  fixedContent: {
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
})

const mapStateToProps = (state, { component }) => {
  const wrapper = component.layout?.body?.[0]

  const { layoutVersion } = component

  const isResponsiveWebApp = layoutVersion === RESPONSIVE_WEB_APPS_ALPHA_VERSION

  const screenHeight = isResponsiveWebApp
    ? getNodeHeight(state, component.id, wrapper?.id)
    : 0

  return {
    screenHeight,
    formInputs: getFormInputs(state),
    dataMap: getScreenPushTree(state, component.id),
  }
}

export default connect(mapStateToProps, {
  fetch,
  setCurrentLocation,
  generateNewPushTree: generatePushTree,
})(Screen)
