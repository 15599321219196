import React from 'react'
import PropTypes from 'prop-types'
import chroma from 'chroma-js'
import {
  Text,
  View,
  Image,
  StyleSheet,
  FlatList,
  TextInput,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  Keyboard,
} from 'react-native'
import Icon from 'react-native-vector-icons/dist/MaterialIcons'
import { dataTypes, DEBOUNCE_TIME, locationTypes } from '@adalo/constants'
import { debounce, isEqual } from 'lodash'
import { contrastWithBackground, getRGBA } from 'utils/colors'
import 'react-native-get-random-values'
import { v4 as uuid } from 'uuid'
import { getLocalizedText, currentLocationMessages } from 'utils/languageLocale'
import { triggerToast, DEVICE_LOCATION_UNAVAILABLE } from 'ducks/toasts'
import { evaluateBinding } from 'utils/dependencies'
import BaseObject from '../BaseObject'
import ActionWrapper from '../ActionWrapper'
import androidColor from './powered_by_google/android/powered_by_google_on_white.png'
import androidWhite from './powered_by_google/android/powered_by_google_on_non_white.png'
import iosColor from './powered_by_google/ios/powered_by_google_on_white.png'
import iosWhite from './powered_by_google/ios/powered_by_google_on_non_white.png'
import webColor from './powered_by_google/desktop/powered_by_google_on_white_hdpi.png'
import webWhite from './powered_by_google/desktop/powered_by_google_on_non_white_hdpi.png'
import { connectInput } from '../Input'
import {
  fetchAutocompleteResults,
  fetchPlaceDetails,
} from '../../utils/googlePlaces'
import { findFormInGroups } from '../../utils/searchGroups'

export class LocationInput extends BaseObject {
  static contextTypes = {
    getBaseAPIURL: PropTypes.func,
    getStore: PropTypes.func,
  }

  state = {
    y: 0,
    displayValue: '',
    value: null,
    currentLocationText: 'Current Device Location',
    currentLocation: false,
    image: '',
    icon: null,
    iconSize: 0,
    autoCorrect: true,
    colors: {},
    textStyles: {},
    containerStyles: {},
    showOptions: false,
    token: uuid(),
    options: [],
    loading: false,
    predictionsTimeout: { current: null },
    initialFetch: false,
  }

  componentDidMount() {
    const { value, object, defaultValue } = this.props
    const { autoFocus } = object.attributes

    this.handleStyles()

    this.debounceHandleFormStateUpdate = debounce(
      this.handleFormStateUpdate,
      DEBOUNCE_TIME
    )
    this.setYValue()

    if (defaultValue) {
      this.setDefaultValue(defaultValue)
    } else if (value) {
      this.handleChange(value)
      this.setState({ value })
    }

    if (autoFocus) {
      this.textInput.focus()
    }

    if (autoFocus) {
      this.textInput.focus()
    }
  }

  setDefaultValue = value => {
    const { setDefaultValue, inputId, changeValue } = this.props

    this.handleChange(value)
    this.setState({ value })
    setDefaultValue(inputId, value)
    changeValue(inputId, value)
  }

  componentDidUpdate(prevProps) {
    const { object, value, topScreen, formSubmitSuccess, defaultValue } =
      this.props

    const { autoFocus } = object.attributes

    if (topScreen) {
      if (prevProps.defaultValue !== defaultValue) {
        this.setDefaultValue(defaultValue)
      }

      if (!isEqual(object.attributes, prevProps.object.attributes)) {
        this.handleStyles()
      }

      if (value && value !== prevProps.value) {
        this.handleChange(value)
      }

      if (
        prevProps.formSubmitSuccess !== formSubmitSuccess &&
        formSubmitSuccess
      ) {
        this.handleChange('')
      }

      if (autoFocus && !prevProps.active) {
        this.textInput.focus()
      }
      /*
       * We need this specific check to be able to set an input
       * to empty if it is doing it through the CHANGE_VALUE action using
       *  an undefined value.
       */
      if (typeof value === 'undefined' && value !== prevProps.value) {
        this.handleChange('')
      }

      if (value !== prevProps.value) {
        this.handleStyles()
      }
    }
  }

  componentWillUnmount() {
    const { object, resetValue } = this.props

    if (resetValue && object) {
      resetValue(object.id)
    }
  }

  handleChange = displayValue => {
    const colors = this.getColors()
    const { value, predictionsTimeout } = this.state
    const { placeholderColor, suggestionIconColor } = colors
    clearTimeout(predictionsTimeout.current)

    if (typeof displayValue !== 'string') {
      const iconColor = displayValue ? suggestionIconColor : placeholderColor
      this.setState({
        value: displayValue,
        displayValue: displayValue.fullAddress,
        colors: { ...colors, iconColor },
      })
      return
    }

    this.setState({ displayValue })

    if (displayValue) {
      if (!value) {
        predictionsTimeout.current = setTimeout(
          () => this.fetchOptions(displayValue),
          DEBOUNCE_TIME
        )
      }
    } else {
      this.setState({
        loading: false,
        colors: { ...colors, iconColor: placeholderColor },
      })
    }

    if (value) {
      if (displayValue !== this.state.displayValue) {
        this.setState(
          {
            value: null,
            colors: { ...colors, iconColor: placeholderColor },
            currentLocation: false,
          },
          this.debounceHandleFormStateUpdate
        )
        this.fetchOptions(displayValue)
      }
    }
  }

  fetchOptions = async search => {
    const { app } = this.props
    const { token } = this.state
    const { getStore, getBaseAPIURL } = this.context
    const baseUrl = getBaseAPIURL()
    const state = getStore().getState()
    const currentDeviceLocation = state.location

    let coordinates

    if (currentDeviceLocation.location) {
      coordinates = currentDeviceLocation.location.coordinates
    }

    this.setState({ loading: true })
    const options = await fetchAutocompleteResults(
      baseUrl,
      app,
      token,
      search,
      coordinates
    )
    this.setState({
      options,
      showOptions: true,
      loading: false,
      initialFetch: true,
    })
  }

  handleFormStateUpdate = () => {
    const { value } = this.state
    const { value: formValue, inputId, changeValue } = this.props

    if (value !== formValue) {
      changeValue(inputId, value)
    }
  }

  handleSelect = async option => {
    const { app } = this.props
    const { colors, token } = this.state
    const { placeholderColor, suggestionIconColor } = colors
    const baseUrl = this.context.getBaseAPIURL()
    if (option) {
      this.setState({ loading: true })
      const value = await fetchPlaceDetails(baseUrl, app.id, option.id, token)
      if (value) {
        this.setState(
          {
            value,
            displayValue: value.fullAddress,
            token: uuid(),
            colors: { ...colors, iconColor: suggestionIconColor },
            showOptions: false,
            currentLocation: false,
            loading: false,
          },
          this.debounceHandleFormStateUpdate
        )
        Keyboard.dismiss()
        this.handleStyles()
        return
      }
    }
    this.setState(
      {
        value: null,
        displayValue: '',
        colors: { ...colors, iconColor: placeholderColor },
        currentLocation: false,
        loading: false,
      },
      this.debounceHandleFormStateUpdate
    )
  }

  getCurrentLocation = async () => {
    const { triggerToast } = this.props
    const { getStore } = this.context
    const { colors } = this.state
    const { suggestionIconColor } = colors

    const state = getStore().getState()
    const currentDeviceLocation = state.location

    this.setState({
      loading: false,
      colors: { ...colors, iconColor: suggestionIconColor },
      showOptions: false,
    })
    this.handleStyles()

    if (!currentDeviceLocation.location || currentDeviceLocation.code) {
      this.setState(
        {
          value: '',
          displayValue: '',
          currentLocation: false,
        },
        this.debounceHandleFormStateUpdate
      )

      triggerToast(DEVICE_LOCATION_UNAVAILABLE)
    } else {
      const { location } = currentDeviceLocation

      this.setState(
        {
          value: location,
          displayValue: location.fullAddress || location.name,
          currentLocation: true,
        },
        this.debounceHandleFormStateUpdate
      )
    }
  }

  getInputId() {
    const { object, getBindingsList } = this.props
    const parentBindings = getBindingsList()

    return parentBindings.concat(object.id).join('.')
  }

  getAttributes = () => {
    const { object } = this.props

    return object.attributes || {}
  }

  setYValue() {
    const { component, object } = this.props
    const { objects } = component
    const { attributes, id: objectId, layout } = object
    const { marginTop } = layout
    const { height, width: menuWidth } = attributes

    let form = objects.find(
      ({ id, type }) => objectId.includes(id) && type === 'form'
    )

    if (!form) {
      const groups = objects.filter(({ type }) => type === 'group')
      form = findFormInGroups(groups, objectId)
      if (!form) {
        return height
      }
    }

    const { fields, id: formId, y } = form
    const types = fields.map(x => x.type)
    const index = fields.findIndex(
      ({ fieldId }) => `${formId}-${fieldId}` === `${objectId}`
    )
    let sum = 0
    for (let i = 0; i <= index; i += 1) {
      // Add space above label
      sum += 15
      // If the field is a boolean, do not add field and label height separately
      if (types[i] === dataTypes.BOOLEAN) {
        sum += 24
      } else {
        // Add label height
        sum += form.fieldStyles.labels.fontSize || 14
        sum += marginTop
        if (types[i] === dataTypes.IMAGE) {
          // Add image field height
          sum += Math.round((menuWidth * 2) / 3)
        } else if (types[i] === dataTypes.FILE) {
          // Add file field height
          sum += 80
        } else if (types[i] === dataTypes.TEXT) {
          // Add text field height
          if (fields[i].multiline) {
            sum += 80
          } else {
            sum += height
          }
        } else {
          // Add standard field height for other fields
          sum += height
        }
      }
    }
    // Add position of form to height of form
    sum += y
    this.setState({ y: sum })
  }

  getTextStyles = icon => {
    const { errorColor, fontSize, padding } = this.getAttributes()
    const textStyles = this.textStyles()
    const { borderWidth } = this.borderStyles()

    let inputTextStyle = {
      ...textStyles,
      paddingLeft: icon ? padding : 0,
      lineHeight: fontSize * 1.25,
      overflow: 'hidden',
      flex: 1,
      marginRight: 0,
    }

    if (Platform.OS === 'ios') {
      inputTextStyle = {
        ...inputTextStyle,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: fontSize * 1.25,
      }
    } else {
      inputTextStyle = {
        ...inputTextStyle,
        paddingTop: padding - borderWidth,
        paddingBottom: padding - borderWidth,
      }
    }

    if (Platform.OS === 'web') {
      inputTextStyle.outline = 'none'
    }

    const clearIconStyle = { padding: padding - borderWidth }
    const loadingIconStyle = { marginLeft: padding }

    return {
      inputTextStyle,
      optionsTextStyle: {
        ...textStyles,
        paddingLeft: 10,
        fontWeight: '500',
        width: '100%',
      },
      errorTextStyle: {
        ...textStyles,
        color: errorColor,
      },
      clearIconStyle,
      loadingIconStyle,
    }
  }

  getContainerStyles = () => {
    const { value } = this.state
    const { padding, height } = this.getAttributes()
    const borderStyles = this.borderStyles()
    const backgroundStyles = this.backgroundStyles()
    let { backgroundColor } = backgroundStyles
    backgroundColor = backgroundColor || 'white'

    const outline = Platform.OS === 'web' ? 'none' : undefined
    const marginRight = Platform.OS === 'web' ? 0 : 2 * padding

    const containerStyles = {
      paddingRight: padding,
      paddingLeft: padding,
      alignItems: 'center',
      flexDirection: 'row',
    }

    return {
      menu: {
        ...borderStyles,
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor,
        width: '100%',
      },
      inputContainer: {
        ...this.shadowStyles(),
        ...this.borderStyles(),
        ...containerStyles,
        outline,
        backgroundColor,
        height,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: value ? 0 : padding,
      },
      optionContainer: {
        ...containerStyles,
        paddingTop: padding * 1.5,
        paddingBottom: padding * 1.5,
        marginRight,
      },
      footer: {
        height: 30,
        paddingRight: padding,
      },
    }
  }

  getColors = () => {
    const { value } = this.state
    const placeholderColor = this.getPlaceholderColor()
    const { color } = this.textStyles()

    let lightSuggestionColor = color
    let suggestionIconColor = color
    if (chroma.valid(lightSuggestionColor)) {
      lightSuggestionColor =
        getRGBA(chroma(lightSuggestionColor).alpha(0.7)) || lightSuggestionColor
      suggestionIconColor =
        getRGBA(chroma(suggestionIconColor).alpha(0.3)) || lightSuggestionColor
    }
    const iconColor = value ? suggestionIconColor : placeholderColor

    return {
      iconColor,
      suggestionIconColor,
      lightSuggestionColor,
      placeholderColor,
    }
  }

  getFooterImage = () => {
    let image = ''
    const { backgroundColor } = this.backgroundStyles()
    const contrast = contrastWithBackground(backgroundColor)

    if (contrast === '#fff') {
      if (Platform.OS === 'android') {
        image = androidWhite
      } else if (Platform.OS === 'ios') {
        image = iosWhite
      } else {
        image = webWhite
      }
    } else {
      if (Platform.OS === 'android') {
        image = androidColor
      } else if (Platform.OS === 'ios') {
        image = iosColor
      } else {
        image = webColor
      }
    }

    return image
  }

  getPlaceholderColor = () => {
    const { placeholderColor } = this.getAttributes()

    return placeholderColor || '#a9a9a9'
  }

  handleStyles = () => {
    const {
      object: { icon: formFieldIcon },
    } = this.props
    const { icon: inputIcon } = this.getAttributes()

    let icon = formFieldIcon || inputIcon

    const containerStyles = this.getContainerStyles()
    const textStyles = this.getTextStyles(icon)
    const colors = this.getColors()
    const image = this.getFooterImage()

    const { fontSize } = this.textStyles()

    const iconSize = fontSize * 1.5

    if (icon) {
      icon = icon.replace(/_/g, '-')
    }

    const currentLocationText = getLocalizedText(currentLocationMessages).body

    this.setState({
      colors,
      image,
      containerStyles,
      textStyles,
      icon,
      iconSize,
      currentLocationText,
    })
  }

  onFocus = () => {
    const {
      scrollTo,
      object: { isFormField },
    } = this.props
    const { displayValue } = this.state

    if (isFormField) {
      const { y } = this.state
      const withMargin = y - 150
      scrollTo(withMargin)
    }
    if (!displayValue) {
      this.setState({ showOptions: true })
    }
  }

  onBlur = () => {
    const { value } = this.state
    if (!value) {
      this.clearInput()
    }
    this.setState({ showOptions: false, options: [], initialFetch: false })
  }

  sanitizeValue = value => {
    return typeof value === 'string' ? value : ''
  }

  clearInput = () => {
    const { colors } = this.state
    const iconColor = this.getPlaceholderColor()

    this.setState(
      {
        displayValue: '',
        value: null,
        colors: { ...colors, iconColor },
        currentLocation: false,
      },
      this.debounceHandleFormStateUpdate
    )
  }

  renderItem = ({ item, icon, currentDeviceLocation }, loading) => {
    const { colors, iconSize, textStyles, containerStyles, displayValue } =
      this.state
    const { lightSuggestionColor, suggestionIconColor } = colors
    const { optionsTextStyle, errorTextStyle } = textStyles
    const { optionContainer } = containerStyles
    if (!currentDeviceLocation && !displayValue) return null

    if (item.errorMessage) {
      return (
        <View style={styles.error}>
          <Text style={errorTextStyle}>{item.errorMessage}</Text>
        </View>
      )
    }

    return (
      <View>
        {item.index === 0 ? this.renderSeparator() : null}
        <TouchableOpacity
          style={optionContainer}
          onPress={
            currentDeviceLocation
              ? this.getCurrentLocation
              : () => this.handleSelect(item)
          }
          disabled={loading}
        >
          <Icon
            size={iconSize || 21}
            name={icon || 'location-on'}
            color={suggestionIconColor || '#9e9e9e'}
          />
          <Text style={optionsTextStyle} numberOfLines={1} ellipsizeMode="tail">
            {item.primaryText}{' '}
            <Text style={{ color: lightSuggestionColor || '#999999' }}>
              {item.secondaryText}
            </Text>
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderSeparator = () => {
    const { displayValue } = this.state
    const { borderColor, borderRadius, borderWidth } = this.borderStyles()
    if (!displayValue) return null
    return (
      <View
        style={{
          backgroundColor: borderColor,
          height: borderWidth,
          borderTopLeftRadius: borderRadius,
          borderBottomLeftRadius: borderRadius,
          marginLeft: 40,
        }}
      />
    )
  }

  renderFooter = () => {
    const { image, containerStyles } = this.state
    const { footer } = containerStyles
    return (
      <View style={footer}>
        <Image
          source={image}
          style={styles.image}
          accessibilityLabel="Powered by Google"
        />
      </View>
    )
  }

  renderHeader = loading => {
    const { currentLocationText } = this.state
    const props = {
      item: {
        primaryText: currentLocationText,
      },
      icon: 'near-me',
      currentDeviceLocation: true,
    }

    return <View>{this.renderItem(props, loading)}</View>
  }

  renderEmpty = () => {
    const { textStyles, displayValue, initialFetch } = this.state
    const { errorTextStyle } = textStyles
    if (!displayValue || !initialFetch) return null
    return (
      <View style={styles.error}>
        <Text style={errorTextStyle}>No Results</Text>
      </View>
    )
  }

  render() {
    const { object, component, bindingData } = this.props
    const { attributes, layout } = object
    const { placeholder, maxLength } = attributes
    const {
      autoCorrect,
      icon,
      iconSize,
      colors,
      textStyles,
      containerStyles,
      displayValue,
      value,
      showOptions,
      options,
      currentLocation,
      loading,
    } = this.state

    const { iconColor } = colors
    const { inputTextStyle, clearIconStyle, loadingIconStyle } = textStyles
    const { inputContainer, menu } = containerStyles

    return (
      <ActionWrapper
        object={object}
        component={component}
        bindingData={bindingData}
        style={[layout, this.getOpacity()]}
      >
        <View style={[inputContainer]}>
          {icon ? (
            <Icon
              size={iconSize || 21}
              name={currentLocation ? 'near-me' : icon}
              color={iconColor || '#CCCCCC'}
            />
          ) : null}
          <TextInput
            autoCapitalize="sentences"
            autoCorrect={autoCorrect}
            // eslint-disable-next-line no-return-assign
            ref={textInput => (this.textInput = textInput)}
            keyboardType="default"
            style={inputTextStyle}
            numberOfLines={1}
            multiline={false}
            returnKeyType="done"
            onChangeText={this.handleChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={this.sanitizeValue(displayValue)}
            placeholder={placeholder}
            placeholderTextColor={this.getPlaceholderColor()}
            secureTextEntry={false}
            underlineColorAndroid="transparent"
            maxLength={maxLength}
          />
          {loading ? (
            <ActivityIndicator
              color={iconColor || '#CCCCCC'}
              style={loadingIconStyle}
            />
          ) : null}
          {!loading && value ? (
            <TouchableOpacity onPress={this.clearInput} style={clearIconStyle}>
              <Icon
                size={iconSize || 21}
                name="clear"
                color={iconColor || '#CCCCCC'}
              />
            </TouchableOpacity>
          ) : null}
        </View>
        <View style={styles.menuContainer}>
          {showOptions ? (
            <FlatList
              data={options}
              keyboardShouldPersistTaps="handled"
              renderItem={item => this.renderItem(item, loading)}
              ItemSeparatorComponent={this.renderSeparator}
              ListHeaderComponent={() => this.renderHeader(loading)}
              ListFooterComponent={this.renderFooter}
              ListEmptyComponent={this.renderEmpty}
              style={menu}
            />
          ) : null}
        </View>
      </ActionWrapper>
    )
  }
}

const styles = StyleSheet.create({
  error: {
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuContainer: {
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  image: {
    height: 15,
    width: 124,
    alignSelf: 'flex-end',
  },
})

const inputMapState = (state, props) => {
  const { defaultValue: defaultValueSource } = props.object.attributes

  let defaultValue

  if (defaultValueSource) {
    if (defaultValueSource.defaultType === locationTypes.CUSTOM) {
      defaultValue = defaultValueSource.value
    } else {
      defaultValue = evaluateBinding(state, defaultValueSource.value, props)

      if (!defaultValue && defaultValueSource.hasFallback) {
        defaultValue = defaultValueSource.fallbackValue
      }
    }
  }

  return {
    defaultValue,
  }
}

export default connectInput(
  LocationInput,
  {
    triggerToast,
  },
  inputMapState
)
