import { Platform } from 'react-native'

/**
 * Returns a font family name from a font target value
 * @param {string} value
 * @param {object} branding - App branding object
 * @param {object} opts
 * @example
 * const branding = { fonts: { body: { family: 'Roboto' } } }
 * return normalizeFontFamily(value, branding)
 */
export const normalizeFontFamily = (value, branding = {}, opts = {}) => {
  const { fonts = {} } = branding
  let { fontWeight } = opts

  const defaultValue = Platform && Platform.OS === 'ios' ? 'System' : 'inherit'
  const isAndroid = Platform && Platform.OS === 'android'

  if (!value || typeof value !== 'string' || !fonts) {
    return defaultValue
  }

  if (!value.startsWith('@')) {
    // assign "@body" to value if value is "system"
    // this is because "system" is our "magic" value for body font branding
    if (value === 'system') {
      value = '@body'
    }
  }

  if (fontWeight) {
    // translate fontWeight value to verify fontWeight exists in
    // the available font variants
    fontWeight = translateFontWeight(fontWeight, value, branding)
  }

  switch (value) {
    case '@body':
    case '@heading': {
      const key = value.substring(1)
      const family = fonts[key] && fonts[key].family

      if (isAndroid && family) {
        return translateFontForAndroid(family, fontWeight)
      } else {
        if (family && family !== 'default') {
          return family
        }

        return defaultValue
      }
    }
    case 'default':
      return defaultValue
    default: {
      if (isAndroid) {
        return translateFontForAndroid(value, fontWeight)
      } else {
        return value
      }
    }
  }
}

/**
 * guarentees the return of an integer for fontSize
 * @example
 * handleFontSize('14px')
 * @returns 14
 */
export const handleFontSize = value => {
  if (typeof value === 'string') {
    if (value.endsWith('px')) {
      value = value.substring(0, value.length - 2)
    }

    value = parseInt(value)
  }

  return value
}

/**
 * Guarentees font weight to be available from font family
 * @param {*} value
 * @param {*} family
 * @param {*} branding
 */
export const translateFontWeight = (value, family, branding = {}) => {
  const { fonts = {} } = branding

  let key = null
  value = normalizeFontWeight(value)

  // assign key based off family
  // should handle a normalized or pre-normalized family key
  if (typeof family === 'string') {
    if (family.startsWith('@')) {
      // family has not been normalized yet
      key = family.substring(1)
    } else {
      // family has been normalized, so we need to grab the key
      const fontKeys = Object.keys(fonts)

      for (const fontKey of fontKeys) {
        if (fonts[fontKey] && fonts[fontKey].family === family) {
          key = fontKey
          break
        }
      }
    }
  }

  if (!key || value === '400') {
    return value
  }

  const target = fonts[key]

  if (!Array.isArray(target && target.variants)) {
    if (defaultFontWeightOptions.includes(value)) {
      return value
    } else {
      return approximateFontWeight(value, defaultFontWeightOptions)
    }
  }

  const normalizedVariants = target.variants.map(obj =>
    normalizeFontWeight(obj)
  )

  if (normalizedVariants.includes(value)) {
    return value
  } else {
    return approximateFontWeight(value, normalizedVariants)
  }
}

export const normalizeFontWeight = value => {
  if (!value || typeof value === 'object') return '400'

  if (typeof value !== 'string') value = `${value}`
  value = value.toLowerCase()

  switch (value) {
    case 'regular':
    case 'normal':
      return '400'
    default:
      return value
  }
}

export const approximateFontWeight = (value, options = []) => {
  if (!value || value === '400') return '400'

  options = options.map(obj => parseInt(normalizeFontWeight(obj)))
  value = parseInt(value)

  const diffs = options.map(opt => Math.abs(opt - value))
  const smallestDiff = Math.min(...diffs)
  const index = diffs.indexOf(smallestDiff)

  if (index !== -1 && options[index]) {
    return `${options[index]}`
  } else {
    return '400'
  }
}

export const defaultFontWeightOptions = [
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
]

export const translateFontForAndroid = (fontFamily, fontWeight) => {
  // set fontFamily to "roboto" if is falsy or default value
  if (!fontFamily || ['default', 'system'].includes(fontFamily.toLowerCase())) {
    fontFamily = 'Roboto'
  }

  // remove whitespace from fontFamily
  fontFamily = fontFamily.replace(/\s/g, '')

  // add fontWeight to fontFamily if weight isn't "normal"
  if (fontWeight && !fontWeight.includes('400', 'regular', 'normal')) {
    return `${fontFamily}-${fontWeight}`
  } else {
    return fontFamily
  }
}
