/* eslint-disable max-classes-per-file */
import React, { Component, PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import lodash from 'lodash'

import { View, ActivityIndicator, StyleSheet } from 'react-native'

import { bindingTypes, listTypes } from '@adalo/constants'

import { getNodeHeight } from 'ducks/pushTreeMap'
import { sortItems, listsEqual } from 'utils/lists'
import ActionWrapper from '../ActionWrapper'
import ListWrapper from './ListWrapper'

export class UnconnectedListItem extends PureComponent {
  static contextTypes = {
    getBinding: PropTypes.func,
    getBindings: PropTypes.func,
    getBindingsList: PropTypes.func,
    getDatasources: PropTypes.func,
  }

  static childContextTypes = {
    getBinding: PropTypes.func,
    getBindings: PropTypes.func,
    getBindingsList: PropTypes.func,
  }

  getChildContext() {
    return {
      getBinding: this.getBinding,
      getBindings: this.getBindings,
      getBindingsList: this.getBindingsList,
    }
  }

  getBinding = objectId => {
    const { item, listId, object } = this.props
    const { getBinding } = this.context

    if (
      objectId === listId ||
      objectId === (object.dataBinding && object.dataBinding.id)
    ) {
      try {
        return {
          ...item,
          id: JSON.parse(item.id),
        }
      } catch {
        // do nothing
      }

      return item
    } else if (getBinding) {
      return getBinding(objectId)
    }
  }

  getBindings = () => {
    const { item, datasourceId, tableId } = this.props
    const { getBindings } = this.context

    let result = {}

    if (getBindings) {
      result = getBindings()
    }

    if (item) {
      const key = `${datasourceId}.${tableId}`
      result[key] = item.id
    }

    return result
  }

  getBindingsList = () => {
    const { getBindingsList } = this.context
    const { item } = this.props

    const bindingsList = getBindingsList()

    if (item) return bindingsList.concat([item.id])
    return bindingsList
  }

  render() {
    const {
      children,
      rowMargin,
      object,
      component,
      marginLeft,
      marginRight,
      newHeight,
      isResponsiveComponent,
    } = this.props

    const styles = { marginBottom: rowMargin }

    if (isResponsiveComponent) {
      styles.height = newHeight
    }

    if (object.actions && Object.keys(object.actions).length > 0) {
      return (
        <ActionWrapper
          style={styles}
          bindingData={{}}
          object={object}
          component={component}
          hitSlop={0}
        >
          {children}
        </ActionWrapper>
      )
    }

    const marginSettings = { marginLeft, marginRight }

    return (
      <View style={[marginSettings, styles]} pointerEvents="box-none">
        {children}
      </View>
    )
  }
}

const mapStateToProps = (
  state,
  { component, listItemPushId, isResponsiveComponent }
) => {
  const newHeight = isResponsiveComponent
    ? getNodeHeight(state, component.id, listItemPushId)
    : 0

  return {
    newHeight,
  }
}

export const ListItem = connect(mapStateToProps)(UnconnectedListItem)

export class WrappedListItem extends Component {
  render() {
    const {
      object,
      bindingData,
      component,
      renderChildren,
      isResponsiveComponent,
      pushId,
    } = this.props

    const { rowMargin } = object.attributes
    const { marginLeft, marginRight } = object.layout

    const item = bindingData[0]
    const { datasourceId, tableId } = (item && item._meta) || {}

    if (!object.children?.[0]) {
      return null
    }

    let prototype = [object.children[0]]
    const listItemPushId = `${pushId} - ${item.id}`
    if (isResponsiveComponent) {
      prototype = object.children
    }

    return (
      <ListItem
        listItemPushId={listItemPushId}
        listId={object.id}
        item={item}
        rowMargin={rowMargin}
        datasourceId={datasourceId}
        tableId={tableId}
        object={object}
        component={component}
        marginLeft={marginLeft}
        marginRight={marginRight}
        isResponsiveComponent={isResponsiveComponent}
      >
        {renderChildren(prototype, { [object.id]: item })}
      </ListItem>
    )
  }
}

export class List extends Component {
  static contextTypes = {
    getBindings: PropTypes.func,
    getStore: PropTypes.func,
    getParams: PropTypes.func,

    setListItems: PropTypes.func,
  }

  getColumnCount() {
    const { object } = this.props

    if (object.attributes.listType === listTypes.GRID) {
      return object.attributes.columnCount || 1
    }

    return 1
  }

  getListKey = () => {
    const { getBindings } = this.context
    const { object } = this.props

    // TODO: Figure out a better way to avoid virtualizedlist listKey errors
    if (!this.keySuffix) {
      this.keySuffix = Math.round(Math.random() * 100000)
    }

    const { keySuffix } = this

    const ids = Object.values(getBindings())

    return `list-${object.id}-${keySuffix}-${ids.join('.')}`
  }

  renderItem = ({ item }) => {
    const { object, component, renderChildren, isResponsiveComponent, pushId } =
      this.props
    const { dataBinding } = object
    const { attributes } = object
    const { rowMargin } = attributes

    const { datasourceId, tableId } = dataBinding.source

    if (!object.children?.[0]) {
      return null
    }

    let prototype = [object.children[0]]
    const listItemPushId = `${pushId} - ${item.id}`

    if (isResponsiveComponent) {
      prototype = object.children.map(child => ({
        ...child,
        attributes: { ...child.attributes },
        layout: { ...child.layout },
      }))
    }

    return (
      <ListItem
        listItemPushId={listItemPushId}
        listId={object.id}
        item={item}
        rowMargin={rowMargin}
        datasourceId={datasourceId}
        tableId={tableId}
        object={object}
        component={component}
        isResponsiveComponent={isResponsiveComponent}
      >
        {renderChildren(prototype, { [object.id]: item })}
      </ListItem>
    )
  }

  shouldComponentUpdate(newProps) {
    const { pushId, object, bindingData, newY, isResponsiveComponent } =
      this.props
    const { dataBinding } = object
    const { setListItems } = this.context

    if (!dataBinding || dataBinding.bindingType !== bindingTypes.LIST) {
      return true
    }

    if (isResponsiveComponent && newY !== newProps.newY) {
      return true
    }

    const listItemIds = bindingData?.map(({ id }) => id) || []
    const newListItemIds = newProps.bindingData?.map(({ id }) => id) || []

    if (
      isResponsiveComponent &&
      !listsEqual(bindingData, newProps.bindingData) &&
      !lodash.isEqual(listItemIds, newListItemIds)
    ) {
      setListItems(pushId, object, newListItemIds)
    }

    return !listsEqual(bindingData, newProps.bindingData)
  }

  componentDidMount() {
    const { pushId, object, bindingData, isResponsiveComponent } = this.props
    const { setListItems } = this.context

    if (isResponsiveComponent) {
      const listItemPushIds = bindingData?.map(({ id }) => id) || []
      setListItems(pushId, object, listItemPushIds)
    }
  }

  render() {
    const { object, bindingData, isResponsiveComponent } = this.props
    const { getStore, getParams } = this.context

    const { layout } = object
    const { rowMargin } = object.attributes

    if (
      object.dataBinding &&
      object.dataBinding.bindingType === bindingTypes.LIST &&
      !Array.isArray(bindingData)
    ) {
      return (
        <View style={styles.loader}>
          <ActivityIndicator color="#999999" />
        </View>
      )
    }

    const opts = { state: getStore().getState(), getParams }

    const listItems = sortItems(bindingData, object.dataBinding, opts)

    const marginBottom = listItems.length > 0 && rowMargin ? -1 * rowMargin : 0

    const wrapperStyles = {
      ...layout,
      marginBottom,
    }

    const listKey = this.getListKey()
    const numberOfColumns = this.getColumnCount()

    return (
      <View style={wrapperStyles} pointerEvents="box-none">
        <ListWrapper
          listKey={listKey}
          data={listItems}
          renderItem={this.renderItem}
          numberOfColumns={numberOfColumns}
          spacing={rowMargin}
          object={object}
          isResponsiveComponent={isResponsiveComponent}
        />
      </View>
    )
  }
}

export default List

const styles = StyleSheet.create({
  input: {
    position: 'absolute',
  },
  loader: {
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
