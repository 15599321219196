import React, { Component } from 'react'
import { TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/dist/MaterialIcons'

import { connectInput } from './Input'

export class Section extends Component {
  state = {
    activeColor: '',
    inactiveColor: '',
    activeIcon: 'check-box',
    inactiveIcon: 'check-box-outline-blank',
  }

  static className = 'Checkbox'

  componentDidMount() {
    this.handleColors()
  }

  componentDidUpdate(prevProps) {
    const { object } = this.props

    if (prevProps.object.attributes !== object.attributes) {
      this.handleColors()
    }
  }

  componentWillUnmount() {
    const { object, resetValue } = this.props

    if (resetValue && object) {
      resetValue(object.id)
    }
  }

  handleColors = () => {
    const { object } = this.props
    const { attributes } = object

    const { activeColor, inactiveColor } = attributes

    this.setState({ activeColor, inactiveColor })
  }

  handleChange = () => {
    const { changeValue, object, value } = this.props

    changeValue(object.id, !value)
  }

  render() {
    const { object, value } = this.props
    const { layout } = object
    const { activeColor, inactiveColor, activeIcon, inactiveIcon } = this.state

    const icon = value ? activeIcon : inactiveIcon
    const color = value ? activeColor : inactiveColor

    return (
      <TouchableOpacity
        onPress={this.handleChange}
        activeOpacity={0.4}
        hitslop={{ left: 10, right: 10, top: 10, bottom: 10 }}
        style={layout}
      >
        <Icon size={24} name={icon} color={color} />
      </TouchableOpacity>
    )
  }
}

export default connectInput(Section)
