import { Platform } from 'react-native'
import { dataTypes, imageTypes, fileTypes } from '@adalo/constants'

import { getScaledSources } from './images'

export const formatLibraryValue = (value, prop, binding, opts) => {
  const { getAssetURL, getImageUploadsBaseURL, getFileUploadsBaseURL, values } =
    opts

  if (value === null || value === undefined || value === '') {
    let placeholder

    // Check if the binding is an imageBinding (library image prop).
    // If so, check if there's a placeholder image.
    if (values) {
      if (
        values[prop.name] &&
        (values[prop.name].type === 'imageBinding' ||
          values[prop.name].bindingType === 'libraryProp')
      ) {
        placeholder = getPlaceholder(values[prop.name].options, getAssetURL)
      }
      // Checks for placeholder on traditional images.
      else if (
        values.image &&
        values.image.type &&
        values.image.type === 'binding'
      ) {
        // Do not return a placeholder object if the prop is an action
        if (
          prop.name === 'avatarClickActions' ||
          prop.name === 'onPress' ||
          prop.name === 'clickActions'
        ) {
          if (typeof values[prop.name] === 'object') {
            return value
          }

          return values[prop.name]
        }

        const options =
          values.image.options !== 'undefined' ? values.image.options : null
        placeholder = getPlaceholder(options, getAssetURL)

        if (placeholder) return placeholder
      }
    }
    return placeholder || value
  }

  if (prop.type === dataTypes.IMAGE) {
    // Make sure it's a binding...

    // This line checks to see if the binding is wrapped into
    // a new object of type "imageBinding". Has to do with
    // library image controls getting support for input types of url.
    // However, if the image is of type "uploaded", there won't be a deeper
    // binding, so don't look for it.
    if (
      binding &&
      binding.type === 'imageBinding' &&
      binding.imageType !== imageTypes.UPLOADED
    ) {
      binding = binding.binding
    }
    const { filename, size } = value
    if (binding && (binding.type === 'binding' || Array.isArray(binding))) {
      if (typeof value === 'object') {
        let uri = value.url || value.uri
        if (!uri.match(/^https:\/\//)) {
          uri = `${getImageUploadsBaseURL()}/${uri}`
        }

        uri = formatURL(uri)
        const fileType = value.filename.split('.')[1]

        // return gifs without any query transforms from imgix
        if (fileType === 'gif') {
          return { uri, filename, size, cache: 'force-cache' }
        }

        if (Platform.OS !== 'web' && value.width && value.height) {
          const result = getScaledSources(
            uri,
            value.width,
            value.height,
            filename,
            size
          )

          return result
        } else if (
          value.width &&
          value.height &&
          opts.object &&
          opts.object.attributes &&
          opts.object.attributes.width &&
          opts.object.attributes.height
        ) {
          const valueArray = value.uri
            ? value.uri.split('.')
            : value.url.split('.')
          const valueType = valueArray && valueArray[valueArray.length - 1]

          // remove url paramaters if type is gif
          if (valueType && valueType === 'gif') {
            return { uri, filename, size, cache: 'force-cache' }
          }

          const { width, height } = opts.object.attributes

          const wRatio = value.width / width
          const hRatio = value.height / height

          if (wRatio < hRatio) {
            const imageWidth = Math.min(value.width, width * 2)

            return {
              uri: `${uri}?w=${imageWidth}`,
              filename,
              size,
              cache: 'force-cache',
            }
          } else {
            const imageHeight = Math.min(value.height, height * 2)

            return {
              uri: `${uri}?h=${imageHeight}`,
              filename,
              size,
              cache: 'force-cache',
            }
          }
        } else {
          value = value.url || value.uri || value
        }
      } else if (typeof value === 'string') {
        const url = formatURL(value)
        return { uri: url, filename, size, cache: 'force-cache' }
      }

      return {
        uri: !value.match(/^https:\/\//)
          ? `${getImageUploadsBaseURL()}/${value}?orient`
          : value,
        filename,
        size,
        cache: 'force-cache',
      }
    } else if (typeof binding === 'string') {
      // New else if statement. Handles when app creator inputs static
      // URL in for the image url of a library component instead of magic text
      return { uri: formatURL(binding) }
    } else {
      let assetURL

      const uri = value.url || value.uri

      if (uri) {
        if (getAssetURL) {
          assetURL = getAssetURL(uri)
        } else {
          assetURL = `${getImageUploadsBaseURL()}/${uri}?orient`
        }
      } else {
        assetURL = getAssetURL(value)
      }

      if (typeof assetURL === 'number') {
        return assetURL
      }

      if (assetURL && assetURL.match('^http://')) {
        assetURL = assetURL.replace(/^http:\/\//i, 'https://')
      }

      assetURL = formatURL(assetURL)

      return { uri: assetURL, filename, size, cache: 'force-cache' }
    }
  }

  if (prop.type === dataTypes.FILE) {
    // Make sure it's a binding...
    // This line checks to see if the binding is wrapped into
    // a new object of type "fileBinding". Has to do with
    // library file controls getting support for input types of url.
    // However, if the file is of type "uploaded", there won't be a deeper
    // binding, so don't look for it.

    if (
      binding &&
      binding.type === 'fileBinding' &&
      binding.fileType !== fileTypes.UPLOADED
    ) {
      binding = binding.binding
    }
    if (binding && (binding.type === 'binding' || Array.isArray(binding))) {
      if (typeof value === 'object') {
        if (value.value) {
          value = value.value
        }
        let uri = value.url || value.uri

        if (!uri) {
          return {
            value: {
              uri: '',
              filename: value.filename || '',
              size: value.size || 0,
            },
          }
        }
        if (!uri.match(/^https:\/\//)) {
          uri = `${getFileUploadsBaseURL()}/${uri}`
        }

        uri = formatURL(uri)

        return { value: { uri, filename: value.filename, size: value.size } }
      } else if (typeof value === 'string') {
        const uri = formatURL(value)
        return { value: { uri, filename: value.filename, size: value.size } }
      }

      return `${getFileUploadsBaseURL()}/${value}`
    } else if (typeof binding === 'string') {
      // New else if statement. Handles when app creator inputs static
      // URL in for the image url of a library component instead of magic text
      return { value: { uri: formatURL(binding) } }
    } else {
      const uri = `${getFileUploadsBaseURL()}/${value.url || value.uri}`

      return { value: { uri, filename: value.filename, size: value.size } }
    }
  }

  return value
}

const formatURL = url => {
  let value = url.trim()
  if (value.match('base64')) {
    return value
  }
  if (!(value.match('^http://') || value.match('^https://'))) {
    value = `https://${value}`
  } else if (value.match('^http://')) {
    value = value.replace(/^http:\/\//i, 'https://').trim()
  }
  return value
}

const getPlaceholder = (imageOptions, getAssetURL) => {
  if (imageOptions) {
    const placeholderImage =
      typeof imageOptions.placeholderImage !== 'undefined'
        ? imageOptions.placeholderImage
        : null

    if (imageOptions.placeholderImageEnabled && placeholderImage) {
      const src = getAssetURL && getAssetURL(placeholderImage)

      if (typeof src === 'number') {
        return src
      }

      return {
        uri: `${src}`,
        cache: 'force-cache',
      }
    }

    return null
  } else {
    return null
  }
}
