import chroma from 'chroma-js'

export const defaultBranding = {
  primary: '#00A898',
  secondary: '#FFC00E',
  background: '#FFFFFF',
  text: '#424242',
}

function brighten(color) {
  return getRGBA(chroma(color).brighten())
}

function darken(color) {
  return getRGBA(chroma(color).darken())
}

export const getRGBA = chromaColor => {
  return `rgba(${chromaColor.rgba().join(', ')})`
}

export function normalizeColor(
  value,
  branding = defaultBranding,
  ctx = {},
  attributes = {}
) {
  if (!value || value[0] !== '@') {
    return value
  }

  if (value.startsWith('@contrast:')) {
    const sibling = value.substr(10)
    return ctx[sibling]
      ? contrastWithBackground(normalizeColor(ctx[sibling], branding))
      : contrastWithBackground(normalizeColor(attributes[sibling], branding))
  }

  switch (value) {
    case '@primary':
      return branding.primary
    case '@primaryLight':
      return brighten(branding.primary)
    case '@primaryDark':
      return darken(branding.primary)
    case '@secondary':
      return branding.secondary
    case '@secondaryLight':
      return brighten(branding.secondary)
    case '@secondaryDark':
      return darken(branding.secondary)
    case '@text':
      return branding.text
    case '@textLight':
      return brighten(branding.text)
    case '@textDark':
      return darken(branding.text)
    case '@background':
      return branding.background
    case '@backgroundLight':
      return brighten(branding.background)
    case '@backgroundDark':
      return darken(branding.background)
    default:
      return value
  }
}

export const getBrandingFromHex = (val, branding) => {
  const c = val.rgb

  for (const prop in branding) {
    if (val.hex === branding[prop]) {
      return `@${prop}`
    }
  }
  return `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`
}

export function contrastWithBackground(bgColor) {
  if (!bgColor) {
    return
  }
  return chroma.contrast(bgColor, '#fff') > 2.5 ? '#fff' : '#000'
}
