import axios from 'axios'
import { authenticateOAuth, unsafeGetToken } from '../ducks/auth'
import { loginUrl } from './urls'
import { registerDevice } from './notifications'

export const oauth = async (oAuthConfig, helpers) => {
  const { dispatch, getBaseURL, getApp } = helpers

  const app = getApp()
  const datasourceId = Object.keys(app.datasources)[0]
  const config = { oauth: true, ...oAuthConfig }
  let res

  try {
    res = await axios.post(loginUrl(getBaseURL(), datasourceId), config)
    dispatch(authenticateOAuth(datasourceId, res))

    // If login was unsuccessful, return -1 to custom component.
    if (res.status !== 200) {
      return -1
    }

    const { getDeviceId, getNotificationsURL, isPreviewer } = helpers

    registerDevice(
      getApp(),
      getDeviceId(),
      getNotificationsURL(),
      unsafeGetToken(datasourceId),
      isPreviewer
    ).catch(err => console.log('NOTIFICATIONS REGISTRATION ERROR:', err))

    return res.data.retcode
  } catch (error) {
    console.log('OAuth error! ', error)
  }
}
