import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

import ActionWrapper from './ActionWrapper'

export default class Group extends Component {
  render() {
    const { object, children, component, styleOverrides } = this.props
    const { groupType, layout } = object

    // All type.group with !groupType were removed before,
    // so this is responsiveLayout only behavior
    if (!groupType) {
      return (
        <ActionWrapper
          component={component}
          object={object}
          style={[layout, styleOverrides]}
        >
          <View style={styles.responsiveGroup} pointerEvents="box-none">
            {children}
          </View>
        </ActionWrapper>
      )
    }

    return (
      <ActionWrapper
        component={component}
        object={object}
        style={[styles.group, styleOverrides]}
      >
        <View style={styles.oldGroup} pointerEvents="box-none">
          {children}
        </View>
      </ActionWrapper>
    )
  }
}

const styles = StyleSheet.create({
  oldGroup: {
    flex: 1,
  },
  responsiveGroup: {
    position: 'relative',
    height: '100%',
  },
})
