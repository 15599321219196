import { transitions } from '@adalo/constants'

export const TRANSITION_CONFIG = {
  [transitions.PUSH]: {
    transformType: 'translateX',
    transformDirection: 1,
    underViewMultiple: 0.1,
    dimUnderView: true,
    gap: 0,
  },
  [transitions.MODAL]: {
    transformType: 'translateY',
    transformDirection: 1,
    underViewMultiple: 0,
    dimUnderView: true,
    gap: 0,
  },
  [transitions.NONE]: {
    transformType: 'translateX',
    transformDirection: 1,
    underViewMultiple: 0,
    dimUnderView: true,
    gap: 0,
  },
  [transitions.SLIDE_LEFT]: {
    transformType: 'translateX',
    transformDirection: 1,
    underViewMultiple: 1,
    gap: 2,
  },
  [transitions.SLIDE_RIGHT]: {
    transformType: 'translateX',
    transformDirection: -1,
    underViewMultiple: 1,
    gap: 2,
  },
  [transitions.SLIDE_UP]: {
    transformType: 'translateY',
    transformDirection: 1,
    underViewMultiple: 1,
    gap: 2,
  },
  [transitions.SLIDE_DOWN]: {
    transformType: 'translateY',
    transformDirection: -1,
    underViewMultiple: 1,
    gap: 2,
  },
  [transitions.FLOAT_LEFT]: {
    transformType: 'translateX',
    transformDirection: 1,
    underViewMultiple: 0,
    dimUnderView: true,
    gap: 0,
  },
  [transitions.FLOAT_RIGHT]: {
    transformType: 'translateX',
    transformDirection: -1,
    underViewMultiple: 0,
    dimUnderView: true,
    gap: 0,
  },
  [transitions.FLOAT_UP]: {
    transformType: 'translateY',
    transformDirection: 1,
    underViewMultiple: 0,
    dimUnderView: true,
    gap: 0,
  },
  [transitions.FLOAT_DOWN]: {
    transformType: 'translateY',
    transformDirection: -1,
    underViewMultiple: 0,
    dimUnderView: true,
    gap: 0,
  },
}
