import React, { Component } from 'react'
import { Dimensions, Image, View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

class BackgroundImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageHeight: 0,
      imageWidth: 0,
      deviceHeight: 0,
      deviceWidth: 0,
    }
  }

  componentDidMount() {
    const { source } = this.props

    const imageSource =
      typeof source === 'number' ? Image.resolveAssetSource(source) : source

    // grab the dimensions for our image
    if (imageSource) {
      Image.getSize(
        imageSource.uri || imageSource,
        this.setImageSize,
        console.error
      )
    }

    // grab the device dimensions
    this.getDeviceDimensions()

    // add an event listener for when device dimensions changes
    Dimensions.addEventListener('change', this.getDeviceDimensions)
  }

  componentWillUnmount() {
    // remove dimensions event listener on unmount
    Dimensions.removeEventListener('change', this.getDeviceDimensions)
  }

  getDeviceDimensions = () => {
    let { height, width } = Dimensions.get('window')
    height = Math.round(height)
    width = Math.round(width)

    return this.setState({
      deviceHeight: height,
      deviceWidth: width,
    })
  }

  setImageSize = (width, height) => {
    return this.setState({ imageHeight: height, imageWidth: width })
  }

  handleImageSize = () => {
    const { resizeMode } = this.props
    const { imageHeight, imageWidth, deviceHeight, deviceWidth } = this.state

    // ratio
    const widthRatio = imageWidth ? deviceWidth / imageWidth : 0
    const heightRatio = imageHeight ? deviceHeight / imageHeight : 0

    let height
    let width

    if (
      (resizeMode === 'cover' && heightRatio > widthRatio) ||
      (resizeMode === 'contain' && heightRatio < widthRatio)
    ) {
      height = deviceHeight
      width = (deviceHeight * imageWidth) / imageHeight
    } else {
      height = (deviceWidth * imageHeight) / imageWidth
      width = deviceWidth
    }

    return { height: height || 0, width: width || 0 }
  }

  // handles positioning on the X and Y axis
  handlePosition = () => {
    let { positionX, positionY } = this.props

    switch (positionX) {
      case 'left':
      case 'flex-start':
        positionX = 'flex-start'
        break
      case 'right':
      case 'flex-end':
        positionX = 'flex-end'
        break
      case 'center':
      default:
        positionX = 'center'
        break
    }

    switch (positionY) {
      case 'top':
      case 'flex-start':
        positionY = 'flex-start'
        break
      case 'bottom':
      case 'flex-end':
        positionY = 'flex-end'
        break
      case 'center':
      default:
        positionY = 'center'
        break
    }

    return { positionX, positionY }
  }

  getSource = () => {
    const { source } = this.props

    if (typeof source === 'number') return source
    return { uri: source }
  }

  render() {
    const { positionX, positionY } = this.handlePosition()
    const viewStyles = {
      justifyContent: positionX,
      alignItems: positionY,
    }

    const imageStyles = {
      ...this.handleImageSize(),
    }

    const source = this.getSource()

    return (
      <View style={[styles.view, viewStyles]}>
        <Image source={source} style={[styles.image, imageStyles]} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  view: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  image: {},
})

BackgroundImage.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // eslint-disable-next-line react/require-default-props
  resizeMode: PropTypes.oneOf(['cover', 'contain']),
  positionX: PropTypes.oneOf([
    'left',
    'flex-start',
    'center',
    'right',
    'flex-end',
  ]).isRequired,
  positionY: PropTypes.oneOf([
    'top',
    'flex-start',
    'center',
    'bottom',
    'flex-end',
  ]).isRequired,
}

export default BackgroundImage
