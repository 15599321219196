import { API_LOCATION_REJECTED } from './location'

const REMOVE_TOAST = Symbol('REMOVE_TOAST')
export const DEVICE_LOCATION_UNAVAILABLE = Symbol('DEVICE_LOCATION_UNAVAILABLE')
export const ACTION_EXECUTION_DENIED = Symbol('ACTION_EXECUTION_DENIED')

const INITIAL_STATE = {
  toast: null,
}

export const ACTION_TYPES = [
  'CREATE_OBJECT_REJECTED',
  'UPDATE_OBJECT_REJECTED',
  'DELETE_OBJECT_REJECTED',
  'CREATE_ASSOCIATION_REJECTED',
  'DELETE_ASSOCIATION_REJECTED',
]

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action) => {
  if (action.type === REMOVE_TOAST) {
    return {
      toast: null,
    }
  }

  if (
    action.type === API_LOCATION_REJECTED ||
    action.type === DEVICE_LOCATION_UNAVAILABLE
  ) {
    return {
      toast: {
        id: Date.now(),
        body: 'Location Data Is Not Available',
      },
    }
  }

  if (action.type === ACTION_EXECUTION_DENIED) {
    return {
      toast: {
        id: Date.now(),
        body: 'Some Actions Could Not Be Completed',
      },
    }
  }

  for (const actionType of ACTION_TYPES) {
    if (action.type === actionType) {
      return {
        toast: {
          id: Date.now(),
          body: 'Action Cannot Be Completed',
        },
      }
    }
  }

  return state
}

// Actions
export const triggerToast = type => ({ type })

export const removeToast = () => ({
  type: REMOVE_TOAST,
})

// Selectors

export const getCurrentToast = state => {
  return state.toasts.toast
}
