/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { View, FlatList, StyleSheet, ActivityIndicator } from 'react-native'
import { bindingTypes } from '@adalo/constants'

import { getVisible } from '../../utils/visibility'
import { getObjectBinding } from '../../utils/dependencies'
import { indexOf } from '../../utils/arrays'
import { isListObject } from '../../utils/lists'
import { MultipleObjectRenderer, ObjectRenderer } from '../ObjectRenderer'

const EMPTY_ARRAY = []

class ListBody extends Component {
  getWrapper = () => {
    const { body } = this.props
    const wrapper = body[0]

    return wrapper
  }

  getObjects = () => {
    const wrapper = this.getWrapper()

    if (!wrapper || !wrapper.children) {
      return []
    }

    return wrapper.children
  }

  getBottomPadding = () => {
    const wrapper = this.getWrapper()
    return wrapper.layout.paddingBottom
  }

  getListIndex = () => {
    const objects = this.getObjects()

    return indexOf(objects, obj => isListObject(obj))
  }

  getList = () => {
    return this.getObjects()[this.getListIndex()]
  }

  getHeader = () => {
    const index = this.getListIndex()

    return this.getObjects().slice(0, index)
  }

  getFooter = () => {
    const index = this.getListIndex() + 1

    return this.getObjects().slice(index)
  }

  getChildProps = () => {
    const { component, active, topScreen } = this.props

    return { component, active, topScreen }
  }

  getTopPadding = () => {
    const {
      style: { paddingTop },
    } = this.props
    const firstObject = this.getObjects()[0]

    return paddingTop + firstObject.layout.marginTop
  }

  getItem = (list, firstItem = false) => {
    let marginTop = 0

    if (!firstItem) {
      marginTop = list.attributes.rowMargin
    }

    return {
      ...list,
      layout: {
        ...list.layout,
        marginTop,
      },
      type: 'listItem',
    }
  }

  renderHeader = () => {
    const { style } = this.props
    const { paddingTop } = style
    const list = this.getList()
    const headerObjects = this.getHeader()

    const styles = {
      paddingTop,
      marginBottom: list.layout.marginTop,
    }

    return (
      <View style={styles}>
        <MultipleObjectRenderer
          {...this.getChildProps()}
          objects={headerObjects}
        />
      </View>
    )
  }

  renderFooter = () => {
    const { style, bindingData, loading } = this.props
    const { paddingBottom } = style
    const list = this.getList()
    const footerObjects = this.getFooter()

    const footerStyles = {
      paddingBottom,
      paddingTop: list.layout.marginBottom,
    }

    return (
      <View>
        {loading || !bindingData ? (
          <View style={styles.loader}>
            <ActivityIndicator color="#999999" />
          </View>
        ) : null}
        <View style={footerStyles}>
          <MultipleObjectRenderer
            {...this.getChildProps()}
            objects={footerObjects}
          />
        </View>
      </View>
    )
  }

  renderItem = ({ item, separators, index }) => {
    const list = this.getList()
    const firstItem = index === 0

    if (item.key === 'spacer') {
      return this.renderSpacer()
    }

    return (
      <ObjectRenderer
        visible
        {...this.getChildProps()}
        object={this.getItem(list, firstItem)}
        bindingData={[item]}
      />
    )
  }

  renderSpacer() {
    const paddingBottom = this.getBottomPadding()

    return <View style={{ height: paddingBottom }} />
  }

  keyExtractor = itm => {
    return String(itm.id)
  }

  getData = () => {
    const { bindingData } = this.props
    const list = this.getList()
    const binding = list && list.dataBinding
    let data

    if (bindingData && binding && binding.bindingType === bindingTypes.LIST) {
      data = bindingData || []
    }

    return data
  }

  handleResize = (width, height) => {
    const { component } = this.props

    if (height) {
      this._innerHeight = height
    }

    if (this._scrollView && component.reverseScroll) {
      window.setTimeout(() => {
        this._scrollView.scrollToEnd({ animated: true })
      }, 10)
    }
  }

  scrollViewRef = el => {
    this._scrollView = el
  }

  render() {
    const { onEndReached, onRefresh, refreshing, visible } = this.props

    let data = this.getData() || []
    data = data.concat([{ key: 'spacer' }])

    if (!visible) {
      data = EMPTY_ARRAY
    }

    return (
      <FlatList
        ref={this.scrollViewRef}
        data={data}
        renderItem={this.renderItem}
        ListHeaderComponent={this.renderHeader}
        ListFooterComponent={this.renderFooter}
        keyExtractor={this.keyExtractor}
        onEndReached={onEndReached}
        onRefresh={onRefresh}
        refreshing={refreshing}
        progressViewOffset={this.getTopPadding()}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={this.handleResize}
        keyboardShouldPersistTaps="handled"
      />
    )
  }
}

const mapStateToProps = (
  state,
  { body, getParams, getBinding, getBindingsList, getApp }
) => {
  const wrapper = body[0]

  if (!wrapper || !wrapper.children) {
    return {}
  }

  const list = wrapper.children.filter(c => isListObject(c))[0]

  if (!list) {
    return {}
  }

  const bindingData = getObjectBinding(state, list, {
    getParams,
    getBinding,
    getBindingsList,
    getApp,
  })

  const visible = getVisible(state, list, {
    getParams,
    getBinding,
    getBindingsList,
    getApp,
  })

  return { bindingData, visible }
}

const ConnectedListBody = connect(mapStateToProps)(ListBody)

export default class WrappedListBody extends Component {
  static contextTypes = {
    getBinding: PropTypes.func,
    getBindingsList: PropTypes.func,
    getParams: PropTypes.func,
    getApp: PropTypes.func,
  }

  render() {
    return <ConnectedListBody {...this.props} {...this.context} />
  }
}

const styles = StyleSheet.create({
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
})
